import styles from "./Footer.module.css";
import { useNavigate } from "react-router-dom";
import { Instagram, Email, } from '../icons';


const Footer = (props) => {
	const navigate = useNavigate();

	const routeChange = (e, url) => {
	    navigate(url);
	}
  
  	return (
      	<div className={styles.footerWrap} style={{...props.style}}>
	        <div className={styles.contactUs}>
	          	<label onClick={event => routeChange(event, 'contact-us')}>Contact Us</label>
	          	<a href="https://www.instagram.com/xp.hive" target="_blank" rel="noreferrer" aria-label="instagram"><i className={styles.insta}><Instagram color={'#000'} /></i></a>
	          	<a className={styles.mail} href="mailto: hello@xphive.com"><i className={styles.email}><Email stroke={'#000'} /></i></a>
	        </div>
	        <div className={styles.policiesLeft}>
	          	<label onClick={event => routeChange(event, 'about-us')}>About Us</label>
	          	<label onClick={event => routeChange(event, 'privacy-policy')}>Privacy Policy</label>
	        </div>
	        <div className={styles.policiesRight}>
	          	<label onClick={event => routeChange(event, 'terms-policy')}>Terms of Use</label>
	          	<label onClick={event => routeChange(event, 'refund-policy')}>Refund Policy</label>
	        </div>
	        <p>© 2024 DROID42 VENTURE PRIVATE LIMITED</p>
      	</div>
  	);  
}

export default Footer;

const Upload = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="fi-rr-upload" clipPath="url(#clip0_130_249)">
			<path id="Vector" d="M7.33804 2.21875L7.33337 12.5108C7.33337 12.6876 7.40361 12.8571 7.52863 12.9822C7.65366 13.1072 7.82323 13.1774 8.00004 13.1774C8.17685 13.1774 8.34642 13.1072 8.47144 12.9822C8.59646 12.8571 8.6667 12.6876 8.6667 12.5108L8.67137 2.23009L10.6127 4.17209C10.7377 4.29707 10.9073 4.36728 11.084 4.36728C11.2608 4.36728 11.4304 4.29707 11.5554 4.17209C11.6803 4.04707 11.7506 3.87753 11.7506 3.70075C11.7506 3.52398 11.6803 3.35444 11.5554 3.22942L9.4147 1.08609C9.22897 0.900234 9.00843 0.752801 8.7657 0.652211C8.52296 0.551621 8.26279 0.499847 8.00004 0.499847C7.73728 0.499847 7.47711 0.551621 7.23437 0.652211C6.99164 0.752801 6.7711 0.900234 6.58537 1.08609L4.4447 3.22742C4.31972 3.35244 4.24951 3.52198 4.24951 3.69875C4.24951 3.87553 4.31972 4.04507 4.4447 4.17009C4.56972 4.29507 4.73926 4.36528 4.91604 4.36528C5.09281 4.36528 5.26235 4.29507 5.38737 4.17009L7.33804 2.21875Z" fill="#1C1C1E"/>
			<path id="Vector_2" d="M14.6667 11.8329V14.4996C14.6667 14.6764 14.5964 14.846 14.4714 14.971C14.3464 15.096 14.1768 15.1663 14 15.1663H2C1.82319 15.1663 1.65362 15.096 1.5286 14.971C1.40357 14.846 1.33333 14.6764 1.33333 14.4996V11.8329C1.33333 11.6561 1.2631 11.4865 1.13807 11.3615C1.01305 11.2365 0.843478 11.1663 0.666667 11.1663C0.489856 11.1663 0.320286 11.2365 0.195262 11.3615C0.0702379 11.4865 0 11.6561 0 11.8329L0 14.4996C0 15.03 0.210714 15.5387 0.585786 15.9138C0.960859 16.2889 1.46957 16.4996 2 16.4996H14C14.5304 16.4996 15.0391 16.2889 15.4142 15.9138C15.7893 15.5387 16 15.03 16 14.4996V11.8329C16 11.6561 15.9298 11.4865 15.8047 11.3615C15.6797 11.2365 15.5101 11.1663 15.3333 11.1663C15.1565 11.1663 14.987 11.2365 14.8619 11.3615C14.7369 11.4865 14.6667 11.6561 14.6667 11.8329Z" fill="#1C1C1E"/>
		</g>
		<defs>
			<clipPath id="clip0_130_249">
				<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
			</clipPath>
		</defs>
	</svg>
  );
};

export default Upload;

import React, { useEffect, useState, } from "react";
import styles from "./Bytes.module.css";
import { Helper } from "../components";


const Bytes = (props) => {
    const [scrollbarLeft, setScrollbarLeft] = useState(0);

    const handleBytesScroll = () => {
      const totalScrollPossible = 180 * (props.bytes.length - 1);
      let left = (document.getElementById('bytesContainer').scrollLeft / totalScrollPossible) * 100;
      left = left >= 100 ? 100 : left;
      const substract = left >= 80 ? ' - ' + ((left - 80) * 1.75) + 'px' : '';
      setScrollbarLeft('calc(' + left + '%' + substract  + ')');
    };

    return (
        <div className={styles.bytes}>
          <h1>Quick Info Bytes</h1>
          {
            Object.keys(props.bytes).length != 0 &&
            <div id="bytesContainer" className="bytesContainer" onScroll={handleBytesScroll}>
              {props.bytes.map((byte, i) => {
                return (
                  <div className={styles.byte} key={i}>
                    <img src={byte.images[0]} />
                    <h4>{byte.title}</h4>
                    <p>{byte.description}</p>
                  </div>
                );
              })}
            </div>
          }
          {props.bytes.length > 1 && <div className={styles.scrollWrap}>
            <span className={styles.scrollLine}></span>
            <span className={styles.scrollBar} style={{'left': scrollbarLeft}}></span>
          </div>}
        </div>
    );  
}  
export default Bytes;

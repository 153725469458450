import styles from "./ConfirmationPopup.module.css";
import parse from 'html-react-parser';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Helper from './Helper';
import Modal from './Modal';
import { RightArrow, BackArrow, CloseBlack } from '../icons';


const ConfirmationPopup = (props) => {
  	const open = props.open;
  	const navigate = useNavigate();

  	const setOpen = () => {
	    props.setOpen(false);
	}
  
	return (
    	<div className={styles.confirmationPopup}>

	        <div id="confirmation-modal-root"></div>
	        {open && (
	          <Modal open={open} toggle={setOpen} root='confirmation-modal-root'>
	              <div>
	              	<i onClick={setOpen} className={styles.closeIcon}><CloseBlack /></i>
	                <h1 className={styles.confirmationPopupHeading}>{props.heading}</h1>
	                <p>{parse(props.para)}</p>

	                <button className={styles.continueWishListBtn} onClick={setOpen}>
	                	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						  <path d="M5 13.0001L15 13.0001L11.71 16.2901C11.6163 16.383 11.5419 16.4936 11.4911 16.6155C11.4403 16.7373 11.4142 16.8681 11.4142 17.0001C11.4142 17.1321 11.4403 17.2628 11.4911 17.3846C11.5419 17.5065 11.6163 17.6171 11.71 17.7101C11.8974 17.8963 12.1508 18.0009 12.415 18.0009C12.6792 18.0009 12.9326 17.8963 13.12 17.7101L17.41 13.4101C17.7856 13.0367 17.9978 12.5296 18 12.0001C17.9951 11.474 17.7832 10.971 17.41 10.6001L13.12 6.30008C13.0268 6.20749 12.9162 6.13418 12.7946 6.08433C12.6731 6.03448 12.5429 6.00906 12.4115 6.00952C12.2801 6.00998 12.1501 6.03632 12.0288 6.08704C11.9076 6.13775 11.7976 6.21184 11.705 6.30508C11.6124 6.39831 11.5391 6.50888 11.4893 6.63045C11.4394 6.75202 11.414 6.88222 11.4144 7.01361C11.4149 7.14501 11.4412 7.27502 11.492 7.39624C11.5427 7.51745 11.6168 7.62749 11.71 7.72007L15 11.0001L5 11.0001C4.73478 11.0001 4.48043 11.1054 4.29289 11.293C4.10536 11.4805 4 11.7349 4 12.0001C4 12.2653 4.10536 12.5196 4.29289 12.7072C4.48043 12.8947 4.73478 13.0001 5 13.0001Z" fill="#F2F2F7"/>
						</svg>
	                	{props.continueBtnText}
	                </button>
		   			<button className={styles.discardBtn} onClick={props.discardBtnAction}>{props.discardBtnIcon}{props.discardBtnText}</button>
	              </div>
	          </Modal>
	        )}
	    </div>
  )
}

export default ConfirmationPopup;

import styles from "./BottomBar.module.css";
import { Explore, ExploreSelected, Experience, ExperienceSelected, User, UserSelected, } from "../icons";
import { useNavigate, useLocation, } from "react-router-dom";
import { LoginHelper, Helper, } from '../components';


const BottomBar = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const path = location.pathname;
	const user = LoginHelper.getUser();
	const explore = path === '/';
	const experiences = props.selectedTab ? props.selectedTab === 'experiences' : path.includes('/listings') && location.search === '?tab=experiences';
	const portfolio = path.includes('/portfolio/');


	const routeChange = (e, url, label) => {
    Helper.trackAnalytics(props.analyticsCategory || 'BottomBar', `Click_${label}`, label);
    if (url === '/') {
      window.location = '/';  
    } else {
      navigate(url);
    }
	}

  const getExperiencesURL = () => {
    return `/listings${location.search ? (location.search.includes('tab=') ? location.search.replace('tab=creators', 'tab=experiences').replace('tab=explore', 'tab=experiences') : location.search + '&tab=experiences') : '?tab=experiences'}`;
  }
  
	return (
  	<div className={styles.bottomFixedBar}>
  		<ul>
  			<li style={{width: '50%'}} onClick={event => routeChange(event, '/', 'Explore')} className={explore ? styles.selected : ''}>
  				<i>{explore ? <Explore /> : <Explore />}</i>
  				<label>Explore</label>
  			</li>
  			<li style={{display: 'none'}} onClick={event => routeChange(event, getExperiencesURL(), 'Experience')} className={experiences ? styles.selected : ''}>
  				<i>{experiences ? <Experience /> : <Experience />}</i>
  				<label>Experience </label>
  			</li>
  			<li style={{width: '50%'}} onClick={event => routeChange(event, user ? `/portfolio/${user.handle}` : '/login-signup', 'Profile')} className={portfolio ? styles.selected : ''}>
  				<i>{portfolio ? <User /> : <User />}</i>
  				<label>Profile</label>
  			</li>
  		</ul>
   </div>
  );
}

export default BottomBar;

import styles from "./Favourite.module.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Helper from './Helper';
import LoginHelper from './LoginHelper';
import { Heart, HeartSelected } from '../icons';

const Favourite = (props) => {
	const navigate = useNavigate();
	const [favourites, setFavourites] = useState(JSON.parse(Helper.storage.getItem(props.storage)) || []);

	const toggleFavourite = (e, fId) => {
	    e.stopPropagation();
	    if (!LoginHelper.isUserLoggedIn()) {
	    	navigate(`/login-signup?storage_fId=${props.storage}_${fId}`);
	    } else {
		    const data = Helper.toggleFavourite(props.analyticsCategory, props.storage, fId);
		    setFavourites(data);
		}
	};
  
  	return (
      	<div className={styles.favouriteIconContainer} onClick={event => toggleFavourite(event, props.id)} style={props.style}>
          	{!props.hideCircle && <svg xmlns="http://www.w3.org/2000/svg" width={props.circleWidth || '48'} height={props.circleHeight || '48'} viewBox="0 0 48 48" fill="none">
			  <circle cx="24" cy="24" r="24" fill="white"/>
			</svg>}
			<i className={styles.favouriteIcon} style={props.iconStyle}>
				{favourites.includes(props.id) ? <HeartSelected height={props.iconHeight} width={props.iconWidth} /> : <Heart height={props.iconHeight} width={props.iconWidth} /> }
			</i>
      </div>
  	);  
}  
export default Favourite;

import { isExpired, decodeToken } from "react-jwt";

const JWTHelper = {};

JWTHelper.isExpired = (token) => {
	if (!token) return true;
    return isExpired(token);
};

JWTHelper.decodeToken = (token) => {
	if (!token) return null;
    return decodeToken(token);
};

export default JWTHelper;

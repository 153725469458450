import React, { useEffect, useState, useRef, } from "react";
import styles from "./Listings.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Back, Favourite, Helper, Share, BottomBar, Search, ContactCreator, } from "../components";
import { Go, CalendarWhiteIcon, LocationSmallIcon, CloseBlack, } from '../icons';
import { Oval } from  'react-loader-spinner';


const Listings = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { height, width } = Helper.getWindowDimensions();
  const [data, setData] = useState({});
  const [selectedTab, setSelectedTab] = useState(searchParams.get('tab') || 'explore');
  const [flippedCards, setFlippedCards] = useState([]);
  const locations = searchParams.get('locations') ? searchParams.get('locations').split(',') : [];
  const categories = searchParams.get('categories') ? searchParams.get('categories').split(',') : [];
  const vibes = searchParams.get('vibes') ? searchParams.get('vibes').split(',') : [];
  const moveToFrontExperience = searchParams.get('experience');
  const moveToFrontExperienceByte = searchParams.get('experiencebyte');
  const [experienceHeight, setExperienceHeight] = useState(0);
  const [creatorHeight, setCreatorHeight] = useState(0);
  const experienceRef = useRef(null);
  const creatorRef = useRef(null);
  const [openContactCreator, setOpenContactCreator] = useState(false);
  const [experienceToContact, setExperienceToContact] = useState({});
  const [searching, setSearching] = useState(false);

  const getAnalyticsCategory = () => {
    try {
      return `Listings_${Helper.titleCase(selectedTab)}`;
    } catch(err) {
      return 'Listings';
    }
  };

  const routeChange = (e, url) => {
    Helper.trackAnalytics(getAnalyticsCategory(), `Click_${Helper.titleCase(url.split('/')[url.split('/').length - 1].replaceAll('-', '_'))}`, 'Navigate');
    navigate(url);
  };

  const setExperienceData = (exp, storage, open) => {
    setExperienceToContact({id: exp._id, brand: exp.brand});
    setOpenContactCreator(open);
  };

  const selectTab = (e, tab) => {
    Helper.trackAnalytics(getAnalyticsCategory(), `Click_${Helper.titleCase(tab)}`, 'Switch Tab');
    navigate(`/listings?tab=${tab}&locations=${locations}&categories=${categories}&vibes=${vibes}&experiencebyte=${moveToFrontExperienceByte}&experience=${moveToFrontExperience}`);
    setSelectedTab(tab);
  };

  const fetchData = (locationsInput=locations, categoriesInput=categories, vibesInput=vibes) => {
    setSearching(true);
    setExperienceHeight(0);
    setCreatorHeight(0);
    fetch(`${global.config.SERVER_URL}/api/v1/search/apply`, {
      method: 'POST',
      body: JSON.stringify({
        locations: locationsInput,
        categories: categoriesInput,
        vibes: vibesInput,
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
         ...Helper.getCommonHeaders(),
      },
      })
      .then((response) => response.json())
      .then((res) => {
        if (res.data) {
          const experiencesRes = [...res.data.experiences.filter(item => item._id === moveToFrontExperience), ...res.data.experiences.filter(item => item._id !== moveToFrontExperience)];
          const experienceBytesRes = [...res.data.experiencebytes.filter(item => item._id === moveToFrontExperienceByte), ...res.data.experiencebytes.filter(item => item._id !== moveToFrontExperienceByte)];
          if (experiencesRes.length && !searchParams.get('tab')) {
            setSelectedTab('experiences');
          }

          setData({
            experiences: experiencesRes,
            brands: res.data.brands,
            experiencebytes: experienceBytesRes,
          });
        };
        setSearching(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, {});


  const onClickExperienceCard = (e, experience) => {
    if (experience.version && experience.version === 2) {
      routeChange(e, `/experiences/${experience.slug}`);
    } else {
      setExperienceData(experience, 'contactExperienceCreatorLeads', true);
    }
  };

  const flipCard = (e, i, title) => {
    e.stopPropagation();
    let flippedCardIds = flippedCards.slice();
    if (flippedCardIds.includes(i)) {
      flippedCardIds = flippedCardIds.filter(key => key !== i);
      Helper.trackAnalytics('Listings_Explore', `Click_${Helper.titleCase(title.split(' ').join('_'))}`, `Flip Back`);
    } else {
      flippedCardIds.push(i);
      Helper.trackAnalytics('Listings_Explore', `Click_${Helper.titleCase(title.split(' ').join('_'))}`, `Flip`);
    }

    setFlippedCards(flippedCardIds);
  };

  useEffect(() => {
    if (experienceRef && experienceRef.current && experienceHeight === 0) {
      let height = 0;
      for (let c of experienceRef.current.children) {
        if (height < c.clientHeight) height = c.clientHeight;
      }  
      setExperienceHeight(height + 40);
    }

    if (creatorRef && creatorRef.current && creatorHeight === 0) {
      let height = 0;
      for (let c of creatorRef.current.children) {
        if (height < c.clientHeight) height = c.clientHeight;
      }  
      setCreatorHeight(height);
    }

    if (searchParams.get('tab') && selectedTab !== searchParams.get('tab')) {
      setSelectedTab(searchParams.get('tab'));
    }
  });

  const removeFilter = (e, filter) => {
    const locationFilters = locations.filter(item => item !== filter);
    const categorieFilters = categories.filter(item => item !== filter);
    const vibeFilters = vibes.filter(item => item !== filter);
    navigate(`/listings?tab=${selectedTab}&locations=${locationFilters}&categories=${categorieFilters}&vibes=${vibeFilters}&experiencebyte=${moveToFrontExperienceByte}&experience=${moveToFrontExperience}`);
    fetchData(locationFilters, categorieFilters, vibeFilters);
  };

  return (
    <div className={styles.homePage}>
      {Object.keys(data).length == 0 ? <Oval
        height={40}
        width={40}
        color="#000000"
        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#dbe8f6"
        strokeWidth={4}
        strokeWidthSecondary={4}
      /> : '' }

      { Object.keys(data).length != 0 ?
        <div className={styles.containers}>
          <Back analyticsCategory={getAnalyticsCategory()} backURL={'/'} />
          <ul className={styles.tabs}>
            <li onClick={e => selectTab(e, 'explore')} className={selectedTab === 'explore' ? styles.selectedTab : ''}>Explore</li>
            <li onClick={e => selectTab(e, 'experiences')} style={{width: '40%'}} className={selectedTab === 'experiences' ? styles.selectedTab : ''}>Experiences</li>
            <li onClick={e => selectTab(e, 'creators')} className={selectedTab === 'creators' ? styles.selectedTab : ''}>Creators</li>
          </ul>

          <Search analyticsCategory={getAnalyticsCategory()} postSearch={fetchData} input={true} locations={locations} categories={categories} vibes={vibes} />

          {(locations.length > 0 || categories.length > 0 || vibes.length > 0) && <ul className={styles.filters}>
            {[...locations, ...categories, ...vibes].map((filter, i) => {
              return(<li onClick={e => removeFilter(e, filter)} key={i}>{filter} <i className={styles.filterRemove}><CloseBlack width={'14px'} height={'14px'} /></i></li>)
          })}</ul>}


          {selectedTab === 'explore' && !searching &&
            <ul className={`${styles.results} ${styles.experiencebytes}`}>
              {data.experiencebytes.map((byte, i) => {
                return(
                  <li key={i} style={{width: (width * 0.50 - 48), height: (width * 0.50 - 48) * 1.15}} className={`${flippedCards.includes(i) ? styles.flippedByte : ''}`} onClick={e => flipCard(e, i, byte.title)}>
                    <div className={styles.card}>
                      <div className={styles.flipCardFront}>
                        <img style={{width: ((width * 0.50 - 48)), height: (width * 0.50 - 48) / 1.15}} src={byte.images[0]} />
                        <span className={styles.border}></span>
                        <h4>{byte.title}</h4>
                        <h5>{byte.subTitle}</h5>

                        <i className={styles.shareIcon}>
                          <Share analyticsCategory={getAnalyticsCategory()} url={`${window.location.origin}/listings?tab=explore&experiencebyte=${byte._id}`} blackIcon={true} iconHeight={'11'} iconStyle={{left: '14px', top: '-7px'}} iconWidth={'11'} hideCircle={true} style={{position: 'absolute', right: '30px', top: '8px', zIndex: 1}}/>
                        </i>
                        <i className={styles.byteFavouriteIcon}>
                          <Favourite analyticsCategory={getAnalyticsCategory()} id={byte._id} iconHeight={'12'} iconWidth={'12'} iconStyle={{left: '4px', top: '2px'}} circleWidth={'20'} circleHeight={'20'} storage={'experiencebyte'} style={{ position: 'absolute', top: '8px', right: '8px', bottom: 'unset', left: 'unset', zIndex: 1 }} />
                        </i>
                      </div>
                      <div className={styles.flipCardBack}>
                        <p>{byte.description}</p>
                      </div>
                    </div>
                  </li>
                )
              })}

              {!data.experiencebytes.length && <li className={styles.noResults}>Your query returned no results.<br/> Please change your search criteria and try again</li>}
            </ul>
          }

          {selectedTab === 'experiences' && !searching &&
            <ul className={`${styles.results} ${styles.experiences}`} ref={experienceRef}>
              {data.experiences.map((experience, i) => {
                return(<li onClick={e => onClickExperienceCard(e, experience)} style={{height: experienceHeight - (width * 0.06)}}>
                  <div style={{position: 'relative'}}>
                    <img src={experience.cardImages[0] || experience.images[0]} alt={i} />
                    {experience.recurrences && experience.recurrences.length > 0 && <span className={styles.recurrence}><label key={i}>{Helper.formatDate(new Date(experience.recurrences[0].dates.start), 'short', false)}</label></span>}
                  </div>

                  {experience.vibes && experience.vibes.length > 0 && <span className={styles.vibeName}><b>Vibe:</b> {experience.vibes[0].tags.join(', ')}</span>}
                  <h3>{experience.title.split('<label>')[0]}</h3>
                  {!experience.unitPrice && <p className={styles.description}>{experience.description}</p>}
                  {experience.unitPrice && <p className={styles.experiencePrice}><span>₹ {experience.unitPrice}/- Per Person</span></p>}
                  {experience.recurrences && experience.recurrences[0] && experience.recurrences[0].dates && 
                    <p className={styles.experienceDate}><CalendarWhiteIcon />{new Date(experience.recurrences[0].dates.start).toLocaleString('default', { month: 'long' })} {new Date(experience.recurrences[0].dates.start).getDate()}, {new Date(experience.recurrences[0].dates.start).getFullYear()}</p>
                  }

                  {experience.version && experience.version === 2 && <div className={styles.bookExperienceBtn}>
                    <span>Book</span>
                    <Go stroke={'rgba(0, 0, 0, 1)'} width={'24'} height={'24'} fill={'rgba(255, 234, 0, 1)'} />
                  </div>}

                  <i className={styles.experienceShareIcon}>
                    <Share analyticsCategory={getAnalyticsCategory()} url={experience.version && experience.version === 2 ? `${window.location.origin}/experiences/${experience.slug}` : `${window.location.origin}/listings?tab=experiences&experience=${experience._id}`} blackIcon={true} iconHeight={'11'} iconStyle={{left: '26px', top: '-15px'}} iconWidth={'11'} hideCircle={true} style={{position: 'absolute', right: '42px', top: '16px', zIndex: 1}}/>
                  </i>
                  <i className={styles.byteFavouriteIcon}>
                    <Favourite analyticsCategory={getAnalyticsCategory()} id={experience._id} iconHeight={'12'} iconWidth={'12'} iconStyle={{left: '4px', top: '2px'}} circleWidth={'20'} circleHeight={'20'} storage={'experience'} style={{ position: 'absolute', top: '16px', right: '16px', bottom: 'unset', left: 'unset', zIndex: 1 }} />
                  </i>
                  {experience.locations && experience.locations.length > 0 && <span className={styles.location}><i><LocationSmallIcon /></i><label key={i}>{experience.locations[0].city}</label></span>}
                </li>)
              })}

              {!data.experiences.length && <li className={styles.noResults}>Your query returned no results.<br/> Please change your search criteria and try again</li>}
            </ul>
          }

          {selectedTab === 'creators' && !searching && <ul className={`${styles.results} ${styles.creators}`} ref={creatorRef}>
            {data.brands.map((brand, i) => {
              return (
                <li style={{height: creatorHeight > 100 ? creatorHeight - 20 : ''}} onClick={event => routeChange(event, `/brands/${Helper.generateURL(brand.name)}`)} className={styles.creatorsWrap} key={i}>
                  <div style={{position: 'relative'}}>
                    <img style={{width: width / 2 - 48, height: (width / 2 - 48) * 1.45}} className={styles.image} src={brand.images[0]} />

                    <i className={styles.experienceShareIcon}>
                      <Share analyticsCategory={getAnalyticsCategory()} url={`${window.location.origin}/brands/${Helper.generateURL(brand.name)}`} blackIcon={true} iconHeight={'11'} iconStyle={{left: '26px', top: '-15px'}} iconWidth={'11'} hideCircle={true} style={{position: 'absolute', right: '42px', top: '16px', zIndex: 1}}/>
                    </i>
                    <i className={styles.byteFavouriteIcon}>
                      <Favourite analyticsCategory={getAnalyticsCategory()} id={brand._id} iconHeight={'12'} iconWidth={'12'} iconStyle={{left: '4px', top: '2px'}} circleWidth={'20'} circleHeight={'20'} storage={'brand'} style={{ position: 'absolute', top: '16px', right: '16px', bottom: 'unset', left: 'unset', zIndex: 1 }} />
                    </i>

                    <label className={styles.exploreMoreLabel}>Explore more</label>
                  
                  </div>
                  <div className={styles.creatorInfo}>
                      <label className={styles.name}>{brand.name}</label>
                    <label className={styles.description}>{brand.shortDescriptions}</label>
                  </div>
                </li>
              );
            })}

            {!data.brands.length && <li className={styles.noResults}>Your query returned no results.<br/> Please change your search criteria and try again</li>}
          </ul>}

          {searching ? <Oval
            height={40}
            width={40}
            color="#000000"
            wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#dbe8f6"
            strokeWidth={4}
            strokeWidthSecondary={4}
          /> : '' }

          <BottomBar analyticsCategory={`BottomBar_Listings_${selectedTab}`} selectedTab={selectedTab} />
          <ContactCreator analyticsCategory={getAnalyticsCategory()} hideCommentsBtn={true} open={openContactCreator} brand={experienceToContact.brand || {}} experience={experienceToContact} storage={'contactExperienceCreatorLeads'} setData={setExperienceData} />
        </div>
        : ''}
    </div>
  );
};

export default Listings;

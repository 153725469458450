import styles from "./EditPortfolio.module.css";
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { LoginHelper, Back, Helper, } from '../components';
import { LeftNavigation, RightNavigation, Check, BackArrow, } from "../icons";
import { Oval } from  'react-loader-spinner';
import Carousel from "react-spring-3d-carousel";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageUploading from 'react-images-uploading';


const EditPortfolio = () => {
	const [traveller, setTraveller] = useState({});
	const [invalidHandle, setInvalidHandle] = useState(false);
	let [goToSlide, setGoToSlide] = useState(0);
	const navigate = useNavigate();
	const location = useLocation();
	const { height, width } = Helper.getWindowDimensions();
	const handle = location.pathname.split('/')[2];
	const isUserLoggedIn = LoginHelper.isUserLoggedIn();
	const user = LoginHelper.getUser();
	const showEdit = isUserLoggedIn && user && user.handle === handle;
	const [emptyHandle, setEmptyHandle] = useState(false);
	const [newHandle, setNewHandle] = useState(user.handle);
	const [emptyName, setEmptyName] = useState(false);
	const [userName, setUserName] = useState(user.name);
	const [emptyBio, setEmptyBio] = useState(false);
	const [bio, setBio] = useState(user.bio);
	const [emptyAge, setEmptyAge] = useState(false);
	const [age, setAge] = useState(user.age);
	const [emptyGender, setEmptyGender] = useState(false);
	const [uploadingImg, setUploadingImg] = useState(false);
	const [gender, setGender] = useState(user.gender);
	const [travellerImage, setTravellerImage] = useState(traveller.image);
	const [submittingForm, setSubmittingForm] = useState(false);


	const routeChange = (e, url) => {
	    navigate(url);
	}

	const getSlides = () => {
		return traveller.vibes.map(uv => uv.image).map((image, i) => ({
		    key: i,
		    content: <img src={image} alt={i} />
		}));
	};

	const getCurrentVisibleVibeIndex = () => {
		const userVibesImagesLength = traveller.vibes.map(uv => uv.image).length;
  		return ((goToSlide < 0 ? (userVibesImagesLength + (goToSlide % userVibesImagesLength)) : goToSlide) % userVibesImagesLength);
  	};

	const onNavigation = (e, direction) => {
		if (direction === 'left') {
			setGoToSlide(goToSlide - 1);	
		} else {
			setGoToSlide(goToSlide + 1);
		}
	};

	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/travellers?handle=${handle}`)
	      .then((response) => response.json())
	      .then((res) => {
	        if (res.data) {
	        	const travellerRes = res.data;
	        	setTraveller(travellerRes);
	        	setUserName(travellerRes.name);
	        	setAge(travellerRes.age);
	        	setBio(travellerRes.bio);
	        	setGender(travellerRes.gender);
	        	setTravellerImage(travellerRes.image);
	        }
	        else setInvalidHandle(true);
	     })
	     .catch((err) => {
	        console.log(err.message);
	     });
	   }, {});


	const savePortfolio = async () => {
	    if (!newHandle) setEmptyHandle(true);
	    if (!userName) setEmptyName(true);

		if (!submittingForm && newHandle && userName && !emptyName && !emptyHandle) {
  			setSubmittingForm(true);
	  		await fetch(`${global.config.SERVER_URL}/api/v1/travellers/update`, {
		        method: 'POST',
		        body: JSON.stringify({
		          image: travellerImage,
		          age,
		          gender,
		          handle: newHandle,
		          name: userName,
		          bio,
		        }),
		        headers: {
		           'Content-type': 'application/json; charset=UTF-8',
		           ...Helper.getCommonHeaders(),
		        },
		        })
		        .then((response) => response.json())
		        .then((data) => {
		          setSubmittingForm(false)
		           if (data.status == 'success') {
		           		LoginHelper.setUser(data.jwt);
		           		if (!data.message) toast.success("Profile Update successfully!", Helper.ToastOptions);
		           		else toast.error(data.message, Helper.ToastOptions);
		           		if (handle != newHandle && !data.message) navigate(`/portfolio/${newHandle}/edit`);
		           }
		        })
		        .catch((err) => {
		          setSubmittingForm(false)
		          console.log(err.message);
		        });
	  	}
	};

	const onChangeHandleName = (e) => {
	    setEmptyHandle(false);
	    const result = event.target.value.replace(' ', '').replace('/', '');
	    setNewHandle(result);
	};

	const onChangeUserName = (e) => {
	    setEmptyName(false);
	    const result = event.target.value.replace(/[^a-z ]/gi, '');
	    setUserName(result);
	};

	const onChangeGender = (e) => {
	    setEmptyGender(false);
	    const result = event.target.value.replace(/[^a-z ]/gi, '');
	    setGender(result);
	};

	const onChangeBio = (e) => {
	    setEmptyBio(false);
	    setBio(e.target.value);
	};

	const onChangeAge = (e) => {
	    setEmptyAge(false)
	    if (e.target.value.length < 3)
	      setAge(e.target.value);
	};

	const goBack = () => {
		navigate(-1);
	};

	const setDefaultImage = () => {
		setTravellerImage('/default-img.png');
	};

	const onChange = async (imageList, addUpdateIndex) => {
		setUploadingImg(true);
		await fetch(`${global.config.SERVER_URL}/api/v1/uploads`, {
	        method: 'POST',
	        body: JSON.stringify({
	          image: imageList[0].data_url,
	        }),
	        headers: {
	           'Content-type': 'application/json; charset=UTF-8',
	           ...Helper.getCommonHeaders(),
	        },
	        })
	        .then((response) => response.json())
	        .then((data) => {
	           	if (data.path) setTravellerImage(data.path);
	           	setUploadingImg(false);
	        })
	        .catch((err) => {
	          console.log(err.message);
	          setUploadingImg(false);
	        });
	};

  	return (
      	<div className={styles.editPortfolio}>
      		<Back analyticsCategory={'EditPortfolio'} />
      		{showEdit && <i className={styles.savePortfolio} onClick={savePortfolio}><Check />Save Changes</i>}
      		{(!showEdit || invalidHandle) && <h1 style={{ textAlign: 'center', padding: '80px 50px', margin: '0', color: '#1C1C1E'}}>Apologies! Your are not allowed to edit this portfolio.</h1>}
   			{!invalidHandle && Object.keys(traveller).length == 0 ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		      /> : '' }

		     { showEdit && Object.keys(traveller).length != 0 ?
	     		<div className={styles.editPortfolioContainer}>
		   			<div className={styles.topContainer}>
		   				<h1 className={styles.heading}>Edit Profile</h1>
		   				<img src={uploadingImg ? '/uploading.svg' : travellerImage} />
		   				<div className={styles.imgModifyBtns}>
		   					<ImageUploading
						        multiple={false}
						        value={''}
						        onChange={onChange}
						        maxNumber={1}
						        dataURLKey="data_url"
						      >
						        {({
						          imageList,
						          onImageUpload,
						          onImageRemoveAll,
						          onImageUpdate,
						          onImageRemove,
						          isDragging,
						          dragProps,
						        }) => (
						          <div className="upload__image-wrapper">
						            <button
						            	className={styles.imgModifyBtn}
						              	style={isDragging ? { color: 'red' } : undefined}
						              	onClick={onImageUpload}
						              	{...dragProps}
						            >
						              Change
						            </button><br/>
						          </div>
						        )}
						      </ImageUploading>
		   					<button className={styles.imgModifyBtn} onClick={setDefaultImage}>Remove</button>
		   				</div>
		   			</div>

		   			<div className={styles.editPortfolioForm}>
	                    <div>
	                      <label className={styles.label}>Handle Name</label>
	                      <input className={emptyHandle ? styles.invalid : styles.valid} type="text" placeholder="Handle Name" value={newHandle} onChange={e => onChangeHandleName(e)} />
	                    </div>

	                    <div>
	                      <label className={styles.label}>Your Name</label>
	                      <input className={emptyName ? styles.invalid : styles.valid} type="text" placeholder="Name" value={userName} onChange={e => onChangeUserName(e)} />
	                    </div>

	                    <div>
		                    <label className={styles.label}>Your Bio</label>
		                    <textarea className={emptyBio ? styles.invalid : styles.valid} rows="5" placeholder="Bio..." value={bio} onChange={e => onChangeBio(e)} />
		                </div>

		                <div className={styles.ageWrap}>
		                    <label className={styles.label}>Age</label>
		                    <input className={emptyAge ? styles.invalid : styles.valid} type="number" placeholder="age" value={age} onChange={e => onChangeAge(e)} />
		                </div>

		                <div>
	                      <label className={styles.label}>Gender</label>
	                      <input className={emptyGender ? styles.invalid : styles.valid} type="text" placeholder="Gender" value={gender} onChange={e => onChangeGender(e)} />
	                    </div>
	                  </div>

		   			{traveller.vibes && <div className={styles.vibes} style={{ minHeight: width <= 375 ? '170px': ''}}>
						<div className={`user-vibes-carousel ${styles.userVibesCarousel}`}>
					      <i className={styles.leftNavigation} onClick={event => onNavigation(event, 'left')}><LeftNavigation /></i>
					      <i className={styles.rightNavigation} onClick={event => onNavigation(event, 'right')}><RightNavigation /></i>
					      <Carousel
					      	offsetRadius={1}
					      	goToSlide={goToSlide}
					        slides={getSlides()}
					        showNavigation={false}
					      />
		                </div>
		                <div className={styles.vibesTitleAndDesc}>
			                <h3>{traveller.vibes[getCurrentVisibleVibeIndex()].title}</h3>
							<p>{traveller.vibes[getCurrentVisibleVibeIndex()].description}</p>
						</div>
		   			</div>}

		   			<button className={styles.recheckVibes} onClick={event => routeChange(event, '/xpvibes')}>Check Vibe Again</button>
		   			<button className={styles.savePortfolioBtn} onClick={savePortfolio}><Check color='#fff' />Save Changes</button>
		   			<button className={styles.discardBtn} onClick={goBack}><BackArrow />Discard Changes and Go Back</button>
		   			<ToastContainer />
		   		</div>
		     : ''}
      	</div>
  	);
};

export default EditPortfolio;

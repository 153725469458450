const RightBigArrow = (props) => {
  return (
	<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="fi-rr-arrow-small-left">
		<path id="Vector" d="M10.8333 28.1669L32.5 28.1669L25.3717 35.2952C25.1686 35.4967 25.0074 35.7363 24.8974 36.0003C24.7874 36.2643 24.7308 36.5475 24.7308 36.8336C24.7308 37.1196 24.7874 37.4028 24.8974 37.6668C25.0074 37.9308 25.1686 38.1705 25.3717 38.3719C25.7776 38.7754 26.3268 39.002 26.8992 39.002C27.4716 39.002 28.0207 38.7754 28.4267 38.3719L37.7217 29.0552C38.5354 28.2463 38.9952 27.1477 39 26.0003C38.9895 24.8604 38.5302 23.7705 37.7217 22.9669L28.4267 13.6503C28.2247 13.4497 27.9851 13.2908 27.7217 13.1828C27.4583 13.0748 27.1762 13.0197 26.8915 13.0207C26.6068 13.0217 26.3251 13.0788 26.0625 13.1887C25.7998 13.2986 25.5614 13.4591 25.3608 13.6611C25.1602 13.8631 25.0014 14.1027 24.8934 14.3661C24.7854 14.6295 24.7303 14.9116 24.7313 15.1963C24.7323 15.4809 24.7894 15.7626 24.8992 16.0253C25.0091 16.2879 25.1697 16.5263 25.3717 16.7269L32.5 23.8336L10.8333 23.8336C10.2587 23.8336 9.7076 24.0619 9.30127 24.4682C8.89494 24.8745 8.66667 25.4256 8.66667 26.0002C8.66667 26.5749 8.89494 27.126 9.30127 27.5323C9.70759 27.9386 10.2587 28.1669 10.8333 28.1669Z" fill={props.fill || '#F2F2F7'}/>
		</g>
	</svg>
  );
};

export default RightBigArrow;

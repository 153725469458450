import styled from 'styled-components'

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1000000000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  @media screen and (min-width: 913px) {
    width: 480px;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
  }
`

const ModalBody = styled.div`
  bottom: 30px;
  position: absolute;
  margin: 0 30px;
  padding: 25px;
  border-radius: 23.5px;
  background: #fff;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
  width: calc(100% - 110px);
`

export const StyledModal = {
  ModalWrapper,
  ModalBody,
}

const CalendarBlueIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <g clipPath="url(#clip0_1506_6332)">
        <path d="M11.875 1.25H11.25V0.625C11.25 0.45924 11.1842 0.300269 11.0669 0.183058C10.9497 0.065848 10.7908 0 10.625 0C10.4592 0 10.3003 0.065848 10.1831 0.183058C10.0658 0.300269 10 0.45924 10 0.625V1.25H5V0.625C5 0.45924 4.93415 0.300269 4.81694 0.183058C4.69973 0.065848 4.54076 0 4.375 0C4.20924 0 4.05027 0.065848 3.93306 0.183058C3.81585 0.300269 3.75 0.45924 3.75 0.625V1.25H3.125C2.2965 1.25099 1.50222 1.58055 0.916387 2.16639C0.330551 2.75222 0.000992411 3.5465 0 4.375L0 11.875C0.000992411 12.7035 0.330551 13.4978 0.916387 14.0836C1.50222 14.6694 2.2965 14.999 3.125 15H11.875C12.7035 14.999 13.4978 14.6694 14.0836 14.0836C14.6694 13.4978 14.999 12.7035 15 11.875V4.375C14.999 3.5465 14.6694 2.75222 14.0836 2.16639C13.4978 1.58055 12.7035 1.25099 11.875 1.25ZM1.25 4.375C1.25 3.87772 1.44754 3.40081 1.79917 3.04917C2.15081 2.69754 2.62772 2.5 3.125 2.5H11.875C12.3723 2.5 12.8492 2.69754 13.2008 3.04917C13.5525 3.40081 13.75 3.87772 13.75 4.375V5H1.25V4.375ZM11.875 13.75H3.125C2.62772 13.75 2.15081 13.5525 1.79917 13.2008C1.44754 12.8492 1.25 12.3723 1.25 11.875V6.25H13.75V11.875C13.75 12.3723 13.5525 12.8492 13.2008 13.2008C12.8492 13.5525 12.3723 13.75 11.875 13.75Z" fill="#47A8BD"/>
        <path d="M7.5 10.3125C8.01777 10.3125 8.4375 9.89277 8.4375 9.375C8.4375 8.85723 8.01777 8.4375 7.5 8.4375C6.98223 8.4375 6.5625 8.85723 6.5625 9.375C6.5625 9.89277 6.98223 10.3125 7.5 10.3125Z" fill="#47A8BD"/>
        <path d="M4.37488 10.3125C4.89264 10.3125 5.31238 9.89277 5.31238 9.375C5.31238 8.85723 4.89264 8.4375 4.37488 8.4375C3.85711 8.4375 3.43738 8.85723 3.43738 9.375C3.43738 9.89277 3.85711 10.3125 4.37488 10.3125Z" fill="#47A8BD"/>
        <path d="M10.6251 10.3125C11.1429 10.3125 11.5626 9.89277 11.5626 9.375C11.5626 8.85723 11.1429 8.4375 10.6251 8.4375C10.1074 8.4375 9.68762 8.85723 9.68762 9.375C9.68762 9.89277 10.1074 10.3125 10.6251 10.3125Z" fill="#47A8BD"/>
      </g>
      <defs>
        <clipPath id="clip0_1506_6332">
          <rect width="15" height="15" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarBlueIcon;

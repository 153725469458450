import styles from "./SummerBreeze.module.css";
import { Oval } from  'react-loader-spinner';
import { Instagram } from "../icons";
import { BookNowPopup } from "../components";
import React, { useState } from "react";


const SummerBreeze = () => {
    const [loaded, setLoaded] = useState(false);
	
  	return (
      	<div className={styles.summerBreeze}>
            {!loaded && 
              <div className={styles.loadedContainer}>
                <Oval
                height={40}
                width={40}
                color="#000"
                wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#dbe8f6"
                strokeWidth={4}
                strokeWidthSecondary={4}
              /></div>}

        		<img className={styles.mainImg} src="/summer-breeze-v4.jpg" onLoad={() => setLoaded(true)} />
        		<div className={styles.footer}>
              <div style={{overflow: 'hidden'}}>
        			  <h3>Follow for Updates</h3>
        			  <a className={styles.instagram} href="https://www.instagram.com/xp.hive" target="_blank" rel="noreferrer" aria-label="instagram"><i><Instagram width={68} height={68} /></i></a>
              </div>
              <div className={styles.instagramLinks}>
                <a href="https://www.instagram.com/xp.hive" target="_blank" rel="noreferrer" aria-label="instagram">@xp.hive</a>
                <a href="https://www.instagram.com/sukoon_rh" target="_blank" rel="noreferrer" aria-label="instagram">@sukoon_rh</a>
                <a href="https://www.instagram.com/theradiohills" target="_blank" rel="noreferrer" aria-label="instagram">@theradiohills</a>
                <a href="https://www.instagram.com/thespotlight_rh" target="_blank" rel="noreferrer" aria-label="instagram">@thespotlight_rh</a>
                <a href="https://www.instagram.com/xanadu_nestinpeace" target="_blank" rel="noreferrer" aria-label="instagram">@xanadu_nestinpeace</a>
              </div>
        		</div>

            <img className={styles.footerImg} src="/sbc-presented-by-v1.png" />
        		<BookNowPopup />
      	</div>
  	);
};

export default SummerBreeze;

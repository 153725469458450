import styles from "./Portfolio.module.css";
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import { Setting, Edit, LeftNavigation, RightNavigation, } from "../icons";
import { LoginHelper, Helper, Share, Back, ContactCreator, BottomBar, } from '../components';
import { Oval } from  'react-loader-spinner';
import Carousel from "react-spring-3d-carousel";


const Portfolio = () => {
	const [traveller, setTraveller] = useState({});
	const [invalidHandle, setInvalidHandle] = useState(false);
	const [selectedTab, setSelectedTab] = useState('bucket');
	const [contactCreatorStorage, setContactCreatorStorage] = useState('contactExperienceCreatorLeads');
	const [experience, setExperience] = useState({});
	const [brand, setBrand] = useState({});
	const { height, width } = Helper.getWindowDimensions();
	let [goToSlide, setGoToSlide] = useState(0);
	const [openContactCreator, setOpenContactCreator] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const handle = location.pathname.replace('/portfolio/', '');
	const isUserLoggedIn = LoginHelper.isUserLoggedIn();
	const user = LoginHelper.getUser();
	const showEditAndSetting = isUserLoggedIn && user && user.handle === handle;


	const routeChange = (e, url) => {
	    navigate(url);
	}

	const setData = (experience, storage, open, brand={}) => {
  		setExperience(experience);
  		setContactCreatorStorage(storage);
  		setOpenContactCreator(open);
  		setBrand(brand);
  	};

	const getSlides = () => {
		return traveller.vibes.map(uv => uv.image).map((image, i) => ({
		    key: i,
		    content: <img src={image} alt={i} />
		}));
	};

	const getCurrentVisibleVibeIndex = () => {
		const userVibesImagesLength = traveller.vibes.map(uv => uv.image).length;
  		return ((goToSlide < 0 ? (userVibesImagesLength + (goToSlide % userVibesImagesLength)) : goToSlide) % userVibesImagesLength);
  	};

	const onNavigation = (e, direction) => {
		if (direction === 'left') {
			setGoToSlide(goToSlide - 1);	
		} else {
			setGoToSlide(goToSlide + 1);
		}
	};

	const switchTab = (e, tab) => {
		setSelectedTab(tab);
	};

	const bucketListClick = (e, bucket) => {
		if (bucket.type === 'brand') {
			navigate(`/brands/${Helper.generateURL(bucket.title)}`);
		} else if (bucket.type === 'blog') {
			navigate(`/blogs/${bucket.slug}`);
		} else if (bucket.type === 'wishlist') {
			navigate(`/wishlists/${bucket.id}`);
		} else if (bucket.type === 'experiencebyte') {
			console.log('Link here please.')
		} else {
			setData({id: bucket.id}, 'contactExperienceCreatorLeads', true, {name: bucket.brand.name, id: bucket.brand.id});
		}
	};

	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/travellers?handle=${handle}`, {
	        headers: {
	           ...Helper.getCommonHeaders(),
	        }})
	      	.then((response) => response.json())
	      	.then((res) => {
	        	if (res.data) setTraveller(res.data);
	        	else setInvalidHandle(true);
	     	})
	     	.catch((err) => {
	        	console.log(err.message);
	     	});

	    if (Helper.storage.getItem('showLoginSuccess')) {
	  		toast.success("Logged in successfully!", Helper.ToastOptions);
	  		Helper.storage.removeItem('showLoginSuccess');
	  	}
	   }, {});


  	return (
      	<div className={styles.portfolio}>
      		<div style={{position: 'relative'}}>
      			{!showEditAndSetting && <Back analyticsCategory={'Portfolio'} />}
	      		{showEditAndSetting && <i className={styles.setting} onClick={event => routeChange(event, location.pathname + '/setting')}><Setting /></i>}
	      		{showEditAndSetting && <i className={styles.editProfile} onClick={event => routeChange(event, location.pathname + '/edit')}><Edit />Edit Profile</i>}
	      		<Share analyticsCategory={'Portfolio'} blackIcon={true} style={{position: 'absolute', right: (showEditAndSetting ? '220px' : '58px'), top: (showEditAndSetting ? '44px' : '34px')}}/>
      		</div>

      		{invalidHandle && <h1 style={{ textAlign: 'center', padding: '80px 50px', margin: '0', color: '#1C1C1E'}}>Apologies! XPHive does not have this portfolio listed.</h1>}
   			{!invalidHandle && Object.keys(traveller).length == 0 ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		      /> : '' }

		     { Object.keys(traveller).length != 0 ?
	     		<div className={styles.portfolioContainer} style={{paddingTop: (showEditAndSetting ? '30px' : '80px'), paddingBottom: (showEditAndSetting ? '90px' : '30px') }}>
		   			<div className={styles.topContainer}>
		   				<img src={traveller.image} />

		   				<div className={styles.nameAndHandle}>
		   					<h2>{traveller.name}</h2>
		   					<label>{traveller.handle}</label>
		   				</div>

		   				<p>{traveller.bio}</p>
		   			</div>

		   			{traveller.vibes && <div className={styles.vibes} style={{ minHeight: width <= 375 ? '170px': ''}}>
						<div className={`user-vibes-carousel ${styles.userVibesCarousel}`}>
					      <i className={styles.leftNavigation} onClick={event => onNavigation(event, 'left')}><LeftNavigation /></i>
					      <i className={styles.rightNavigation} onClick={event => onNavigation(event, 'right')}><RightNavigation /></i>
					      <Carousel
					      	offsetRadius={1}
					      	goToSlide={goToSlide}
					        slides={getSlides()}
					        showNavigation={false}
					      />
		                </div>
		                <div className={styles.vibesTitleAndDesc}>
			                <h3>{traveller.vibes[getCurrentVisibleVibeIndex()].title}</h3>
							<p>{traveller.vibes[getCurrentVisibleVibeIndex()].description}</p>
						</div>
		   			</div>}

		   			{(traveller.bucket && (traveller.bucket.list.length > 0 || traveller.bucket.list.completed > 0 || showEditAndSetting)) &&
		   			 <div className={styles.bucketListContainer}>
		   				<div className={styles.tabContainer}>
			   				<button className={`${selectedTab === 'bucket' ? 'selectedTab': ''} ${styles.tabBtn}`} onClick={event => switchTab(event, 'bucket')}>Bucket list</button>
			   				<button className={`${selectedTab === 'completed' ? 'selectedTab': ''} ${styles.tabBtn}`} onClick={event => switchTab(event, 'completed')}>Completed</button>
			   				<span style={{left: (selectedTab === 'bucket' ? 'calc((100% - 280px) / 2)' : 'calc((100% - 280px) / 2 + 140px)') }}></span>


			   				{selectedTab === 'bucket' &&
			   					<ul className={styles.bucketList}>
			   						{showEditAndSetting && <li className={styles.bucketListItem} onClick={event => routeChange(event, '/custom-wishlist')}>
			   							<div className={styles.customBLWrap}>
				   							<img src="/add-c-bl.svg" style={{marginTop: '10px'}}/>
				   							<label>Custom</label>
			   							</div>
			   							<h4>Add Custom list...</h4>
			   						</li>}

			   						{traveller.bucket.list.map((bl, i) => {
                						return (
                							<li className={styles.bucketListItem} key={i} onClick={event => bucketListClick(event, bl)}>
					   							<div>
						   							<img className={styles.blImage} src={bl.image} />
					   							</div>
					   							<h4>{bl.title}</h4>
					   						</li>
                						);
              						})}
			   					</ul>
			   				}

			   				{selectedTab === 'completed' &&
			   					<ul className={styles.bucketList}>
			   						<li className={styles.emptyCompletedBucket}>None of the Bucket list Item Completed</li>
			   					</ul>
			   				}
		   				</div>
		   			</div>}

		   			{showEditAndSetting && <BottomBar analyticsCategory={'BottomBar_Portfolio'} />}

		   			<ContactCreator analyticsCategory={'Portfolio'} hideCommentsBtn={true} open={openContactCreator} brand={brand} experience={experience} storage={'contactExperienceCreatorLeads'} setData={setData} />
		   		</div>
		     : ''}

		    <ToastContainer />
      	</div>
  	);
};

export default Portfolio;

const Location = () => {
  return (
		<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="fi-rr-marker" clipPath="url(#clip0_1921_3996)">
				<path id="Vector" d="M10.5003 5.5C9.84106 5.5 9.19659 5.6955 8.64843 6.06177C8.10026 6.42804 7.67302 6.94863 7.42073 7.55772C7.16844 8.16681 7.10243 8.83703 7.23104 9.48363C7.35966 10.1302 7.67713 10.7242 8.1433 11.1904C8.60948 11.6565 9.20342 11.974 9.85003 12.1026C10.4966 12.2312 11.1669 12.1652 11.7759 11.9129C12.385 11.6606 12.9056 11.2334 13.2719 10.6852C13.6382 10.1371 13.8337 9.4926 13.8337 8.83333C13.8337 7.94928 13.4825 7.10143 12.8574 6.47631C12.2322 5.85119 11.3844 5.5 10.5003 5.5ZM10.5003 10.5C10.1707 10.5 9.84846 10.4023 9.57438 10.2191C9.3003 10.036 9.08667 9.77568 8.96053 9.47114C8.83438 9.1666 8.80138 8.83148 8.86569 8.50818C8.92999 8.18488 9.08873 7.88791 9.32182 7.65482C9.5549 7.42173 9.85188 7.263 10.1752 7.19869C10.4985 7.13438 10.8336 7.16739 11.1381 7.29353C11.4427 7.41968 11.703 7.6333 11.8861 7.90738C12.0692 8.18146 12.167 8.5037 12.167 8.83333C12.167 9.27536 11.9914 9.69928 11.6788 10.0118C11.3663 10.3244 10.9424 10.5 10.5003 10.5Z" fill="#FFEA00"/>
				<path id="Vector_2" d="M10.5003 20.5007C9.79855 20.5043 9.10619 20.3397 8.48115 20.0207C7.85611 19.7018 7.31659 19.2377 6.90776 18.6674C3.73193 14.2865 2.12109 10.9932 2.12109 8.87819C2.12109 6.6559 3.0039 4.52462 4.57529 2.95322C6.14669 1.38183 8.27797 0.499023 10.5003 0.499023C12.7226 0.499023 14.8538 1.38183 16.4252 2.95322C17.9966 4.52462 18.8794 6.6559 18.8794 8.87819C18.8794 10.9932 17.2686 14.2865 14.0928 18.6674C13.6839 19.2377 13.1444 19.7018 12.5194 20.0207C11.8943 20.3397 11.202 20.5043 10.5003 20.5007ZM10.5003 2.31819C8.76061 2.32018 7.09277 3.01213 5.86265 4.24225C4.63253 5.47237 3.94058 7.1402 3.93859 8.87986C3.93859 10.5549 5.51609 13.6524 8.37943 17.6015C8.62251 17.9363 8.9414 18.2088 9.31002 18.3967C9.67864 18.5846 10.0865 18.6826 10.5003 18.6826C10.914 18.6826 11.3219 18.5846 11.6905 18.3967C12.0591 18.2088 12.378 17.9363 12.6211 17.6015C15.4844 13.6524 17.0619 10.5549 17.0619 8.87986C17.0599 7.1402 16.368 5.47237 15.1379 4.24225C13.9077 3.01213 12.2399 2.32018 10.5003 2.31819Z" fill="black"/>
			</g>
			<defs>
			<clipPath id="clip0_1921_3996">
				<rect width="20" height="20" fill="white" transform="translate(0.5 0.5)"/>
			</clipPath>
			</defs>
		</svg>
  	);
};

export default Location;

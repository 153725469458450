import parse from 'html-react-parser'
import React, { useEffect, useState, useRef, } from "react";
import styles from "./Experiences.module.css";
import { useNavigate } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Favourite, Helper } from "../components";
import { CalendarBlueIcon, TimeIcon, Rating } from '../icons';


const Experiences = (props) => {
    const navigate = useNavigate();
    const experiences = props.experiences;
    const setData = props.setData;
    const containerId = props.containerId || 'upcomingExperienceContainer';
    const [scrollbarLeft, setScrollbarLeft] = useState(0);
    const experienceRef = useRef(null);
    const [experienceHeight, setExperienceHeight] = useState(0);


    useEffect(() => {
      if (experienceRef && experienceRef.current && experienceHeight === 0) {
        let height = 0;
        for (let c of experienceRef.current.children) {
          if (height < c.clientHeight) height = c.clientHeight;
        }  
        setExperienceHeight(height);
      }
    });

    const handleExperiencesScroll = () => {
      const totalScrollPossible = 230 * (experiences.length - 1);
      let left = (document.getElementById(containerId).scrollLeft / totalScrollPossible) * 100;
      left = left >= 100 ? 100 : left;
      const substract = left >= 80 ? ' - ' + ((left - 80) * 1.75) + 'px' : '';
      setScrollbarLeft('calc(' + left + '%' + substract  + ')');
    };

    const routeChange = (e, url) => {
      Helper.trackAnalytics(props.analyticsCategory, `Click_${Helper.titleCase(url.split('/')[url.split('/').length - 1].replaceAll('-', '_'))}`, `Navigate`);
      navigate(url);
    };

    const onExperienceClick = (e, experience) => {
      if (experience.version && experience.version === 2) {
        routeChange(e, `/experiences/${experience.slug}`);
      } else {
        Helper.trackAnalytics(props.analyticsCategory, `Click_${Helper.titleCase(experience.title.split('<label>')[0].split(' ').join('_'))}`, 'Experience Click');
        setData({id: experience._id, brand: experience.brand}, 'contactExperienceCreatorLeads', true);
      }
    };

    return (
        <div className={styles.upcomingExperiences} style={{backgroundColor: props.removeBGColor ? 'transparent' : props.brand.bgColor}}>
          <h3 className={styles.upcomingExperiencesHeading} style={{color: (props.heading ? props.heading.color : ''), fontSize: (props.heading ? props.heading.fontSize : ''), fontFamily: (props.heading ? props.heading.fontFamily : '') }}>{props.heading ? props.heading.text : 'Top Experiences'}</h3>
          {
            Object.keys(experiences).length != 0 &&
            <div ref={experienceRef} id={containerId} className="upcomingExperienceContainer" onScroll={handleExperiencesScroll}>
              {experiences.map((experience, i) => {
                return (
                  <div onClick={(e) => { e.stopPropagation(); onExperienceClick(e, experience); }} className={styles.experiencesCarouselParent} key={i} style={{'width': experiences.length == 1 ? 'calc(100% - 70px)' : '', ...props.experienceStyle, height: experienceHeight - 45}}>
                    <div className={styles.experiencesCarousel}>
                      <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={5} showIndicators={experience.images.length > 1} autoPlay={false} showArrows={false} infiniteLoop={true} stopOnHover={false} showThumbs={false} showStatus={false}>
                        {experience.images.map((image, i) => {
                          return (
                            <div key={i}>
                              <img className={styles.image} src={image} />
                            </div>
                          );
                        })}
                      </Carousel>
                      <i className={styles.favouriteIconContainer}>
                        <Favourite analyticsCategory={props.analyticsCategory} id={experience._id} storage={'experience'} style={{ position: 'absolute', bottom: '10px', left: '10px', top: 'unset', right: 'unset'}} />
                      </i>
                    </div>
                    <div className={styles.experienceLocationAndDuration} style={{display: 'none'}}>
                      <i className={styles.calendarIcon}><CalendarBlueIcon /></i>
                      <label className={styles.experienceDate}>{Helper.formatDate(new Date())}</label>
                      <i className={styles.timeIcon}><TimeIcon /></i>
                      <label className={styles.experienceDuration}>{Helper.diffDays(new Date(), new Date())} Days</label>
                    </div>


                    <div className={styles.experienceInfoAndVibes}>
                      <div className={styles.experienceInfo}>
                        <label className={styles.title}>{parse(experience.title)}</label>
                        <label style={{display: 'none'}} className={styles.price}>₹{experience.unitPrice ? experience.unitPrice.toLocaleString() : ''}/-  <span className={styles.unit}>Per Person</span></label>
                        <p className={styles.description}>{experience.description}</p>
                        <label style={{display: 'none'}} className={styles.experienceRating}><Rating /> {experience.overallRating}</label>
                      </div>
                   
                      {experience.group && <div className={styles.groupInfoWrap} style={{display: 'none'}}>
                        <div className={styles.progressIcon}>
                          <CircularProgressbar value={(experience.group.joined / experience.group.size) * 100} strokeWidth={8} styles={buildStyles({
                            pathColor: '#1C1C1E',
                            trailColor: '#F2F2F7',
                            backgroundColor: '#fff',
                          })} />
                        </div>
                        <label className={styles.groupStatus}>{`${experience.group.joined}/${experience.group.size} Joined`}</label>
                        <label className={styles.groupSize}>{experience.group.size}</label>
                      </div>}
                    </div>
                  </div>
                );
              })}
            </div>
          }
          {experiences.length > 1 ? <div className={styles.scrollWrap}>
            <span className={styles.scrollLine} style={{background: props.scrollLineColor ? props.scrollLineColor : ''}}></span>
            <span className={styles.scrollBar} style={{'left': scrollbarLeft, background: props.scrollBarColor ? props.scrollBarColor : ''}}></span>
          </div> : ''}
        </div>
    );  
}  
export default Experiences;

const CloseWhite = (props) => {
  	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_2663_1662)">
				<path d="M14.1209 12L23.5605 2.56137C24.1465 1.97541 24.1465 1.02538 23.5605 0.43947C22.9745 -0.14649 22.0245 -0.14649 21.4386 0.43947L12 9.87905L2.56137 0.43947C1.97541 -0.14649 1.02538 -0.14649 0.43947 0.43947C-0.146443 1.02543 -0.14649 1.97545 0.43947 2.56137L9.87905 12L0.43947 21.4386C-0.14649 22.0246 -0.14649 22.9746 0.43947 23.5605C1.02543 24.1464 1.97545 24.1465 2.56137 23.5605L12 14.1209L21.4386 23.5605C22.0245 24.1465 22.9745 24.1465 23.5605 23.5605C24.1464 22.9745 24.1464 22.0245 23.5605 21.4386L14.1209 12Z" fill={props.fill || "#F2F2F7"} />
			</g>
			<defs>
				<clipPath id="clip0_2663_1662">
					<rect width="24" height="24" fill="white"/>
				</clipPath>
			</defs>
		</svg>
  	);
};

export default CloseWhite;

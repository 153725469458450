import styles from "./PlanWithXPHive.module.css";
import React, { useState, useEffect } from 'react';  
import { Helper, Back, Share, } from "../components";


const PlanWithXPHive = () => {
  const [formState, setFormState] = useState({
    phone: '',
    name: '',
    email: '',
    message: '',
  });
  const [formErrorState, setFormErrorState] = useState({
    phone: false,
    name: false,
    email: false,
    message: false,
  });
  const [submittingForm, setSubmittingForm] = useState(false);
  const [leadSubmitted, setLeadSubmitted] = useState(Helper.storage.getItem('PlanWithXPHive') || false);

  const onChangeInput = (e, type) => {
      e.preventDefault();

      if (type === 'phone') {
        formErrorState['phone'] = false;
        setFormErrorState({...formErrorState});
        if (e.target.value.length < 11) {
          formState['phone'] = e.target.value;
          setFormState({...formState});
        }
      } else if (type === 'name') {
        formErrorState.name = false;
        setFormErrorState({...formErrorState});
        const result = e.target.value.replace(/[^a-z ]/gi, '');
        formState.name = result;
        setFormState({...formState});
      } else if (type === 'email') {
        formErrorState.email = false;
        setFormErrorState({...formErrorState});
        formState.email = e.target.value;
        setFormState({...formState});
      } else if (type === 'message') {
        formErrorState.message = false;
        setFormErrorState({...formErrorState});
        formState.message = e.target.value;
        setFormState({...formState});
      }
    };

  const setLeads = () => {
    setLeadSubmitted(true);
    Helper.storage.setItem('PlanWithXPHive', true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.name) {
      formErrorState['name'] = true;
      setFormErrorState({...formErrorState});
    }

    if (formState.phone.length != 10) {
      formErrorState['phone'] = true;
      setFormErrorState({...formErrorState});
    }
    
    if (!formState.email) {
      formErrorState['email'] = true;
      setFormErrorState({...formErrorState});
    }

    if (!formState.message) {
      formErrorState['message'] = true;
      setFormErrorState({...formErrorState});
    }


    if (!submittingForm && formState.name && formState.phone && formState.email && formState.message && !formErrorState.name 
      && !formErrorState.phone && !formErrorState.email && !formErrorState.message) {
      Helper.trackAnalytics('PlanWithXPHive', 'Click_Submit_details', formState.phone);
      setSubmittingForm(true);

      await fetch(`${global.config.SERVER_URL}/api/v1/leads`, {
        method: 'POST',
        body: JSON.stringify({
          name: formState.name,
          email: formState.email,
          phone: formState.phone,
          message: formState.message,
          type: 'planWithXPHive',
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => response.json())
        .then((data) => {
          setSubmittingForm(false);
          if (data.status == 'success') setLeads();
        })
        .catch((err) => {
          setSubmittingForm(false);
          console.log(err.message);
        });
    }
  };
  
  return (
      <div className={styles.planWithXPHive}>
      	<i className={styles.backIcon}><Back /></i>
        <i className={styles.shareIcon}><Share hideCircle={true} style={{position: 'absolute', right: '25px', top: '27px'}} blackIcon={true} /></i>

        {!leadSubmitted &&
          <div>
            <h1 className={styles.heading}>Come travel with us!</h1>

            <form onSubmit={handleSubmit}>
              <div>
                <label className={styles.label}>Your name... *</label>
                <input className={formErrorState.name ? styles.invalid : styles.valid} type="text" placeholder="Name" value={formState.name} onChange={e => onChangeInput(e, 'name')} />
              </div>

              <div>
                <label className={styles.label}>WhatsApp number... *</label>
                <input className={formErrorState.phone ? styles.invalid : styles.valid} type="number" placeholder="+91 -" value={formState.phone} onChange={e => onChangeInput(e, 'phone')} />
              </div>

              <div>
                <label className={styles.label}>Email ID... *</label>
                <input className={formErrorState.email ? styles.invalid : styles.valid} type="email" placeholder="Email" value={formState.email} onChange={e => onChangeInput(e, 'email')} />
              </div>

              <div>
	              <label className={styles.label}>Please share some details about your travel plans *</label>
	              <textarea className={formErrorState.message ? styles.invalid : styles.valid} placeholder="Message" value={formState.message} rows="3" onChange={e => onChangeInput(e, 'message')} />
	          </div>

              <button type="submit">Submit</button>
            </form>
          </div>
        }

        {leadSubmitted && 
          <div>
            <h3 className={styles.success}>We hear you friend! Our reply is racing to your inbox!</h3>
          </div>
        }
    </div>
  )
}

export default PlanWithXPHive;

const TimeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
	  <g clipPath="url(#clip0_1506_6336)">
	    <path d="M14.3749 6.875C14.2092 6.875 14.0502 6.94085 13.933 7.05806C13.8158 7.17527 13.7499 7.33424 13.7499 7.5C13.755 8.9533 13.2552 10.3632 12.3359 11.4888C11.4166 12.6145 10.1349 13.3858 8.70988 13.6711C7.28486 13.9564 5.80498 13.738 4.52319 13.053C3.2414 12.3681 2.23729 11.2593 1.68247 9.91605C1.12765 8.57281 1.05658 7.07858 1.4814 5.68876C1.90622 4.29893 2.80055 3.09979 4.01154 2.29629C5.22253 1.4928 6.67499 1.13485 8.12066 1.28361C9.56634 1.43237 10.9155 2.07861 11.9374 3.11188C11.9169 3.11748 11.896 3.12187 11.8749 3.125H9.99995C9.83419 3.125 9.67522 3.19085 9.55801 3.30806C9.4408 3.42527 9.37495 3.58424 9.37495 3.75C9.37495 3.91576 9.4408 4.07473 9.55801 4.19194C9.67522 4.30915 9.83419 4.375 9.99995 4.375H11.8749C12.3722 4.375 12.8491 4.17746 13.2008 3.82583C13.5524 3.47419 13.7499 2.99728 13.7499 2.5V0.625C13.7499 0.45924 13.6841 0.300269 13.5669 0.183058C13.4497 0.065848 13.2907 0 13.1249 0C12.9592 0 12.8002 0.065848 12.683 0.183058C12.5658 0.300269 12.4999 0.45924 12.4999 0.625V1.91563C11.2203 0.771581 9.58677 0.101354 7.87236 0.0169391C6.15794 -0.0674758 4.46648 0.439036 3.08062 1.45183C1.69477 2.46463 0.698471 3.92236 0.258215 5.58144C-0.182041 7.24052 -0.039586 9.00043 0.661776 10.5671C1.36314 12.1338 2.58092 13.4123 4.11163 14.189C5.64233 14.9657 7.39324 15.1936 9.07176 14.8345C10.7503 14.4755 12.2547 13.5513 13.3337 12.2163C14.4127 10.8813 15.0009 9.2165 14.9999 7.5C14.9999 7.33424 14.9341 7.17527 14.8169 7.05806C14.6997 6.94085 14.5407 6.875 14.3749 6.875Z" fill="#47A8BD"/>
	    <path d="M7.50012 3.75C7.33436 3.75 7.17539 3.81585 7.05818 3.93306C6.94097 4.05027 6.87512 4.20924 6.87512 4.375V7.5C6.87516 7.66575 6.94103 7.82469 7.05825 7.94188L8.93325 9.81688C9.05112 9.93072 9.209 9.99372 9.37287 9.9923C9.53675 9.99087 9.6935 9.92514 9.80938 9.80926C9.92526 9.69338 9.99099 9.53662 9.99242 9.37275C9.99384 9.20888 9.93085 9.051 9.817 8.93313L8.12512 7.24125V4.375C8.12512 4.20924 8.05927 4.05027 7.94206 3.93306C7.82485 3.81585 7.66588 3.75 7.50012 3.75Z" fill="#47A8BD"/>
	  </g>
	  <defs>
	    <clipPath id="clip0_1506_6336">
	      <rect width="15" height="15" fill="white"/>
	    </clipPath>
	  </defs>
	</svg>
  );
};

export default TimeIcon;

import Sidebar from "react-sidebar";
import { Hamburger, CloseWhite, Instagram, Email, } from '../icons';
import { LoginHelper, Helper, } from '../components';
import styles from "./Sidebar.module.css";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";



const SideBar = (props) => {
  	const navigate = useNavigate();
  	const [sidebarOpen, setSidebarOpen] = useState(false);
    const user = LoginHelper.getUser();
     const { height, width } = Helper.getWindowDimensions();
    const isUserLoggedIn = LoginHelper.isUserLoggedIn();

  	const onSetSidebarOpen = (open) => {
      Helper.trackAnalytics(props.analyticsCategory || 'Sidebar', `Click_${open ? 'Open': 'Close'}_sidebar`, 'Toggle Sidebar');
    	setSidebarOpen(open);
  	}

  	const routeChange = (e, url) => {
      Helper.trackAnalytics(props.analyticsCategory || 'Sidebar', `Click_${Helper.titleCase(url.split('/')[url.split('/').length - 1].replaceAll('-', '_'))}`, `Navigate`);
    	navigate(url);
  	}
 

	return (
    <div>
      <span onClick={() => onSetSidebarOpen(true)} className={styles.menu}>
        <Hamburger />
      </span>
  	  <Sidebar
  	    sidebar={
  	    	<div className={styles.sidebarContent}>
            <i className={styles.closeIcon} onClick={() => onSetSidebarOpen(false)}><CloseWhite fill={'rgba(255, 234, 0, 1)'} /></i>
            { !isUserLoggedIn && <h3 className={styles.sidebarHeading}><span>Hey,</span><br/><span className={styles.userName}>User</span></h3>}
            { isUserLoggedIn && <h3 onClick={event => routeChange(event, '/portfolio/' + user.handle)} className={styles.sidebarHeading}><span>Hey,</span><br/><span className={styles.userName}>{user.name}</span></h3>}
            { !isUserLoggedIn && <label className={styles.loginSignup} onClick={event => routeChange(event, '/login-signup')}>Login / Sign Up</label> }
            { isUserLoggedIn && <label className={styles.vibe} onClick={event => routeChange(event, '/xpvibes')}>Recreate vibe</label> }
            <label className={styles.aboutUs} onClick={event => routeChange(event, '/about-us')}>About Us</label>


            <div className={styles.contactUsWrap}>
              <label className={styles.contactUs}>Connect with Us :</label>
              <a className={styles.instagram} href="https://www.instagram.com/xp.hive" target="_blank" rel="noreferrer" aria-label="instagram"><i><Instagram /></i>@xphive</a>
              <a className={styles.email} href="mailto: hello@xphive.com"><i><Email /></i>hello@xphive.com</a>
            </div>

            <div className={styles.policyLinksWrap}>
              <label className={styles.privacyPolicy} onClick={event => routeChange(event, 'privacy-policy')}>Privacy Policy</label>
              <label className={styles.termsPolicy} onClick={event => routeChange(event, 'terms-policy')}>Terms of Use</label>
            </div>
          </div>
  	    }
  	    pullRight={true}
  	    shadow={false}
  	    open={sidebarOpen}
  	    onSetOpen={onSetSidebarOpen}
        sidebarClassName={sidebarOpen ? styles.sidebarDesktopOpen : styles.sidebarDesktopClose}
  	    styles={{ 
          sidebar: { 
            position: "fixed",
            background: "#15171c",
            zIndex: '15',
            width: width >= 480 ? '280px' : '68%',
            background: 'rgba(0, 0, 0, 0.95)'
          },
          overlay: {
            zIndex: '3'
          },
          root: {
            position: "undefined"
          },
          content: {
            position: "undefined",
            top: "undefined",
            left: "undefined",
            right: "undefined",
            bottom: "undefined",
            background: "#15171c"
          }
        }}
  	  >
  	  </Sidebar>
    </div>
  );
}
 
export default SideBar;

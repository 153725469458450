const Delete = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
	  <g clipPath="url(#clip0_130_309)">
	    <path d="M15.75 3H13.425C13.2509 2.15356 12.7904 1.39301 12.1209 0.846539C11.4515 0.300068 10.6142 0.00109089 9.75 0L8.25 0C7.38585 0.00109089 6.54849 0.300068 5.87906 0.846539C5.20964 1.39301 4.74907 2.15356 4.575 3H2.25C2.05109 3 1.86032 3.07902 1.71967 3.21967C1.57902 3.36032 1.5 3.55109 1.5 3.75C1.5 3.94891 1.57902 4.13968 1.71967 4.28033C1.86032 4.42098 2.05109 4.5 2.25 4.5H3V14.25C3.00119 15.2442 3.39666 16.1973 4.09966 16.9003C4.80267 17.6033 5.7558 17.9988 6.75 18H11.25C12.2442 17.9988 13.1973 17.6033 13.9003 16.9003C14.6033 16.1973 14.9988 15.2442 15 14.25V4.5H15.75C15.9489 4.5 16.1397 4.42098 16.2803 4.28033C16.421 4.13968 16.5 3.94891 16.5 3.75C16.5 3.55109 16.421 3.36032 16.2803 3.21967C16.1397 3.07902 15.9489 3 15.75 3ZM8.25 1.5H9.75C10.2152 1.50057 10.6688 1.64503 11.0487 1.91358C11.4286 2.18213 11.7161 2.56162 11.8717 3H6.12825C6.28394 2.56162 6.57143 2.18213 6.95129 1.91358C7.33115 1.64503 7.78479 1.50057 8.25 1.5ZM13.5 14.25C13.5 14.8467 13.2629 15.419 12.841 15.841C12.419 16.2629 11.8467 16.5 11.25 16.5H6.75C6.15326 16.5 5.58097 16.2629 5.15901 15.841C4.73705 15.419 4.5 14.8467 4.5 14.25V4.5H13.5V14.25Z" fill="#1C1C1E"/>
	    <path d="M7.5 13.4995C7.69891 13.4995 7.88968 13.4205 8.03033 13.2798C8.17098 13.1392 8.25 12.9484 8.25 12.7495V8.24951C8.25 8.0506 8.17098 7.85983 8.03033 7.71918C7.88968 7.57853 7.69891 7.49951 7.5 7.49951C7.30109 7.49951 7.11032 7.57853 6.96967 7.71918C6.82902 7.85983 6.75 8.0506 6.75 8.24951V12.7495C6.75 12.9484 6.82902 13.1392 6.96967 13.2798C7.11032 13.4205 7.30109 13.4995 7.5 13.4995Z" fill="#1C1C1E"/>
	    <path d="M10.5 13.4995C10.6989 13.4995 10.8897 13.4205 11.0303 13.2798C11.171 13.1392 11.25 12.9484 11.25 12.7495V8.24951C11.25 8.0506 11.171 7.85983 11.0303 7.71918C10.8897 7.57853 10.6989 7.49951 10.5 7.49951C10.3011 7.49951 10.1103 7.57853 9.96967 7.71918C9.82902 7.85983 9.75 8.0506 9.75 8.24951V12.7495C9.75 12.9484 9.82902 13.1392 9.96967 13.2798C10.1103 13.4205 10.3011 13.4995 10.5 13.4995Z" fill="#1C1C1E"/>
	  </g>
	  <defs>
	    <clipPath id="clip0_130_309">
	      <rect width="18" height="18" fill="white"/>
	    </clipPath>
	  </defs>
	</svg>
  );
};

export default Delete;

const Comments = () => {
  return (
    	<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
		  <g clipPath="url(#clip0_2121_2122)">
		    <path d="M9.425 19.4997H3.25C1.63258 19.4997 0 18.2712 0 15.5282V10.0682C0.0227794 7.57888 0.954879 5.18378 2.62077 3.33392C4.28667 1.48405 6.57141 0.307073 9.04475 0.0246132C10.4405 -0.0767063 11.8417 0.12361 13.1531 0.61195C14.4645 1.10029 15.6554 1.86521 16.645 2.85473C17.6345 3.84426 18.3994 5.03519 18.8877 6.34662C19.3761 7.65804 19.5764 9.05922 19.4751 10.4549C19.1923 12.9293 18.0143 15.2148 16.1631 16.8808C14.3119 18.5468 11.9154 19.4783 9.425 19.4997ZM21.6667 9.83636H21.6537C21.6537 10.0931 21.6537 10.3499 21.6407 10.6077C21.2225 16.4664 15.8676 21.4259 9.841 21.6458V21.662C10.6 22.9784 11.6917 24.0721 13.0067 24.8334C14.3218 25.5947 15.8138 25.9969 17.3333 25.9997H22.75C23.612 25.9997 24.4386 25.6573 25.0481 25.0478C25.6576 24.4383 26 23.6117 26 22.7497V17.333C25.9984 15.8132 25.5972 14.3205 24.8367 13.0047C24.0761 11.6889 22.9829 10.5963 21.6667 9.83636Z" fill="#1C1C1E"/>
		  </g>
		  <defs>
		    <clipPath id="clip0_2121_2122">
		      <rect width="26" height="26" fill="white"/>
		    </clipPath>
		  </defs>
		</svg>
  );
};

export default Comments;

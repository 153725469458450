import styles from "./ExperienceFaqs.module.css";
import { Back, List, ContactCreator, Helper, } from "../components";
import { ThumbsUp, ThumbsDown, } from "../icons";
import { Oval } from  'react-loader-spinner';
import parse from 'html-react-parser';
import { useParams } from "react-router-dom";
import React, { useEffect, useState, } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';


const ExperienceFaqs = () => {
	const { slug } = useParams();
	const [faqs, setFaqs] = useState({});
	const [brand, setBrand] = useState({});
	const [invalidExperienceId, setInvalidExperienceId] = useState(false);
	const [openContactCreator, setOpenContactCreator] = useState(false);
	const [experience, setExperience] = useState({});
	const [contactCreatorStorage, setContactCreatorStorage] = useState('contactCreatorLeads');
	let [thumbsUp, setThumbsUp] = useState(JSON.parse(Helper.storage.getItem('faqsThumbsUp')) || {});
	let [thumbsDown, setThumbsDown] = useState(JSON.parse(Helper.storage.getItem('faqsThumbsDown')) || {});


	const setData = (exp, storage, open) => {
		setContactCreatorStorage(storage);
		setOpenContactCreator(open);
	};

	const selectThumbsUp = (e, i) => {
		const experienceId = experience.id;
		if (thumbsUp[experienceId]) {
			if (!thumbsUp[experienceId].includes(i)) {
				thumbsUp[experienceId].push(i);
			}
		} else {
			thumbsUp[experienceId] = [i];
		}
		if (thumbsDown[experienceId] && thumbsDown[experienceId].includes(i)) {
			thumbsDown[experienceId] = thumbsDown[experienceId].filter(idx => idx !== i);
			Helper.storage.setItem('faqsThumbsDown', JSON.stringify(thumbsDown));
		}
		Helper.storage.setItem('faqsThumbsUp', JSON.stringify(thumbsUp));
		setThumbsDown({...thumbsDown});
		setThumbsUp({...thumbsUp});
	};

	const selectThumbsDown = (e, i) => {
		const experienceId = experience.id;
		if (thumbsDown[experienceId]) {
			if (!thumbsDown[experienceId].includes(i)) {
				thumbsDown[experienceId].push(i);
			}
		} else {
			thumbsDown[experienceId] = [i];
		}
		if (thumbsUp[experienceId] && thumbsUp[experienceId].includes(i)) {
			thumbsUp[experienceId] = thumbsUp[experienceId].filter(idx => idx !== i);
			Helper.storage.setItem('faqsThumbsUp', JSON.stringify(thumbsUp));
		}
		Helper.storage.setItem('faqsThumbsDown', JSON.stringify(thumbsDown));
		setThumbsDown({...thumbsDown});
		setThumbsUp({...thumbsUp});
	};

	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/experiences/faqs?slug=${slug}`)
	      	.then((response) => response.json())
	      	.then((res) => {
	        	if (res.data) {
	        		setFaqs(res.data.faqs);
	        		setExperience({id: res.data._id, title: res.data.title})
	        		setBrand(res.data.brand);
	        	}
	        	else setInvalidExperienceId(true);
	     	})
	     	.catch((err) => {
	        	console.log(err.message);
	     	});
	}, {});

	const getAnalyticsCategory = () => {
		try {
	      return `ExperienceDetails_Faqs_${experience.title.split(' ').join('_')}`;
	    } catch(err) {
	      return 'ExperienceDetails_Faqs';
	    }
	};

  	return(
   		<div className={styles.faqsContainer}>
   			<Back analyticsCategory={getAnalyticsCategory()} />

   			{invalidExperienceId && 
		        <h1 style={{ textAlign: 'center', margin: '80px 50px'}}>Apologies! XPHive does not have this experience listed.</h1>
		    }

		    {!invalidExperienceId && Object.keys(faqs).length == 0 ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		    /> : '' }

		    { Object.keys(faqs).length != 0 ?
		        <div>
		        	<h3 className={styles.heading}>Frequently Asked Questions :</h3>

		        	{faqs.data.map((faq, i) => {
		        		return(
		        			<div className={styles.faq}>
		        				<h4>{faq.title}</h4>
		        				<p>{parse(faq.content)}</p>
		        				<i className={styles.thumbsDown} onClick={e => selectThumbsDown(e, i)}><ThumbsDown fill={(thumbsDown[experience.id] || []).includes(i) ? 'red' : '#7B7B84'} /></i>
		        				<i className={styles.thumbsUp} onClick={e => selectThumbsUp(e, i)}><ThumbsUp fill={(thumbsUp[experience.id] || []).includes(i) ? 'green' : '#7B7B84'} /></i>
		        			</div>
		        		)
		        	})}

					<ContactCreator analyticsCategory={getAnalyticsCategory()} hideCommentsBtn={true} open={openContactCreator} brand={brand} experience={experience} storage={contactCreatorStorage} setData={setData} />
			        <button className={styles.queryBtn} onClick={(e) => { e.stopPropagation(); setData(experience, 'contactCreatorLeads', true); Helper.trackAnalytics(getAnalyticsCategory(), 'Click_Query', 'Query');}}>Submit Your Questions</button>
		        </div>
		    : ''}
   		</div>
  	);
};

export default ExperienceFaqs;

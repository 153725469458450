import styles from "./PortfolioSetting.module.css";
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { LoginHelper, Back, Helper, } from '../components';
import { Check, LeftNavigation, RightNavigation, } from "../icons";
import { Oval } from  'react-loader-spinner';


const PortfolioSetting = () => {
	const [traveller, setTraveller] = useState({});
	const [bucketListOption, setBucketListOption] = useState('public');
	const [invalidHandle, setInvalidHandle] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const handle = location.pathname.split('/')[2];
	const isUserLoggedIn = LoginHelper.isUserLoggedIn();
	const user = LoginHelper.getUser();
	const showEdit = isUserLoggedIn && user && user.handle === handle;
	const [submittingForm, setSubmittingForm] = useState(false);


	const routeChange = (e, url) => {
	    navigate(url);
	}

	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/travellers?handle=${handle}`)
	      .then((response) => response.json())
	      .then((res) => {
	        if (res.data) {
	        	const travellerRes = res.data;
	        	setTraveller(travellerRes);
	        	setBucketListOption(travellerRes.bucketListOption || 'public');
	        }
	        else setInvalidHandle(true);
	     })
	     .catch((err) => {
	        console.log(err.message);
	     });
	   }, {});


	const toggleBucketListOption = async (e) => {
		const option = bucketListOption === 'public' ? 'private': 'public';
		setBucketListOption(option);

		if (!submittingForm) {
  			setSubmittingForm(true);
	  		await fetch(`${global.config.SERVER_URL}/api/v1/travellers/set-bucket-list-option`, {
		        method: 'POST',
		        body: JSON.stringify({
		          option: option,
		        }),
		        headers: {
		           'Content-type': 'application/json; charset=UTF-8',
		           ...Helper.getCommonHeaders(),
		        },
		        })
		        .then((response) => response.json())
		        .then((data) => {
		          setSubmittingForm(false);
		        })
		        .catch((err) => {
		          setSubmittingForm(false)
		          console.log(err.message);
		        });
	  	}
	};

	const changeEmail = () => {
		LoginHelper.setUser(null);
		navigate('/login-signup');
	};

  	return (
      	<div className={styles.portfolioSetting}>
      		<Back analyticsCategory={'PortfolioSetting'} />
      		{(!showEdit || invalidHandle) && <h1 style={{ textAlign: 'center', padding: '80px 50px', margin: '0', color: '#1C1C1E'}}>Apologies! Your are not allowed to edit this portfolio.</h1>}
   			{!invalidHandle && Object.keys(traveller).length == 0 ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		      /> : '' }

		     { showEdit && Object.keys(traveller).length != 0 ?
	     		<div className={styles.portfolioSettingContainer}>
		   			<h1 className={styles.pageHeading}>Settings</h1>
		   			<label className={styles.editProfile}>Edit Profile</label>
		   			<div className={styles.editProfileLink} onClick={event => routeChange(event, location.pathname.replace('setting', 'edit'))}>
		   				<img src={traveller.image} />
		   				<div className={styles.nameAndBio}>
		   					<label>{traveller.name}</label>
		   					<p>{traveller.bio}</p>
		   					<i><RightNavigation /></i>
		   				</div>
		   			</div>
		   			<label className={styles.bucketListText}>Bucket-list Options</label>
		   			<div className={styles.bucketListInput}>
		   				<i className={styles.leftNavigation} onClick={toggleBucketListOption}><LeftNavigation /></i>
		   				<i className={styles.rightNavigation} onClick={toggleBucketListOption}><RightNavigation /></i>
		   				<label>{Helper.titleCase(bucketListOption)}</label>
		   			</div>

		   			<button className={styles.accountCTA} onClick={changeEmail}>Change Email ID<RightNavigation /></button>
		   			<button className={styles.accountCTA} onClick={event => routeChange(event, '/reset-password?changePassword=true')}>Change Password<RightNavigation /></button>
		   			<a className={styles.reportAnIssue} href="mailto: hello@xphive.com">Report An Issue</a>
		   		</div>
		     : ''}
      	</div>
  	);
};

export default PortfolioSetting;

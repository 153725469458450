import parse from 'html-react-parser';
import styles from "./BlogDetails.module.css";
import { Helper, Back, Share, Blogs, Communities, Groups, Bytes, } from "../components";
import { RightArrow } from "../icons";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Oval } from  'react-loader-spinner';


const BlogDetails = () => {
	const navigate = useNavigate();
  	const [blog, setBlog] = useState({});
  	const [showLoader, setShowLoader] = useState(false);
  	const [blogSlug, setBlogSlug] = useState(null);
  	const { slug } = useParams();
  	const [invalidBlogSlug, setInvalidBlogSlug] = useState(false);

	const routeChange = (e, url) => {
	    navigate(url);
	};

	const getAnalyticsCategory = () => {
	    return 'BlogDetails';
	};

	const fetchData = () => {
	    fetch(`${global.config.SERVER_URL}/api/v1/blogs?slug=${slug}`)
	      .then((response) => response.json())
	      .then((res) => {
	        if (res.data) setBlog(res.data);
	        else setInvalidBlogSlug(true);

	        setShowLoader(false);
	     })
	     .catch((err) => {
	     	setInvalidBlogSlug(true);
	        console.log(err.message);
	     });
	};

	if (blogSlug && slug !== blogSlug) {
	    setShowLoader(true);
	    setBlogSlug(slug);
	    fetchData();
	};

	useEffect(() => {
	    fetchData();
	    if (slug !== blogSlug) setBlogSlug(slug);
	}, {});

	const convertMarkdownToJSON = (markdown='') => {
		const section = {
			h1: '',
			h2: '',
			h3: '',
			p1: '',
			p2: '',
			list: [],
		};
	  	let lines = markdown.split('\n');
	  	let inList = false;

	  for (let line of lines) {
	    line = line.trim();
	    if (line === '') continue;

	    if (line.startsWith('# ')) {
	    	section.h1 = line.slice(2);
	    } else if (line.startsWith('## ')) {
	    	section.h2 = line.slice(3);
	    } else if (line.startsWith('### ')) {
	    	section.h3 = line.slice(4);
	    } else if (line.startsWith('- ')) {
	    	section.list.push(line.slice(2));
	    } else if (section.p1) {
	    	section.p2 = line;
	    } else {
	    	section.p1 = line;
	    }
	  }
	  return section;
	}

	const getSection = (data) => {
		return {
			img: data.img,
			...convertMarkdownToJSON(data.markdown),
		};
	};

  	return (
   		<div className={styles.blogDetails}>
   			{invalidBlogSlug && <h1 style={{ textAlign: 'center', padding: '80px 50px', margin: '0', color: '#000'}}>Apologies! XPHive does not have this blog listed.</h1>}
   			{(!invalidBlogSlug && Object.keys(blog).length == 0) || showLoader ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		      /> : '' }

		     { !showLoader && Object.keys(blog).length != 0 && blog.html &&
		     	<div className={styles.mediumBlogTopContainer}>
		   			<Back analyticsCategory={'BlogDetails'} whiteIcon={false} />
		     		{parse(blog.html)}
		     	</div>
		     }

		    { !showLoader && Object.keys(blog).length != 0 && !blog.html ?
	     		<div className={styles.blogContainer}>
		   			<i className={styles.backIcon}><Back style={{position: 'absolute', left: '23px', top: '25px'}} analyticsCategory={getAnalyticsCategory()} /></i>
          			<i className={styles.shareIcon}><Share hideCircle={true} style={{position: 'absolute', right: '25px', top: '27px'}} blackIcon={true} analyticsCategory={getAnalyticsCategory()} /></i>
          			<h1 className={styles.pageHeading}>XpHive Mini Guides</h1>
          			<h2 className={styles.blogTitle}>{blog.title}</h2>
          			{blog.vibes && blog.vibes.length > 0 && <p className={styles.vibes}><span>Vibes:</span> {blog.vibes.map(vibe => vibe.title).join(', ')}</p>}

          			{blog.readTime && 
          				<p className={styles.readTime}>{new Date(blog.createdAt).toLocaleString("default", { month: 'long' })}, {new Date(blog.createdAt).getFullYear()} 
          				<i>&bull;</i>
						{blog.readTime} read
					</p>}
          			{blog.data && blog.data.map((data, i) => {
          				const section = getSection(data);
          				return(
          					<div key={i} className={styles.section}>
          						{section.h1 && <h1>{parse(section.h1)}</h1>}
          						{section.h2 && <h2>{parse(section.h2)}</h2>}
          						{section.img && <img src={section.img} />}
          						{section.h3 && <h3>{parse(section.h3)}</h3>}
          						{section.p1 && <p>{parse(section.p1)}</p>}
          						{section.list && <ul className={styles.list}>{section.list.map((list, j) => {
          							return(<li key={j}>{parse(list)}</li>);
          						})}</ul>}
          						{section.p2 && <p>{parse(section.p2)}</p>}

          						{i === 0 && blog.bytes && blog.bytes.length > 0 && <Bytes bytes={blog.bytes} />}
          					</div>
          				);
          			})}

          			<button onClick={e => routeChange(e, '/travel-with-xphive')} className={styles.travelWithXpHive}>Come travel with XPhive</button>
          			<Groups analyticsCategory={getAnalyticsCategory()} headingStyle={{marginLeft: '0'}} subHeadingStyle={{marginLeft: '0'}} />
          			<Communities analyticsCategory={getAnalyticsCategory()} headingStyle={{marginLeft: '0'}} subHeadingStyle={{marginLeft: '0'}} />
          			<div className={styles.otherMiniGuides}><Blogs heading={'Mini guides for you'} excludeId={blog['_id']} /></div>
		   		</div>
		     : ''}
   		</div>
  	);
};

export default BlogDetails;

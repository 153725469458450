import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";


const faqStyles = {
    titleTextColor: "#000",
    rowTitleColor: "#000",
};

const config = {
};

const FaqComponent = (props) => {
    const data = {
        title: "FAQs",
        rows: props.rows
    };
    return (
        <div className="tripFaqContainer">
            <Faq
                data={data}
                styles={faqStyles}
                config={config}
            />
        </div>
    );
}
export default FaqComponent;

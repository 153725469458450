import styles from "./Groups.module.css";
import { useNavigate, } from "react-router-dom";
import React, { useState, useEffect, } from 'react';
import { Helper, } from "../components";


const Groups = (props) => {
	const navigate = useNavigate();
	const [groups, setGroups] = useState([])
	
	const routeChange = (e, url) => {
	    navigate(url);
	};

	const onGroupClick = (e, slug, url) => {
		Helper.trackAnalytics(props.analyticsCategory, `Click_${Helper.titleCase(slug.split('/')[slug.split('/').length - 1].replaceAll('-', '_'))}`, `Navigate_Group`);
		routeChange(e, `/groups/${slug}`);
	};

	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/groups?vibeIds=${props.vibeIds || []}&active=true`, {headers: {...Helper.getCommonHeaders(),}})
	      .then((response) => response.json())
	      .then((res) => {
	      	setGroups(res.data);
	      })
	      .catch((err) => {
	        console.log(err.message);
	      });
	}, {});

  	return (
      	<div className={styles.groups}>
      		{groups.length > 0 && <div>
		        <h3 style={{...props.headingStyle}} className={styles.groupsHeading}>Travel Groups for you</h3>
		        <label style={{...props.subHeadingStyle}} className={styles.groupsSubHeading}>Join small curated groups matching your travel style</label>
		        <ul className={styles.groupsCarousel}>
		          {groups.map((group, i) => {
		            return(<li onClick={e => onGroupClick(e, group.slug, group.link)} className={styles.group}>
		              <div style={{position: 'relative'}}>
		                <img src={group.image} alt={i} />
		              </div>

		              <h3>{group.title}</h3>
		              <p>{group.description}</p>
		            </li>)
		          })}
		        </ul>
		    </div>}
	    </div>
  	);
}

export default Groups;

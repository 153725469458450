import styles from "./List.module.css";

const List = (props) => {
	const listItemClasses = `${styles.listItem} ${props.class ? props.class : ''}`
  
  	return (
      	<ul className={styles.list}>
	        {props.list.map((item, i) => {
	            return (
	              <li className={listItemClasses} key={i}>{item}</li>
	            );
	          })}
	    </ul>
  	);
}

export default List;

import styles from "./ExperienceStay.module.css";
import parse from 'html-react-parser';
import { Back, List, ContactCreator, Helper, } from "../components";
import { Oval } from  'react-loader-spinner';
import { useParams } from "react-router-dom";
import React, { useEffect, useState, } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';


const ExperienceStay = () => {
	const { slug } = useParams();
	const [stay, setStay] = useState({});
	const [brand, setBrand] = useState({});
	const { height, width } = Helper.getWindowDimensions();
	const [invalidExperienceId, setInvalidExperienceId] = useState(false);
	const [openContactCreator, setOpenContactCreator] = useState(false);
	const [experience, setExperience] = useState({});
	const [contactCreatorStorage, setContactCreatorStorage] = useState('contactCreatorLeads');


	const setData = (exp, storage, open) => {
		setContactCreatorStorage(storage);
		setOpenContactCreator(open);
	};

	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/experiences/stay?slug=${slug}`)
	      	.then((response) => response.json())
	      	.then((res) => {
	        	if (res.data) {
	        		setStay(res.data.stay);
	        		setExperience({id: res.data._id, title: res.data.title})
	        		setBrand(res.data.brand);
	        	}
	        	else setInvalidExperienceId(true);
	     	})
	     	.catch((err) => {
	        	console.log(err.message);
	     	});
	}, {});

	const getAnalyticsCategory = () => {
		try {
	      return `ExperienceDetails_Stay_${experience.title.split(' ').join('_')}`;
	    } catch(err) {
	      return 'ExperienceDetails_Stay';
	    }
	};

  	return(
   		<div className={styles.stayContainer}>
   			<Back analyticsCategory={getAnalyticsCategory()} />

   			{invalidExperienceId && 
		        <h1 style={{ textAlign: 'center', margin: '80px 50px'}}>Apologies! XPHive does not have this experience listed.</h1>
		    }

		    {!invalidExperienceId && Object.keys(stay).length == 0 ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		    /> : '' }

		    { Object.keys(stay).length != 0 ?
		        <div>
		        	<h3 className={styles.heading}>{stay.heading}</h3>
		        	<p>{stay.description}</p>

		        	{stay.data.map((st) => {
		        		return(
		        			<div className={styles.dayWiseStay}>
		        				<p>{st.text}</p>
		        				{st.image.includes(".mp4") ? <ReactPlayer className={styles.videoContainer} url={st.image} playsinline muted={true} loop={true} playing={true} width={width - 40} config={{file: {attributes: {crossOrigin: 'true',},},}} /> : <img className={styles.image} src={st.image} />}
		        			</div>
		        		)
		        	})}
					<p>{parse(stay.supplementary)}</p>

					<ContactCreator analyticsCategory={getAnalyticsCategory()} hideCommentsBtn={true} open={openContactCreator} brand={brand} experience={experience} storage={contactCreatorStorage} setData={setData} />
			        <button className={styles.queryBtn} onClick={(e) => { e.stopPropagation(); setData(experience, 'contactCreatorLeads', true); Helper.trackAnalytics(getAnalyticsCategory(), 'Click_Query', 'Query');}}>Ask your Queries</button>
		        </div>
		    : ''}
   		</div>
  	);
};

export default ExperienceStay;

import styles from "./Back.module.css";
import React, { Component } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Circle, BackArrow, BackArrowWhite } from '../icons';
import { Helper } from '../components';


const Back = (props) => {
	const navigate = useNavigate();
	const location = useLocation();

	const onClick = () => {
		if (props.backURL) {
			navigate(props.backURL);
		} else {
			if (location.key === 'default') {
				navigate('/');
			} else {
				Helper.trackAnalytics(props.analyticsCategory || 'Back', 'Click_Back', 'Back');
				navigate(-1);
			}	
		}
	};
  
  	return (
      	<div className={styles.backIconContainer} onClick={onClick} style={props.style}>
			<i className={styles.backIcon}>{props.whiteIcon ? <BackArrowWhite /> : <BackArrow />}</i>
      	</div>
  	);  
}  
export default Back;

import styles from "./ContactCreator.module.css";
import React, { useState, useEffect } from 'react';
import Helper from './Helper';
import Modal from './Modal';
import { Comments } from '../icons';


const ContactCreator = (props) => {
  const brand = props.brand;
  const brandId = brand._id;
  const storage = props.storage;
  const experience = props.experience;
  const setData = props.setData;
  const open = props.open;
  const [userEmail, setUserEmail] = useState('');
  const [message, setMessage] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState(false);
  const [emptyName, setEmptyName] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const { height, width } = Helper.getWindowDimensions();
  const leadId = (experience.id || experience._id) ? (brandId + (experience.id || experience._id)) : brandId;
  let localData = JSON.parse(Helper.storage.getItem(storage)) || [];

  const onChangeUserName = (e) => {
    setEmptyName(false);
    const result = event.target.value.replace(/[^a-z ]/gi, '');
    setUserName(result);
  }

  const setOpen = () => {
    setData({}, storage, false);
  }

  const onChangeEmail = (e) => {
    setEmptyEmail(false);
    setUserEmail(e.target.value);
  }

  const onChangeMessage = (e) => {
    setEmptyMessage(false);
    setMessage(e.target.value);
  }

  const setLeads = () => {
    let data = JSON.parse(Helper.storage.getItem(storage)) || [];
    if (!data.includes(leadId)) {
      data.push(leadId);
    }
    localData = data;
    Helper.storage.setItem(storage, JSON.stringify(data));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!userName) setEmptyName(true);
    if (!userEmail) setEmptyEmail(true);
    if (!message) setEmptyMessage(true);
    if (userPhone.length != 10) setInvalidPhone(true);

    if (!submittingForm && userName && userPhone && userEmail && message && !emptyName 
      && !emptyMessage && !emptyEmail && !invalidPhone && userPhone.length == 10) {
      Helper.trackAnalytics(props.analyticsCategory || 'ContactCreator', 'Click_Submit_contact_creator', userPhone);
      setSubmittingForm(true);
      await fetch(`${global.config.SERVER_URL}/api/v1/leads`, {
        method: 'POST',
        body: JSON.stringify({
           name: userName,
           phone: userPhone,
           email: userEmail,
           message: message,
           brandId: brandId,
           experienceId: (experience.id || experience._id) || null,
           type: 'contactCreator',
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => response.json())
        .then((data) => {
          setSubmittingForm(false)
           if (data.status == 'success') setLeads();
        })
        .catch((err) => {
          setSubmittingForm(false)
          console.log(err.message);
        });
    }
  };

  const onChangeUserPhone = (e) => {
    setInvalidPhone(false)
    if (e.target.value.length < 11)
      setUserPhone(e.target.value);
  };
  
	return (
    	<div className={styles.contactCreatorContainer}>
        {!props.hideCommentsBtn ? <div className={styles.commentsButton} onClick={(e) => { e.stopPropagation(); setOpen(); setData({}, 'contactCreatorLeads', true);  Helper.trackAnalytics(props.analyticsCategory || 'ContactCreator', 'Click_Open_contact_creator', 'comment');}}>
          <Comments />
        </div> : ''}

        <div id="contact-creator-modal-root"></div>
        {open && (
          <Modal open={open} toggle={setOpen} root='contact-creator-modal-root'>
            {!localData.includes(leadId) &&
              <div>
                <h1 className={styles.contactCreatorModalHeading}>Contact the Creator</h1>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label className={styles.label}>Your Name</label>
                    <input className={emptyName ? styles.invalid : styles.valid} type="text" placeholder="Name" value={userName} onChange={e => onChangeUserName(e)} />
                  </div>

                  <div>
                    <label className={styles.label}>Email</label>
                    <input className={emptyEmail ? styles.invalid : styles.valid} type="email" placeholder="loremipsu@company.com" value={userEmail} onChange={e => onChangeEmail(e)} />
                  </div>

                  <div>
                    <label className={styles.label}>Phone Number</label>
                    <input className={invalidPhone ? styles.invalid : styles.valid} type="number" placeholder="+91 -" value={userPhone} onChange={e => onChangeUserPhone(e)} />
                  </div>

                  <div>
                    <label className={styles.label}>Reach Out {brand.name}</label>
                    <textarea className={emptyMessage ? styles.invalid : styles.valid} rows="5" placeholder="Type Your Message Here..." value={message} onChange={e => onChangeMessage(e)} />
                  </div>

                  <button type="submit">Get In Touch</button>
                  <button className={styles.closeBtn} onClick={(e) => { e.stopPropagation(); setOpen(); setData({}, storage, false);}}>Close</button>
                </form>
              </div>
            }

            {localData.includes(leadId) && 
              <div>
                <h3 className={styles.contactCreatorsModalHeadingSuccess}>Your Message is on the way!<br/>We’ll Get Back Soon...</h3>
                <img style={{width: width - 110, height: (width - 110) * 1.17434210526 }} className={styles.formSuccessImg} src="/contact-creator-success.png" />
              </div>
            }
          </Modal>
        )}
    </div>
  )
}

export default ContactCreator;

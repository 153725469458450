import styles from "./ResetPassword.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Back, Helper, } from "../components";
import { BackArrow, Scenery, } from "../icons";
import React, { useState } from 'react';


const ResetPassword = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const code = window.location.search.replace('?changePassword=true', '').replace('?code=', '');
	const changePassword = searchParams.get('changePassword');
	const [password1, setPassword1] = useState('');
	const [password2, setPassword2] = useState('');
	const [invalidPassword1, setInvalidPassword1] = useState(false);
	const [invalidPassword2, setInvalidPassword2] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [submittingForm, setSubmittingForm] = useState(false);


  	const onChangeUserPassword1 = (e) => {
    	setInvalidPassword1(false);
    	setPassword1(e.target.value);
  	};

  	const onChangeUserPassword2 = (e) => {
    	setInvalidPassword2(false);
    	setPassword2(e.target.value);
  	};

  	const goBackToLogin = () => {
  		navigate('/login-signup');
  	};

  	const handleSubmit = async (event) => {
	    event.preventDefault();
	    if (!password1) setInvalidPassword1(true);
	    if (!password2) setInvalidPassword2(true);
	    if (password1 != password2) setInvalidPassword2(true);

	    if (!submittingForm && !invalidPassword1 && !invalidPassword2 && password1 && password2 && password1 === password2) {
	      	Helper.trackAnalytics('ResetPassword', 'Click_Submit', 'submit');
	      	setSubmittingForm(true);
	      	await fetch(`${global.config.SERVER_URL}/api/v1/users/reset-password`, {
	        	method: 'POST',
	        	body: JSON.stringify({
	           		password: password1,
	           		code: code,
	        	}),
	        	headers: {
	           		'Content-type': 'application/json; charset=UTF-8',
	           		...Helper.getCommonHeaders(),
	        	},
	        	})
	        	.then((response) => response.json())
	        	.then((data) => {
	          		setSubmittingForm(false);
	           		if (data.success) {
	           			goBackToLogin();
	           		} else {
	           			setErrorMessage(data.error);
	           		}
	        	})
	        	.catch((err) => {
	          		setSubmittingForm(false);
	          		console.log(err.message);
	        	});
	    }
	};

  	return (
   		<div className={styles.resetPassword}>
   			<Back analyticsCategory={'ResetPassword'} whiteIcon={true} />
   			<i className={styles.resetPasswordImg}><Scenery width={225} height={270} /></i>

   			<div className={styles.resetPasswordContainer}>
	   			<div className={changePassword ? styles.resetPasswordInnerContainer2 : styles.resetPasswordInnerContainer} style={{ backgroundImage: `url("/ls-ellipse.png")` }}>
	   				<img src="/fi-rr-smile.svg" />
		        	<p className={styles.headingTxt}>Enter Your New Password...</p>	

	   				<div>
		   				{!errorMessage && <form onSubmit={handleSubmit}>
			                <input className={invalidPassword1 ? styles.invalid : styles.valid} type="password" placeholder="New Password" value={password1} onChange={e => onChangeUserPassword1(e)} />
			                <input className={invalidPassword2 ? styles.invalid : styles.valid} type="password" placeholder="Verify Your Password" value={password2} onChange={e => onChangeUserPassword2(e)} />
			                <button type="submit">Confirm</button>
			            </form>}

			            {errorMessage && <p className={styles.errorMessage}><img src="/fi-rr-info.svg" /><label>{errorMessage}</label></p>}

			            {!changePassword && <label className={styles.goBackToLoginLabel} onClick={event => goBackToLogin(event)}><BackArrow /> Go Back to Login Page</label>}
			        </div>
		            
	   			</div>
   			</div>
   		</div>
  	);
};

export default ResetPassword;

const HeartSelected = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || "24"} height={props.height || "24"} viewBox="0 0 24 24" fill="none">
		<g clipPath="url(#clip0_2594_1859)">
			<path d="M17.5009 1.91699C16.3749 1.93451 15.2734 2.24885 14.3077 2.82826C13.342 3.40768 12.5463 4.23166 12.0009 5.21699C11.4556 4.23166 10.6599 3.40768 9.69417 2.82826C8.72848 2.24885 7.62698 1.93451 6.50094 1.91699C4.70588 1.99498 3.01464 2.78025 1.79672 4.10122C0.578794 5.4222 -0.0668157 7.17152 0.000934853 8.96699C0.000934853 13.514 4.78693 18.48 8.80093 21.847C9.69715 22.6001 10.8303 23.013 12.0009 23.013C13.1716 23.013 14.3047 22.6001 15.2009 21.847C19.2149 18.48 24.0009 13.514 24.0009 8.96699C24.0687 7.17152 23.4231 5.4222 22.2052 4.10122C20.9872 2.78025 19.296 1.99498 17.5009 1.91699Z" fill="#FF2D2D"/>
		</g>
		<defs>
			<clipPath id="clip0_2594_1859">
				<rect width="24" height="24" fill="white"/>
			</clipPath>
		</defs>
	</svg>
  );
};

export default HeartSelected;

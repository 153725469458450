import styles from "./WishlistDetails.module.css";
import { Back, Share, Helper, LoginHelper, ConfirmationPopup, } from "../components";
import { BackArrow, BlackHeart, Delete, } from "../icons";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Oval } from  'react-loader-spinner';


const WishlistDetails = () => {
	const navigate = useNavigate();
	const { id } = useParams();
  	const [wishlist, setWishlist] = useState({});
  	const [invalidWishlist, setInvalidWishlist] = useState(false);
  	const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  	const isUserLoggedIn = LoginHelper.isUserLoggedIn();
	const user = LoginHelper.getUser();

	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/wishlists/${id}`)
	      .then((response) => response.json())
	      .then((res) => {
	        if (res.data) setWishlist(res.data);
	        else setInvalidWishlist(true);
	     })
	     .catch((err) => {
	        console.log(err.message);
	     });
	}, {});

	const backFromWishlist = () => {
		navigate(`/portfolio/${wishlist.handle}`);
	};

	const deleteFromWishlist = async () => {
		await fetch(`${global.config.SERVER_URL}/api/v1/wishlists/${id}`, {
	        method: 'delete',
	        headers: {
	           ...Helper.getCommonHeaders(),
	        },
	        })
	        .then((response) => response.json())
	        .then((data) => {
	           if (data.status == 'success') backFromWishlist();
	        })
	        .catch((err) => {
	          console.log(err.message);
	        });
	};

	const onOpenConfirmationPopup = () => {
		setOpenConfirmationPopup(true);
	};

  	return (
   		<div className={styles.wishlistDetails}>
   			{invalidWishlist && <h1 style={{ textAlign: 'center', padding: '80px 50px', margin: '0', color: '#fff'}}>Apologies! XPHive does not have this wishlist listed.</h1>}
		      {!invalidWishlist && Object.keys(wishlist).length == 0 ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		      /> : '' }

		     { Object.keys(wishlist).length != 0 ?
	     		<div className={styles.topContainer}>
	     			{isUserLoggedIn && user && user.handle === wishlist.handle && 
	     				<i className={styles.deleteFromWishlist} onClick={onOpenConfirmationPopup}>
	     					<BlackHeart />
	     				</i>
	     			}
		   			<div className={styles.backFromWishlistContainer} onClick={backFromWishlist}>
						<i className={styles.backIcon}><BackArrow /></i>
			      	</div>
			      	<h1>{Helper.titleCase(wishlist.name)}</h1>
			      	<img src={wishlist.image} />
			      	<p>{wishlist.description}</p>
			      	<p>{wishlist.tags.join(' ')}</p>
			      	<label>A wish from @{wishlist.handle} ‘s wish-list...</label>
		   			<div className={styles.shareWishlistContainer}>
		   				<Share analyticsCategory={'WishlistDetails'} btn={true} style={{position: 'absolute', left: '0', top: '0'}} />
		   			</div>

		   			<ConfirmationPopup
		            	open={openConfirmationPopup}
		            	setOpen={setOpenConfirmationPopup} 
		            	heading={'Delete from Wishlist?'} 
		            	para={"Click “Delete” to Delete this wish-list.<br/><br/>Click “Keep” to keep the experience in your wish-list."}
		            	continueBtnText={'Keep the Wish-list'}
		            	discardBtnText={' Delete from Wish-list'}
		            	discardBtnIcon={<Delete />}
		            	discardBtnAction={deleteFromWishlist}
		            	/>
		   		</div>
		     : ''}
   		</div>
  	);
};

export default WishlistDetails;

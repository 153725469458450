import styles from "./Communities.module.css";
import { useNavigate, } from "react-router-dom";
import React, { useState, useEffect, } from 'react';
import { Helper, } from "../components";


const Communities = (props) => {
	const navigate = useNavigate();
	const [communities, setCommunities] = useState([])
	
	const routeChange = (e, url) => {
	    navigate(url);
	};

	const onCommunityClick = (e, slug, url) => {
		if (slug) {
			Helper.trackAnalytics(props.analyticsCategory, `Click_${Helper.titleCase(slug.split('/')[slug.split('/').length - 1].replaceAll('-', '_'))}`, `Navigate_Community`);
			routeChange(e, `/communities/${slug}`);
		} else {
			window.open(url, '_blank');	
		}
	};

	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/communities?vibeIds=${props.vibeIds || []}&active=true`, {headers: {...Helper.getCommonHeaders(),}})
	      .then((response) => response.json())
	      .then((res) => {
	      	setCommunities(res.data);
	      })
	      .catch((err) => {
	        console.log(err.message);
	      });
	}, {});

  	return (
      	<div className={styles.communities}>
	        <h3 style={{...props.headingStyle}} className={styles.communitiesHeading}>{props.heading ? props.heading : 'Communities for you'}</h3>
	        <label style={{...props.subHeadingStyle}} className={styles.communitiesSubHeading}>{props.subHeading ? props.subHeading : 'Join these communities to find your tribe and get inspired.'}</label>
	        <ul className={styles.communitiesCarousel} style={{...props.communitiesCarouselStyle}}>
	          {communities.map((community, i) => {
	            return(<li onClick={e => onCommunityClick(e, community.slug, community.link)} className={styles.community}>
	              <div style={{position: 'relative'}}>
	                <img src={community.image} alt={i} />
	              </div>

	              <h3>{community.title}</h3>
	              <p>{community.description}</p>
	            </li>)
	          })}
	        </ul>
	    </div>
  	);
}

export default Communities;

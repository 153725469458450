import styles from "./MyVibes.module.css";
import { ToastContainer, toast } from 'react-toastify';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, } from "react-router-dom";
import { Helper, LoginHelper, Favourite, ContactCreator, BottomBar, Sidebar, ContactUs, Communities, Groups, Blogs, Footer, } from "../components";
import { Uploading, DoubleArrowRight, Logo, } from '../icons';
import { config } from "react-spring";


const MyVibes = () => {
	const navigate = useNavigate();
	const xpvibeUniqueId = Helper.storage.getItem('xpvibeUniqueId');
	const { height, width } = Helper.getWindowDimensions();
  	const [vibeIds, setVibeIds] = useState([]);
  	const [vibeDesc, setVibeDesc] = useState('');
	const [loadingScreen, setLoadingScreen] = useState(true);
	const [generatingVibes, setGeneratingVibes] = useState(false);
	const [experienceHeight, setExperienceHeight] = useState(0);
	const experienceRef = useRef(null);
	const brandRef = useRef(null);
	const [brandHeight, setBrandHeight] = useState(0);
	const user = LoginHelper.getUser();
	const [userVibes, setUserVibes] = useState([]);
	const [experienceToContact, setExperienceToContact] = useState({});
	const [openContactCreator, setOpenContactCreator] = useState(false);
	const [data, setData] = useState({brands: [], experiences: [], });
  	const [showLoginPopup, setShowLoginPopup] = useState(false);

  	const getAnalyticsCategory = () => {
  		if (window.location.pathname === '/') {
  			return 'Home'
  		}
	    return 'MyVibes';
	};

	const submitFinalForm = async () => {
		setLoadingScreen(true);
  		await fetch(`${global.config.SERVER_URL}/api/v1/user-vibes`, {
	        method: 'POST',
	        body: JSON.stringify({
	          dayToDay: window.dayToDaySelectedVibes,
	          aspirational: window.aspirationalSelectedVibes,
	          deviceId: Helper.getDeviceId(),
	        }),
	        headers: {
	           'Content-type': 'application/json; charset=UTF-8',
	           ...Helper.getCommonHeaders(),
	        },
	        })
	        .then((response) => response.json())
	        .then((data) => {
	          	setTimeout(
				  () => setLoadingScreen(false), 
				  3000
				);
	            if (data.status == 'success') {
	           		setUserVibes(data.userVibes);
	           		setVibeDesc(data.description);
	           		setVibeIds(data.vibeIds);
	           		Helper.storage.setItem('userVibeIds', JSON.stringify(data.vibeIds));
	           		fetchBrandsAndExperiences(data.vibeIds);
	           		Helper.storage.setItem('xpvibeUniqueId', data.uniqueId);
	           		if (data.guest) Helper.setXPVibes({userVibes: data.userVibes, selectedVibes: {
			          dayToDay: window.dayToDaySelectedVibes,
			          aspirational: window.aspirationalSelectedVibes,
			        }});

	           		if (data.guest) {
	           			setTimeout(
						  	() => setShowLoginPopup(true), 
						  	10000
						);
	           		}

	           		window.dayToDaySelectedVibes = null;
	           		window.aspirationalSelectedVibes = null;
	            }
	        })
	        .catch((err) => {
	          setLoadingScreen(false)
	          console.log(err.message);
	        });
  	}

	useEffect(() => {
		if (window.dayToDaySelectedVibes && window.aspirationalSelectedVibes) {
			setGeneratingVibes(true);
			submitFinalForm();
		} else {
			if (!xpvibeUniqueId) return navigate('/xpvibes');

		    fetch(`${global.config.SERVER_URL}/api/v1/user-vibes?uniqueId=${xpvibeUniqueId}`)
		      .then((response) => response.json())
		      .then((res) => {
		      	if (!res.error) {
		      		setLoadingScreen(false);
	           		setUserVibes(res.vibes);
	           		setVibeDesc(res.description);
	           		const userVibeIds = JSON.parse(Helper.storage.getItem('userVibeIds'));
	           		setVibeIds(userVibeIds || []);
	           		fetchBrandsAndExperiences(userVibeIds || []);

	           		if (res.guest) {
	           			setTimeout(
						  	() => setShowLoginPopup(true), 
						  	10000
						);
	           		}
	           	} else {
	           		navigate('/xpvibes');
	           	}
		     })
		     .catch((err) => {
		     	navigate('/xpvibes');
		     });

		  	if (Helper.storage.getItem('showLoginSuccess')) {
		  		toast.success("Logged in successfully!", Helper.ToastOptions);
		  		Helper.storage.removeItem('showLoginSuccess');
		  	}
		}
	}, {});

	useEffect(() => {
	    if (experienceRef && experienceRef.current && experienceHeight === 0) {
	      let height = 0;
	      for (let c of experienceRef.current.children) {
	        if (height < c.clientHeight) height = c.clientHeight;
	      }  
	      setExperienceHeight(height > 0 ? height - 24 : height);
	    }

	    if (brandRef && brandRef.current && brandHeight === 0) {
	      let height = 0;
	      for (let c of brandRef.current.children) {
	        if (height < c.clientHeight) height = c.clientHeight;
	      }  
	      setBrandHeight(height > 0 ? height + 16 : height);
	    }
	});

	const fetchBrandsAndExperiences = (vibeIdsParam=[]) => {
		Promise.all([
	      fetch(`${global.config.SERVER_URL}/api/v1/brands?vibeIds=${vibeIdsParam}`, {headers: {...Helper.getCommonHeaders(),}}),
	      fetch(`${global.config.SERVER_URL}/api/v1/experiences/v2?vibeIds=${vibeIdsParam}`, {headers: {...Helper.getCommonHeaders(),}}),
	    ]).then(([brands, experiences,]) => {
	        return Promise.all([brands.json(), experiences.json(),]);
	    }).then(([brands, experiences,]) => {
	        setData({
	          brands: brands.data,
	          experiences: experiences.data,
	        });
	    }).catch((err) => {
	        console.log(err);
	    });
	};

	const routeChange = (e, url) => {
	    navigate(url);
	};

  	const onLoginSignup = (event) => {
  		event.preventDefault();
  		navigate('/login-signup');
  	};

  	const setExperienceData = (exp, storage, open) => {
	    setExperienceToContact({id: exp._id, brand: exp.brand});
	    setOpenContactCreator(open);
	};

  	const onClickExperienceCard = (e, i, experience) => {    
      	if (experience.version && experience.version === 2) {
        	routeChange(e, `/experiences/${experience.slug}`);  
      	} else {
        	setExperienceData(experience, 'contactExperienceCreatorLeads', true);
      	}
	};

  	const getSlotsText = (recurrences) => {
  		const months = [...new Set(recurrences.map(recurrence => new Date(recurrence.dates.start).toLocaleString("default", { month: 'short' })))];
  		return months.join(', ').replace(/,(?=[^,]+$)/, ' &');
  	};

  	const getExperiences = () => {
  		return(<div className={styles.experiences}>
	        <h3 className={styles.exHeading}>Experiences for you</h3>
	        <label className={styles.exSubHeading}>Here are some experiences curated for your vibe</label>
	        <ul ref={experienceRef} className={styles.experiencesCarousel}>
	          {data.experiences.map((experience, i) => {
	            return(<li style={{height: experienceHeight - 1}} onClick={e => onClickExperienceCard(e, i, experience)} className={styles.experience}>
	              <div style={{position: 'relative'}}>
	                <img src={experience.cardImages[0] || experience.images[0]} alt={i} />
	              </div>

	              {experience.vibes && experience.vibes.length > 0 && <span className={styles.vibeName}><b>Vibe:</b> {experience.vibes[0].tags.join(', ')}</span>}
	              <h3>{experience.title.split('<label>')[0]}</h3>
	              {experience.unitPrice && <span className={styles.price}>₹ {experience.unitPrice.toLocaleString()} /- Per Person</span>}
	              {experience.recurrences && experience.recurrences.length > 0 && <span className={styles.slots}>{getSlotsText(experience.recurrences)} Slots Available</span>}
	              {experience.duration && <span className={styles.duration}><b>{experience.duration.split(' ')[0]}</b><br/>{experience.duration.split(' ')[1]}</span>}
	            </li>)
	          })}

	          <li onClick={e => routeChange(e, `/listings?tab=experiences`)} className={styles.moreExperiences} style={{height: experienceHeight}}>
	            <div className={styles.moreExperiencesWrap}>
	            	<i><DoubleArrowRight /></i>
	            	<label>More</label>
	            </div>
	          </li>
	        </ul>
	    </div>)
  	};

  	const getBrands = () => {
  		return(<div className={styles.brands}>
	        <h3 className={styles.brandsHeading}>Creators</h3>
	        <div className={styles.brandsContainer} ref={brandRef}>
	          {data.brands && data.brands.map((brand, i) => {
	            return (
	              <div onClick={event => routeChange(event, `/brands/${Helper.generateURL(brand.name)}`)} className={styles.creatorsWrap} key={i} style={{height: brandHeight - 39}}>
	                <div className={styles.creatorsCarousel}>
	                  <div>
	                    <img className={styles.image} src={brand.bannerImages[0]} />
	                  </div>
	                  <i>
	                    <Favourite analyticsCategory={getAnalyticsCategory()} id={brand._id} circleWidth={'40'} circleHeight={'40'} storage={'brand'} style={{ position: 'absolute', top: '25px', right: '30px', bottom: 'unset', left: 'unset', zIndex: 1 }} />
	                  </i>
	                </div>
	                <div className={styles.creatorInfo}>
	                    <label className={styles.name}>{brand.name}</label>
	                  <label className={styles.description}>{brand.shortDescriptions}</label>
	                </div>
	              </div>
	            );
	          })}
	          {data.brands && data.brands.length === 10 && <li onClick={e => routeChange(e, `/listings?tab=creators`)} className={`${styles.creatorsWrap} ${styles.exploreMore}`} style={{height: brandHeight - 39}}>
	            <h3>Explore More</h3>
	          </li>}
	        </div>   
	    </div>)
  	};

  	return (
      	<div className={styles.vibeCheck}>
	        {loadingScreen && <div className={styles.loadingScreen}>
	        	<h2>Loading...</h2>
				{generatingVibes && <label>Stay put you traveler, XPHive is working to generate your travelling persona 😀</label>}
	        	<i className={styles.uploading}><Uploading width={width} height={width} /></i>
	        </div>}

	        {!loadingScreen && userVibes && (
	        	<div className={styles.userVibes}>
	        		<i className={styles.logo}><Logo /></i>
      				<Sidebar analyticsCategory={getAnalyticsCategory()} />
      				<div className={styles.userVibesContainer}>
      					<h2 className={styles.userVibesHeading}>Hello {user ? user.name : 'Explorer'}, here is your <b>Vibe</b></h2>
      					
      					<div className={styles.dominant}>
      						<label className={styles.vibeHeading}>Dominant Vibes</label>
      						{userVibes.slice(0, 3).map((vibe, i) => {
      							return(
      								<div key={i} className={styles.vibeElement}>
      									<img src={vibe.image} />
      									<div className={styles.vibePolygon} style={{backgroundImage: `url(/vibe-polygon.png)`}}>
      										<label>{vibe.title.split(' ')[0]}<br/>{vibe.title.split(' ')[1]}</label>
      									</div>
      								</div>
      							)
      						})}
      					</div>

      					<div className={styles.subtle}>
      						<label className={styles.vibeHeading}>Subtle Vibes</label>
      						{userVibes.slice(3, 6).map((vibe, i) => {
      							return(
      								<div key={i} className={styles.vibeElement}>
      									<img src={vibe.image} />
      									<div className={styles.vibePolygon} style={{backgroundImage: `url(/vibe-polygon.png)`}}>
      										<label>{vibe.title.split(' ')[0]}<br/>{vibe.title.split(' ')[1]}</label>
      									</div>
      								</div>
      							)
      						})}
      					</div>
      					{vibeDesc && <p className={styles.vibeDesc}>{vibeDesc}</p>}
      				</div>

      				<Groups analyticsCategory={getAnalyticsCategory()} vibeIds={vibeIds} />
					<Communities analyticsCategory={getAnalyticsCategory()} vibeIds={vibeIds} />
					<div className={styles.blogs}><Blogs analyticsCategory={getAnalyticsCategory()} containerStyle={{marginTop: '12px'}} /></div>
					{getExperiences()}
					<ContactUs bgColor={'#fff'} heading={'Come travel with XPhive'} subHeading={'Get in touch for planning trips most suitable to your vibe.'} />

				    {showLoginPopup && <div className={styles.loginPopup}>
				    	<h3>Login to Save Your Vibe...</h3>
				    	<button onClick={(e) => {Helper.trackAnalytics('XPVibes', 'Click_Login_signup', 'Click login signup'); onLoginSignup(e);}} className={styles.loginSignupBtn}>Login / Sign Up</button>
				    	<span onClick={(e) => {Helper.trackAnalytics('XPVibes', 'Click_Close_login_popup', 'Close login popup'); setShowLoginPopup(false);}}>Close</span>
				    </div>}

				    <BottomBar analyticsCategory={`BottomBar_${getAnalyticsCategory()}`} />
		        </div>
	        )}

	        <ContactCreator analyticsCategory={getAnalyticsCategory()} hideCommentsBtn={true} open={openContactCreator} brand={experienceToContact.brand || {}} experience={experienceToContact} storage={'contactExperienceCreatorLeads'} setData={setExperienceData} />
	        <ToastContainer />
	        {!loadingScreen && <Footer style={{paddingBottom: '90px'}} />}
      	</div>
  	);
};

export default MyVibes;

const Duration = () => {
  return (
		<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Group 735">
				<rect id="Rectangle 739" x="1.5" y="1" width="18" height="18" rx="9" stroke="black" strokeWidth="2"/>
				<path id="Line 188" d="M10.5 5V11" stroke="#FFEA00" strokeWidth="2" strokeLinecap="round"/>
				<path id="Line 189" d="M6.5 14.2422L10.7426 9.99955" stroke="#FFEA00" strokeWidth="2" strokeLinecap="round"/>
			</g>
		</svg>
  	);
};

export default Duration;

const Camera = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="fi-rr-camera" clipPath="url(#clip0_130_244)">
			<path id="Vector" d="M13.4583 2.83333H13.0985L11.5515 0.827333C11.3521 0.570779 11.097 0.362962 10.8054 0.219622C10.5138 0.0762815 10.1934 0.00117799 9.8685 0L7.1315 0C6.80659 0.00117799 6.4862 0.0762815 6.19461 0.219622C5.90302 0.362962 5.64788 0.570779 5.4485 0.827333L3.9015 2.83333H3.54167C2.6027 2.83446 1.70252 3.20796 1.03857 3.87191C0.374625 4.53585 0.00112473 5.43604 0 6.375L0 13.4583C0.00112473 14.3973 0.374625 15.2975 1.03857 15.9614C1.70252 16.6254 2.6027 16.9989 3.54167 17H13.4583C14.3973 16.9989 15.2975 16.6254 15.9614 15.9614C16.6254 15.2975 16.9989 14.3973 17 13.4583V6.375C16.9989 5.43604 16.6254 4.53585 15.9614 3.87191C15.2975 3.20796 14.3973 2.83446 13.4583 2.83333ZM6.5705 1.69292C6.63685 1.60725 6.72187 1.53784 6.81908 1.48997C6.91629 1.4421 7.02314 1.41703 7.1315 1.41667H9.8685C9.97684 1.41713 10.0837 1.44225 10.1809 1.49011C10.278 1.53797 10.3631 1.60733 10.4295 1.69292L11.3092 2.83333H5.69075L6.5705 1.69292ZM15.5833 13.4583C15.5833 14.0219 15.3595 14.5624 14.9609 14.9609C14.5624 15.3595 14.0219 15.5833 13.4583 15.5833H3.54167C2.97808 15.5833 2.43758 15.3595 2.03906 14.9609C1.64055 14.5624 1.41667 14.0219 1.41667 13.4583V6.375C1.41667 5.81141 1.64055 5.27091 2.03906 4.8724C2.43758 4.47388 2.97808 4.25 3.54167 4.25H13.4583C14.0219 4.25 14.5624 4.47388 14.9609 4.8724C15.3595 5.27091 15.5833 5.81141 15.5833 6.375V13.4583Z" fill="#1C1C1E"/>
			<path id="Vector_2" d="M8.5 5.6666C7.65943 5.6666 6.83774 5.91586 6.13883 6.38286C5.43992 6.84985 4.89519 7.51361 4.57351 8.2902C4.25184 9.06678 4.16768 9.92132 4.33166 10.7457C4.49565 11.5702 4.90043 12.3274 5.4948 12.9218C6.08917 13.5162 6.84645 13.921 7.67087 14.0849C8.49529 14.2489 9.34982 14.1648 10.1264 13.8431C10.903 13.5214 11.5668 12.9767 12.0337 12.2778C12.5007 11.5789 12.75 10.7572 12.75 9.9166C12.7489 8.78978 12.3007 7.70943 11.504 6.91264C10.7072 6.11586 9.62683 5.66773 8.5 5.6666ZM8.5 12.7499C7.93962 12.7499 7.39183 12.5838 6.92589 12.2724C6.45995 11.9611 6.09679 11.5186 5.88234 11.0009C5.66789 10.4831 5.61179 9.91346 5.72111 9.36385C5.83044 8.81423 6.10028 8.30938 6.49653 7.91313C6.89278 7.51688 7.39763 7.24704 7.94725 7.13771C8.49686 7.02839 9.06655 7.0845 9.58427 7.29894C10.102 7.51339 10.5445 7.87655 10.8558 8.34249C11.1672 8.80843 11.3333 9.35622 11.3333 9.9166C11.3333 10.668 11.0348 11.3887 10.5035 11.9201C9.97212 12.4514 9.25145 12.7499 8.5 12.7499Z" fill="#1C1C1E"/>
		</g>
		<defs>
			<clipPath id="clip0_130_244">
				<rect width="17" height="17" fill="white"/>
			</clipPath>
		</defs>
	</svg>
  );
};

export default Camera;

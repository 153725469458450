import styles from "./CustomWishlist.module.css";
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Back, Helper, ConfirmationPopup, } from '../components';
import { Upload, Camera, CloseWhite, Check, BackArrow, BackArrowSmall, } from "../icons";
import ImageUploading from 'react-images-uploading';
import Webcam from "react-webcam";
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";


const CustomWishlist = () => {
	const navigate = useNavigate();
	const [customWhishlistImage, setCustomWhishlistImage] = useState('');
	const [emptyImg, setEmptyImg] = useState(false);
	const [uploadingImg, setUploadingImg] = useState(false);
	const [openCamera, setOpenCamera] = useState(false);
	const [submittingForm, setSubmittingForm] = useState(false);
	const [wishlistName, setWishlistName] = useState('');
	const [emptyWishlistName, setEmptyWishlistName] = useState(false);
	const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
	const [wishlistTag, setWishlistTag] = useState('');
	const [emptyWishlistTag, setEmptyWishlistTag] = useState(false);
	const [description, setDescription] = useState('');
	const [emptyDesc, setEmptyDesc] = useState(false);
	const { height, width } = Helper.getWindowDimensions();
	const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
	const videoConstraints = {
	  	height: height
	};
	if (width === 480) videoConstraints.width = 410;


	const handleSwitchCamera = () => {
		if (facingMode === FACING_MODE_USER) setFacingMode(FACING_MODE_ENVIRONMENT);
		else setFacingMode(FACING_MODE_USER);
	};

	const onChange = async (imageList, addUpdateIndex) => {
		setEmptyImg(false);
		setUploadingImg(true);
		await fetch(`${global.config.SERVER_URL}/api/v1/uploads`, {
	        method: 'POST',
	        body: JSON.stringify({
	          image: imageList[0].data_url,
	        }),
	        headers: {
	           'Content-type': 'application/json; charset=UTF-8',
	           ...Helper.getCommonHeaders(),
	        },
	        })
	        .then((response) => response.json())
	        .then((data) => {
	           	if (data.path) setCustomWhishlistImage(data.path);
	           	setUploadingImg(false);
	        })
	        .catch((err) => {
	          console.log(err.message);
	          setUploadingImg(false);
	        });
	};

	const onOpenCamera = () => {
		setOpenCamera(true);
	};

	const closeCamera = () => {
		setOpenCamera(false);
	};


	const onChangeWishlistName = (e) => {
	    setEmptyWishlistName(false);
	    const result = event.target.value.replace(/[^a-z ]/gi, '');
	    setWishlistName(result);
	};

	const onChangeTag = (e) => {
	    setEmptyWishlistTag(false);
	    setWishlistTag(e.target.value);
	};

	const onChangeDesc = (e) => {
	    setEmptyDesc(false);
	    setDescription(e.target.value);
	};

	const goBackToPrevPage = () => {
		navigate(-1);
	};


	const saveWishList = async (event) => {
	    event.preventDefault();
	    if (!wishlistName) setEmptyWishlistName(true);
	    if (!wishlistTag) setEmptyWishlistTag(true);
	    if (!description) setEmptyDesc(true);
	    if (!customWhishlistImage) setEmptyImg(true);


	    if (!submittingForm && wishlistName && wishlistTag && description && customWhishlistImage 
	    	&& !emptyDesc && !emptyImg && !emptyWishlistTag && !emptyWishlistName) {
	      Helper.trackAnalytics('CustomWishlist', 'Click_Submit', wishlistName);
	      setSubmittingForm(true);
	      await fetch(`${global.config.SERVER_URL}/api/v1/wishlists`, {
	        method: 'POST',
	        body: JSON.stringify({
	          name: wishlistName,
	          tags: wishlistTag.split(' '),
	          description,
	          image: customWhishlistImage,
	          state: true,
	        }),
	        headers: {
	           'Content-type': 'application/json; charset=UTF-8',
	           ...Helper.getCommonHeaders(),
	        },
	        })
	        .then((response) => response.json())
	        .then((data) => {
	          setSubmittingForm(false)
	           if (data.status == 'success') navigate(`/wishlists/${data.id}`);
	        })
	        .catch((err) => {
	          setSubmittingForm(false)
	          console.log(err.message);
	        });
	    }
	};


	const goBack = () => {
		if (customWhishlistImage || wishlistName || wishlistTag || description) {
			setOpenConfirmationPopup(true);
		} else {
			navigate(-1);
		}
	};

  	return (
      	<div className={styles.customWishList}>
      		<Back analyticsCategory={'CustomWishlist'} />
      		<div>
      			<h1 className={styles.heading}>Make Your Wish-list...</h1>
      			<p className={styles.para}>Use this to plan and save your travel bucket list. Add images, tags, texts and hyperlinks here.</p>

      			<div className={styles.photoWrap}>
      				<div className={`${styles.photoSection} ${emptyImg ? styles.invalid : ''}`} style={{height: (((width - 60) / 2) - 16) * 1.7}}>
      					{customWhishlistImage ? <img className={styles.uploadedImg} src={uploadingImg ? '/uploading.svg' : customWhishlistImage} /> : <img className={uploadingImg ? styles.uploadedImg : styles.uploadImgPlaceholder} src={uploadingImg ? '/uploading.svg' : '/add-c-bl.svg'} />}
      				</div>
      				<div className={styles.uploadBtnSection} style={{height: (((width - 60) / 2) - 16) * 1.7}}>
      					<label>Add an image to your Wish</label>
						<button className={styles.cameraBtn} onClick={onOpenCamera}><Camera />Click</button>

						{openCamera && 
							<div className={styles.cameraScreen}>
								<i className={styles.closeIcon} onClick={closeCamera}><CloseWhite /></i>
								<Webcam
								    audio={false}
								    height={height}
								    screenshotFormat="image/jpeg"
								    videoConstraints={{
							          ...videoConstraints,
							          facingMode
							        }}
								  >
								    {({ getScreenshot }) => (
									     <div className={styles.cameraBtns}>
									     	<button className={styles.switchCamera} onClick={handleSwitchCamera}>Switch camera</button>
									      	<button className={styles.capturePhotoBtn}
										        onClick={() => {
										          const imageSrc = getScreenshot();
										          setOpenCamera(false);
										          onChange([{data_url: imageSrc}]);
										        }}
										      >
										        Capture
										    </button>
										 </div>
								    )}

								</Webcam>
							</div>}

      					<div className={styles.uploadBtn}>
		   					<ImageUploading
						        multiple={false}
						        value={''}
						        onChange={onChange}
						        maxNumber={1}
						        dataURLKey="data_url"
						      >
						        {({
						          imageList,
						          onImageUpload,
						          onImageRemoveAll,
						          onImageUpdate,
						          onImageRemove,
						          isDragging,
						          dragProps,
						        }) => (
						          <div className="upload__image-wrapper">
						            <button
						            	className={styles.imgModifyBtn}
						              	style={isDragging ? { color: 'red' } : undefined}
						              	onClick={onImageUpload}
						              	{...dragProps}
						            >
						              <Upload />Upload
						            </button><br/>
						          </div>
						        )}
						      </ImageUploading>
		   				</div>
      				</div>
      			</div>

      			<div className={styles.wishlistForm}>
                    <div>
                      <label className={styles.label}>Give this Wish a Name</label>
                      <input className={emptyWishlistName ? styles.invalid : styles.valid} type="text" placeholder="Wish-list Name" value={wishlistName} onChange={e => onChangeWishlistName(e)} />
                    </div>

                    <div>
	                    <label className={styles.label}>Add Tags to Your Wish</label>
	                    <textarea className={emptyWishlistTag ? styles.invalid : styles.valid} rows="2" placeholder="#tag1 #tag2 #tag3" value={wishlistTag} onChange={e => onChangeTag(e)} />
	                </div>

	                <div>
	                    <label className={styles.label}>Description</label>
	                    <textarea className={emptyDesc ? styles.invalid : styles.valid} rows="5" placeholder="Description" value={description} onChange={e => onChangeDesc(e)} />
	                </div>

	                <button className={styles.saveWishListBtn} onClick={saveWishList}><Check color='#fff' />Add to Wishlist</button>
		   			<button className={styles.discardBtn} onClick={goBack}><BackArrow />Discard Changes and Go Back</button>
	            </div>
	            <ConfirmationPopup
	            	open={openConfirmationPopup}
	            	setOpen={setOpenConfirmationPopup} 
	            	heading={'Discard Your Wish-list?'} 
	            	para={"Click “Continue” to continue making your wish-list.<br/><br/>Click “Discard” to discard the current wish-list and go back to Profile Page."}
	            	continueBtnText={'Continue to Wish-list'}
	            	discardBtnText={'Discard and Go Back'}
	            	discardBtnIcon={<BackArrowSmall />}
	            	discardBtnAction={goBackToPrevPage}
	            	/>
      		</div>
      	</div>
  	);
};

export default CustomWishlist;

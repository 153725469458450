const Explore = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
	  	<path d="M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z" stroke="#1C1C1E" strokeWidth="2"/>
	  	<path d="M10.7467 12.1098C10.8708 11.7935 11.0848 11.5204 11.3622 11.3243L18.3873 6.35704C18.4029 6.34605 18.4122 6.34301 18.4152 6.34218C18.4183 6.34128 18.4206 6.34111 18.423 6.34126C18.4292 6.34164 18.4447 6.34513 18.4623 6.35917C18.48 6.3732 18.4868 6.38748 18.4886 6.39345C18.4893 6.39583 18.4896 6.39803 18.4895 6.40133C18.4893 6.40436 18.4885 6.41417 18.4812 6.43178L15.2794 14.2391C15.1589 14.533 14.9603 14.7883 14.7051 14.9774L7.7126 20.158C7.69716 20.1695 7.68779 20.1727 7.68492 20.1736C7.68179 20.1745 7.67962 20.1748 7.67718 20.1747C7.67102 20.1744 7.65542 20.1712 7.63739 20.1574C7.61936 20.1436 7.61223 20.1294 7.61038 20.1235C7.60965 20.1212 7.60928 20.119 7.60939 20.1157C7.6095 20.1127 7.61019 20.1028 7.61721 20.085L10.7467 12.1098ZM13.0014 14.8331C14.0139 14.8331 14.8347 14.0123 14.8347 12.9998C14.8347 11.9873 14.0139 11.1665 13.0014 11.1665C11.9889 11.1665 11.1681 11.9873 11.1681 12.9998C11.1681 14.0123 11.9889 14.8331 13.0014 14.8331Z" fill="#1C1C1E" stroke="#1C1C1E" strokeWidth="1.5"/>
	</svg>
  );
};

export default Explore;

import styles from "./LoginSignup.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Back, Helper, LoginHelper, } from "../components";
import { Google, Apple, Facebook, Dash, BackArrowWhite, BackArrow, Scenery } from "../icons";
import React, { useState, useEffect, useCallback } from 'react';
import { LoginSocialGoogle, LoginSocialFacebook, LoginSocialApple, } from 'reactjs-social-login';
const REDIRECT_URI = window.location.href;


const LoginSignup = () => {
	const navigate = useNavigate();
	const { width, height } = Helper.getWindowDimensions();
	const [searchParams] = useSearchParams();
	const storage_fId = searchParams.get('storage_fId');
	const [userEmail, setUserEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [invalidEmail, setInvalidEmail] = useState(false);
	const [userPassword, setUserPassword] = useState('');
	const [invalidPassword, setInvalidPassword] = useState(false);
	const [submittingForm, setSubmittingForm] = useState(false);
	const [invalidCredentials, setInvalidCredentials] = useState(false);
	const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);
	const [showVerifyEmail, setShowVerifyEmail] = useState(false);
	const [verificationEmailSent, setVerificationEmailSent] = useState(false);
	const [savedXPVibes, setSavedXPVibes] = useState(false);


  	const onLoginStart = useCallback(() => {
	    console.log('login start');
	}, []);

	const postLoginActivity = async (user, jwt) => {
		if (storage_fId) {
			const storage = storage_fId.split('_')[0];
			const fId = storage_fId.split('_')[1];
			Helper.toggleFavourite('Login', storage, fId);
		}

		const xpvibes = Helper.getXPVibes();
		if (xpvibes) {
			await fetch(`${global.config.SERVER_URL}/api/v1/user-vibes`, {
		        method: 'POST',
		        body: JSON.stringify({
		          ...xpvibes.selectedVibes,
		          userVibes: xpvibes.userVibes,
		          deviceId: Helper.getDeviceId(),
		        }),
		        headers: {
		           'Content-type': 'application/json; charset=UTF-8',
		           'Authorization': `Bearer ${jwt}`,
		        },
		        })
		        .then((response) => response.json())
		        .then((data) => {
		           if (data.status == 'success') {
		           		Helper.removeXPVibes();
		           		setSavedXPVibes(true);
		           		console.log('User vibes saved successfully!');
		           }
		        })
		        .catch((err) => {
		          console.log(err.message);
		        });
		}
	};

	const navigatePostLogin = (user) => {
		Helper.storage.setItem('showLoginSuccess', true);
		if (user.xpvibes || savedXPVibes) {
			navigate(`/portfolio/${user.handle}`);
		} else {
			navigate('/xpvibes');
		}
	};


	const submitSocialLogin = async (provider, data) => {
		Helper.trackAnalytics('SocialLogin', 'Click_Submit', 'submit');
	  	setSubmittingForm(true);
	  	await fetch(`${global.config.SERVER_URL}/api/v1/users/login-signup`, {
	    	method: 'POST',
	    	body: JSON.stringify({
	       		email: data.email,
	       		verified: data.email_verified,
	       		name: data.name,
	       		picture: data.picture,
	       		provider: provider,
	    	}),
	    	headers: {
	       		'Content-type': 'application/json; charset=UTF-8',
	    	},
	    	})
	    	.then((response) => response.json())
	    	.then(async (data) => {
	      		setSubmittingForm(false);
	       		if (data.success) {
	       			const user = LoginHelper.getUserFromToken(data.jwt);
       				LoginHelper.setUser(data.jwt);
       				await postLoginActivity(user, data.jwt);
       				navigatePostLogin(user);
	       		} else {
	       			setErrorMessage(data.error);
	       		}
	    	})
	    	.catch((err) => {
	      		setSubmittingForm(false);
	      		console.log(err.message);
	    	});
	};

	const onLoginSuccess = useCallback(({ provider, data }) => {
      	submitSocialLogin(provider, data);
    }, []);

	const onChangeUserEmail = (e) => {
    	setInvalidEmail(false);
    	setUserEmail(e.target.value);
  	};

  	const onChangeUserPassword = (e) => {
    	setInvalidPassword(false);
    	setUserPassword(e.target.value);
  	};

  	const goBackToLogin = () => {
  		setInvalidCredentials(false);
  		setResetPasswordEmailSent(false);
  		setShowVerifyEmail(false);
  		setVerificationEmailSent(false);
  	};

  	const verifyEmail = async (event) => {
	    event.preventDefault();
	    if (!userEmail) setInvalidEmail(true);

	    if (!submittingForm && userEmail && !invalidEmail) {
	      	Helper.trackAnalytics('VerifyEmail', 'Click_Submit', 'submit');
	      	setSubmittingForm(true);
	      	await fetch(`${global.config.SERVER_URL}/api/v1/users/email-verification`, {
	        	method: 'POST',
	        	body: JSON.stringify({
	           		email: userEmail
	        	}),
	        	headers: {
	           		'Content-type': 'application/json; charset=UTF-8',
	        	},
	        	})
	        	.then((response) => response.json())
	        	.then((data) => {
	          		setSubmittingForm(false);
	           		if (data.success) {
	           			setVerificationEmailSent(true);
	           		} else {
	           			setErrorMessage(data.error);
	           		}
	        	})
	        	.catch((err) => {
	          		setSubmittingForm(false);
	          		console.log(err.message);
	        	});
	    }
	};

  	const forgotPassword = async (event) => {
	    event.preventDefault();
	    if (!userEmail) setInvalidEmail(true);

	    if (!submittingForm && userEmail && !invalidEmail) {
	      	Helper.trackAnalytics('ForgotPassword', 'Click_Submit', 'submit');
	      	setSubmittingForm(true);
	      	await fetch(`${global.config.SERVER_URL}/api/v1/users/forgot-password`, {
	        	method: 'POST',
	        	body: JSON.stringify({
	           		email: userEmail
	        	}),
	        	headers: {
	           		'Content-type': 'application/json; charset=UTF-8',
	        	},
	        	})
	        	.then((response) => response.json())
	        	.then((data) => {
	          		setSubmittingForm(false);
	           		if (data.success) {
	           			setResetPasswordEmailSent(true);
	           		} else {
	           			setErrorMessage(data.error);
	           		}
	        	})
	        	.catch((err) => {
	          		setSubmittingForm(false);
	          		console.log(err.message);
	        	});
	    }
	};

  	const handleSubmit = async (event) => {
	    event.preventDefault();
	    if (!userEmail) setInvalidEmail(true)
	    if (!userPassword) setInvalidPassword(true)

	    if (!submittingForm && userEmail && !invalidEmail && userPassword && !invalidPassword) {
	      	Helper.trackAnalytics('Login', 'Click_Submit', 'submit');
	      	setSubmittingForm(true);
	      	await fetch(`${global.config.SERVER_URL}/api/v1/users/login-signup`, {
	        	method: 'POST',
	        	body: JSON.stringify({
	           		email: userEmail,
	           		password: userPassword,
	        	}),
	        	headers: {
	           		'Content-type': 'application/json; charset=UTF-8',
	        	},
	        	})
	        	.then((response) => response.json())
	        	.then(async (data) => {
	          		setSubmittingForm(false);
	           		if (data.success) {
	           			setInvalidCredentials(false);
	           			const user = LoginHelper.getUserFromToken(data.jwt);
	           			await postLoginActivity(user, data.jwt);
	           			if (user.verified) {
	           				LoginHelper.setUser(data.jwt);
	           				navigatePostLogin(user);
	           			} else {
	           				setShowVerifyEmail(true);
	           			}
	           		} else {
	           			setErrorMessage(data.error);
	           			setInvalidCredentials(true);
	           		}
	        	})
	        	.catch((err) => {
	          		setSubmittingForm(false);
	          		console.log(err.message);
	        	});
	    }
	};

  	return (
   		<div className={styles.loginSignup}>
   			<Back analyticsCategory={'Login'} whiteIcon={true} />
   			<i className={styles.loginSignupImg}><Scenery width={width * 0.67} height={width * 0.77} /></i>

   			<div className={styles.loginSignupContainer} style={{ backgroundImage: `url("/ls-ellipse.png")` }}>
   				
   				{!invalidCredentials && !showVerifyEmail && <div>
	   				<form onSubmit={handleSubmit}>
		                <input className={invalidEmail ? styles.invalid : styles.valid} type="email" placeholder="Email ID" value={userEmail} onChange={e => onChangeUserEmail(e)} />

		                <input className={invalidPassword ? styles.invalid : styles.valid} type="password" placeholder="Password" value={userPassword} onChange={e => onChangeUserPassword(e)} />
		                <button type="submit">Login / Sign Up</button>
		            </form>

		            <div className={styles.socialLogin}>
		            	<LoginSocialGoogle
				            scope={'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'}
				            className={styles.socialLoginBtn}
				            client_id={global.config.REACT_APP_GG_APP_ID || ''}
				            onLoginStart={onLoginStart}
				            onResolve={onLoginSuccess}
				            onReject={(err) => {
				              console.log(err)
				            }}>
				          	<Google /><i className={styles.dashIcon}><Dash /></i><label>Login with Google</label>
				        </LoginSocialGoogle>
		            </div>
		            <p className={styles.text}>Welcome to the Community !</p>
		        </div>}

		        {invalidCredentials && !resetPasswordEmailSent && <div className={styles.invalidCredentials}>
		        	<img src="/fi-rr-info.svg" />
	        		<p className={styles.headingTxt}>You’ve Entered Wrong Password, <br/>Do You Want to -</p>
	        		<button onClick={event => forgotPassword(event)} className={styles.forgotPasswordBtn}>Forgot Password<label>You will receive Email to Reset Your Password... </label>
	        			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
						  <path d="M15.4013 10.3798L10.8113 5.78982C10.6239 5.60356 10.3705 5.49902 10.1063 5.49902C9.84208 5.49902 9.58863 5.60356 9.40127 5.78982C9.30754 5.88278 9.23314 5.99338 9.18238 6.11524C9.13161 6.2371 9.10547 6.3678 9.10547 6.49982C9.10547 6.63183 9.13161 6.76253 9.18238 6.88439C9.23314 7.00625 9.30754 7.11685 9.40127 7.20982L14.0013 11.7898C14.095 11.8828 14.1694 11.9934 14.2202 12.1152C14.2709 12.2371 14.2971 12.3678 14.2971 12.4998C14.2971 12.6318 14.2709 12.7625 14.2202 12.8844C14.1694 13.0063 14.095 13.1169 14.0013 13.2098L9.40127 17.7898C9.21296 17.9768 9.10665 18.2309 9.10571 18.4963C9.10477 18.7616 9.20929 19.0165 9.39627 19.2048C9.58325 19.3931 9.83737 19.4994 10.1027 19.5004C10.3681 19.5013 10.623 19.3968 10.8113 19.2098L15.4013 14.6198C15.9631 14.0573 16.2786 13.2948 16.2786 12.4998C16.2786 11.7048 15.9631 10.9423 15.4013 10.3798Z" fill="#1C1C1E"/>
						</svg>
	        		</button>

	        		<button onClick={event => goBackToLogin(event)} className={styles.goBackToLogin}><BackArrowWhite /> Go Back</button>
	        	</div>}

	        	{resetPasswordEmailSent && <div className={styles.resetPasswordEmailSent}>
		        	<img src="/fi-rr-smile.svg" />
	        		<p className={styles.headingTxt}>Check Email For reset Link...</p>
	        		<p className={styles.linkSentTxt}>We’ve Sent Password Reset Link to: <b>{userEmail}</b></p>
	        		<p className={styles.sendAgainTxt}>If it doesn’t arrive soon, check spam folder or <a onClick={event => forgotPassword(event)}>send email again.</a></p>
	        		
	        		<label className={styles.goBackToLoginLabel} onClick={event => goBackToLogin(event)}><BackArrow /> Go Back to Login Page</label>
	        	</div>}


	        	{showVerifyEmail && !verificationEmailSent && <div className={styles.verifyEmail}>
		        	<img src="/fi-rr-spinner-alt.svg" />
	        		<p className={styles.headingTxt}>Welcome!<br/>Seems like You’re New Here...</p>
	        		<button onClick={event => verifyEmail(event)} className={styles.verifyEmailBtn}>Verify Email ID<label>You will receive Email with verification link...</label>
	        			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
						  <path d="M15.4013 10.3798L10.8113 5.78982C10.6239 5.60356 10.3705 5.49902 10.1063 5.49902C9.84208 5.49902 9.58863 5.60356 9.40127 5.78982C9.30754 5.88278 9.23314 5.99338 9.18238 6.11524C9.13161 6.2371 9.10547 6.3678 9.10547 6.49982C9.10547 6.63183 9.13161 6.76253 9.18238 6.88439C9.23314 7.00625 9.30754 7.11685 9.40127 7.20982L14.0013 11.7898C14.095 11.8828 14.1694 11.9934 14.2202 12.1152C14.2709 12.2371 14.2971 12.3678 14.2971 12.4998C14.2971 12.6318 14.2709 12.7625 14.2202 12.8844C14.1694 13.0063 14.095 13.1169 14.0013 13.2098L9.40127 17.7898C9.21296 17.9768 9.10665 18.2309 9.10571 18.4963C9.10477 18.7616 9.20929 19.0165 9.39627 19.2048C9.58325 19.3931 9.83737 19.4994 10.1027 19.5004C10.3681 19.5013 10.623 19.3968 10.8113 19.2098L15.4013 14.6198C15.9631 14.0573 16.2786 13.2948 16.2786 12.4998C16.2786 11.7048 15.9631 10.9423 15.4013 10.3798Z" fill="#1C1C1E"/>
						</svg>
	        		</button>

	        		<button onClick={event => goBackToLogin(event)} className={styles.goBackToLogin}><BackArrowWhite /> Go Back</button>
	        	</div>}

	        	{verificationEmailSent && <div className={styles.verificationEmailSent}>
		        	<img src="/fi-rr-spinner-alt.svg" />
	        		<p className={styles.headingTxt}>Verify Your Email</p>
	        		<p className={styles.linkSentTxt}>We’ve sent email to <b>{userEmail}</b> to verify your email address and activate your account. The link will expire within 24hrs.</p>
	        		<p className={styles.sendAgainTxt}>If it doesn’t arrive soon, check spam folder or <a onClick={event => verifyEmail(event)}>send email again.</a></p>
	        		
	        		<label className={styles.goBackToLoginLabel} onClick={event => goBackToLogin(event)}><BackArrow /> Go Back to Login Page</label>
	        	</div>}
	            
   			</div>
   		</div>
  	);
};

export default LoginSignup;

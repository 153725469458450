import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';


const VerifyEmail = () => {
	const navigate = useNavigate();
	const code = window.location.search.replace('?code=', '');
	const [errorMessage, setErrorMessage] = useState('');
	const [reqSent, setReqSent] = useState(false);

    const triggerEmailVerification = async () => {
	    if (code && !reqSent) {
	    	setReqSent(true);
	      	await fetch(`${global.config.SERVER_URL}/api/v1/users/verify-email`, {
	        	method: 'POST',
	        	body: JSON.stringify({
	           		code: code,
	        	}),
	        	headers: {
	           		'Content-type': 'application/json; charset=UTF-8',
	        	},
	        	})
	        	.then((response) => response.json())
	        	.then((data) => {
	           		if (data.success) {
	           			navigate('/');
	           		} else {
	           			setErrorMessage(data.error);
	           		}
	        	})
	        	.catch((err) => {
	          		console.log(err.message);
	        	});
	    }
	}

	triggerEmailVerification();

  	return (<div>{errorMessage && <p style={{padding: '30px'}}>{errorMessage}</p>}</div>);
};

export default VerifyEmail;

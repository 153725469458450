const RatingWhiteIcon = (props) => {
  return (
	<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
	  <g clipPath="url(#clip0_2121_2050)">
	    <path d="M0.718042 6.71727L2.64638 8.12561L1.91404 10.3936C1.79569 10.7453 1.79419 11.1259 1.90977 11.4786C2.02534 11.8312 2.2518 12.1371 2.55538 12.3506C2.85375 12.5709 3.21534 12.689 3.58625 12.6871C3.95716 12.6853 4.31755 12.5636 4.61371 12.3403L6.49925 10.9526L8.38533 12.3387C8.68317 12.5578 9.04281 12.6768 9.41253 12.6786C9.78226 12.6804 10.143 12.5649 10.443 12.3487C10.743 12.1326 10.9666 11.8268 11.0819 11.4755C11.1972 11.1242 11.1981 10.7454 11.0845 10.3936L10.3521 8.12561L12.2805 6.71727C12.5779 6.4998 12.799 6.19389 12.9122 5.84323C13.0254 5.49258 13.0249 5.11512 12.9108 4.76477C12.7966 4.41443 12.5747 4.10911 12.2767 3.89243C11.9786 3.67575 11.6198 3.5588 11.2513 3.55827H8.88258L8.16379 1.31794C8.05076 0.965292 7.82865 0.657655 7.52948 0.439391C7.23032 0.221127 6.86957 0.103516 6.49925 0.103516C6.12893 0.103516 5.76818 0.221127 5.46902 0.439391C5.16986 0.657655 4.94774 0.965292 4.83471 1.31794L4.11592 3.55827H1.74938C1.3809 3.5588 1.02202 3.67575 0.723989 3.89243C0.425958 4.10911 0.204022 4.41443 0.0898824 4.76477C-0.0242572 5.11512 -0.0247624 5.49258 0.088439 5.84323C0.20164 6.19389 0.422758 6.4998 0.720208 6.71727H0.718042Z" fill="#F2F2F7"/>
	  </g>
	  <defs>
	    <clipPath id="clip0_2121_2050">
	      <rect width="13" height="13" fill="white"/>
	    </clipPath>
	  </defs>
	</svg>
  );
};

export default RatingWhiteIcon;

const BlackHeart = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
	  <g clipPath="url(#clip0_130_208)">
	    <path d="M22.6046 2.47552C21.1501 2.49815 19.7273 2.90417 18.48 3.65258C17.2326 4.401 16.2048 5.46531 15.5004 6.73802C14.796 5.46531 13.7682 4.401 12.5208 3.65258C11.2735 2.90417 9.85071 2.49815 8.39623 2.47552C6.07761 2.57626 3.8931 3.59056 2.31995 5.29683C0.746796 7.00309 -0.0871174 9.26262 0.000393717 11.5818C0.000393717 17.455 6.18231 23.8694 11.3671 28.2184C12.5247 29.1912 13.9883 29.7246 15.5004 29.7246C17.0125 29.7246 18.4761 29.1912 19.6337 28.2184C24.8185 23.8694 31.0004 17.455 31.0004 11.5818C31.0879 9.26262 30.254 7.00309 28.6808 5.29683C27.1077 3.59056 24.9232 2.57626 22.6046 2.47552Z" fill="#1C415B"/>
	  </g>
	  <defs>
	    <clipPath id="clip0_130_208">
	      <rect width="31" height="31" fill="white"/>
	    </clipPath>
	  </defs>
	</svg>
  );
};

export default BlackHeart;

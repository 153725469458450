const ThumbsUp = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="fi-rr-thumbs-up" clipPath="url(#clip0_482_710)">
			<path id="Vector" d="M22.773 7.72139C22.3041 7.181 21.7245 6.74767 21.0736 6.45075C20.4226 6.15382 19.7155 6.00024 19 6.00039H15.011L15.347 3.95939C15.4659 3.24065 15.3225 2.50313 14.9429 1.88133C14.5633 1.25954 13.9728 0.794906 13.2792 0.572185C12.5856 0.349464 11.835 0.383494 11.1644 0.668068C10.4938 0.952642 9.94777 1.4688 9.626 2.12239L7.712 6.00039H5C3.67441 6.00198 2.40356 6.52927 1.46622 7.46661C0.528882 8.40395 0.00158786 9.6748 0 11.0004L0 16.0004C0.00158786 17.326 0.528882 18.5968 1.46622 19.5342C2.40356 20.4715 3.67441 20.9988 5 21.0004H18.3C19.5035 20.9955 20.6652 20.5587 21.5738 19.7696C22.4824 18.9804 23.0776 17.8913 23.251 16.7004L23.956 11.7004C24.0553 10.9911 24.001 10.2688 23.7969 9.58232C23.5928 8.89584 23.2437 8.26121 22.773 7.72139ZM2 16.0004V11.0004C2 10.2047 2.31607 9.44168 2.87868 8.87907C3.44129 8.31646 4.20435 8.00039 5 8.00039H7V19.0004H5C4.20435 19.0004 3.44129 18.6843 2.87868 18.1217C2.31607 17.5591 2 16.796 2 16.0004ZM21.971 11.4194L21.265 16.4194C21.1618 17.1334 20.8057 17.7866 20.2616 18.2603C19.7175 18.7339 19.0214 18.9966 18.3 19.0004H9V7.73439C9.09424 7.65229 9.17226 7.55324 9.23 7.44239L11.419 3.00739C11.5011 2.85932 11.6171 2.73284 11.7576 2.63835C11.8981 2.54386 12.059 2.48406 12.2271 2.46385C12.3952 2.44364 12.5657 2.46361 12.7246 2.52212C12.8834 2.58062 13.0262 2.67599 13.141 2.80039C13.2392 2.91464 13.3111 3.04916 13.3513 3.19436C13.3916 3.33956 13.3994 3.49186 13.374 3.64039L12.846 6.84039C12.8228 6.98337 12.831 7.12968 12.8699 7.2692C12.9089 7.40871 12.9776 7.5381 13.0715 7.64842C13.1654 7.75874 13.2821 7.84735 13.4136 7.90812C13.545 7.96889 13.6882 8.00038 13.833 8.00039H19C19.4294 8.00033 19.8538 8.09246 20.2445 8.27054C20.6353 8.44862 20.9832 8.7085 21.2649 9.03263C21.5465 9.35675 21.7553 9.73757 21.8772 10.1493C21.999 10.5611 22.031 10.9942 21.971 11.4194Z" fill={props.fill}/>
		</g>
		<defs>
			<clipPath id="clip0_482_710">
				<rect width="24" height="24" fill="white"/>
			</clipPath>
		</defs>
	</svg>
  );
};

export default ThumbsUp;

const Go = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '65'} height={props.height || '65'} viewBox="0 0 65 65" fill="none">
	  <circle cx="32.5" cy="32.5" r="32.5" fill={props.fill || 'black'} />
	  <path d="M41.7823 23.2197H31.4708M41.7823 23.2197L41.7817 33.5317M41.7823 23.2197L23.2207 41.7813" stroke={props.stroke || 'white'} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>
  );
};

export default Go;

import styles from "./BookExperienceForm.module.css";
import React, { useEffect, useState, } from "react";
import { LoginHelper, Helper, } from "../components";
import { Plus, Minus, } from "../icons";


const BookExperienceForm = (props) => {
	const [adultsCount, setAdultsCount] = useState(1);
	const user = LoginHelper.getUser() || {};
	let [formState, setFormState] = useState({
		mobile: user.mobile ? user.mobile.toString() : '',
		name: user.name,
		email: user.email,
		comingFrom: '',
		gender: user.gender,
		count: 1,
	});
	let [formErrorState, setFormErrorState] = useState({
		mobile: false,
		name: false,
		email: false,
	});
	const [submittingForm, setSubmittingForm] = useState(false);
  
  	const onChangeInput = (e, type, val) => {
  		e.preventDefault();

  		if (type === 'phone') {
  			formErrorState['mobile'] = false;
  			setFormErrorState({...formErrorState});
		    if (e.target.value.length < 11) {
		    	formState['mobile'] = e.target.value;
		    	setFormState({...formState});
		    }
  		}

  		if (type === 'name') {
  			formErrorState = formErrorState || {};
  			formErrorState.name = false;
  			setFormErrorState({...formErrorState});
  			const result = e.target.value.replace(/[^a-z ]/gi, '');
    		formState = formState || {};
  			formState.name = result;
  			setFormState({...formState});
  		}

  		if (type === 'email') {
  			formErrorState = formErrorState || {};
  			formErrorState.email = false;
  			setFormErrorState({...formErrorState});
    		formState = formState || {};
	  		formState.email = e.target.value;
	  		setFormState({...formState});
  		}

  		if (type === 'gender') {
    		formState = formState || {};
	  		formState.gender = val;
	  		setFormState({...formState});
  		}

  		if (type === 'comingFrom') {
    		formState = formState || {};
	  		formState.comingFrom = e.target.value;
	  		setFormState({...formState});
  		}
  	};

  	const getAnalyticsCategory = () => {
		try {
	      return `BookExperience_${props.experience.title.split(' ').join('_')}`;
	    } catch(err) {
	      return 'BookExperience';
	    }
	};

  	const trackAnalytics = (action, label) => {
		Helper.trackAnalytics(getAnalyticsCategory(), action, label);
	};

  	const decreaseCount = (e) => {
		if (formState.count !== 1) {
			formState = formState || {};
	  		formState.count = formState.count - 1;
	  		setFormState({...formState});
		}
		trackAnalytics('Click_Decrease_count', formState.count);
  	};

  	const increaseCount = (e) => {
		formState = formState || {};
  		formState.count = formState.count + 1;
  		setFormState({...formState});
		trackAnalytics('Click_Increase_count', formState.count);
  	};

  	const handleSubmit = async (event) => {
	    event.preventDefault();
		formErrorState = formErrorState || {};
	    if (!formState.name) formErrorState.name = true;
	    if (!formState.email) formErrorState.email = true;
	    if (formState.mobile.length != 10) formErrorState.mobile = true;
	    setFormErrorState({...formErrorState});

	    if (!submittingForm && formState.mobile && formState.email && formState.name && !formErrorState.mobile && !formErrorState.name && !formErrorState.email) {
	      Helper.trackAnalytics(getAnalyticsCategory(), 'Click_Submit_details', formState.mobile);
	      setSubmittingForm(true);
	      await fetch(`${global.config.SERVER_URL}/api/v1/leads`, {
	        method: 'POST',
	        body: JSON.stringify({
	           	phone: formState.mobile,
	           	name: formState.name,
	           	email: formState.email,
	           	comingFrom: formState.comingFrom,
			   	gender: formState.gender,
		    	count: 1,
	          	type: 'JoinExperience',
	          	experienceId: props.experience._id,
	          	brandId: props.experience.brand._id
	        }),
	        headers: {
	           'Content-type': 'application/json; charset=UTF-8',
	        },
	        })
	        .then((response) => response.json())
	        .then((data) => {
	          	setSubmittingForm(false)
	           	if (data.status == 'success') {
	           		window.open(`https://wa.me/+919972136020/?text=Hi, I want to know more about ${window.location.href}`);
	           	}
	        })
	        .catch((err) => {
	          	setSubmittingForm(false)
	          	console.log(err.message);
	        });
	    }
  	};

  	return (
      	<form>
      		<div className={styles.memberForm}>
			  	<h4>Tell us more about yourself</h4>

			  	<div>
			    	<input className={formErrorState.name ? styles.invalid : styles.valid} type="text" placeholder="Name *" value={formState.name || ''} onChange={e => onChangeInput(e, 'name')} />
			  	</div>

			  	<div>
			    	<input className={formErrorState.mobile ? styles.invalid : styles.valid} type="number" placeholder="Phone No. (+91) *" value={formState.mobile} onChange={e => onChangeInput(e, 'phone')} />
			  	</div>

			  	<div>
			    	<input className={formErrorState.email ? styles.invalid : styles.valid} type="email" placeholder="Email *" value={formState.email || ''} onChange={e => onChangeInput(e, 'email')} />
			  	</div>

			  	<div className={styles.genderInput}>
			    	<span className={styles.genderHeading}>Gender</span>
			    	<label className={formState.gender === 'male' ? styles.selected : ''} onClick={e => onChangeInput(e, 'gender', 'male')}><img src={formState.gender === 'male' ? '/radio-selected.svg' : '/radio-unselected.svg'} />Male</label>
			    	<label className={formState.gender === 'female' ? styles.selected : ''} onClick={e => onChangeInput(e, 'gender', 'female')}><img src={formState.gender === 'female' ? '/radio-selected.svg' : '/radio-unselected.svg'} />Female</label>
			    	<label className={formState.gender === 'other' ? styles.selected : ''} onClick={e => onChangeInput(e, 'gender', 'other')}><img src={formState.gender === 'other' ? '/radio-selected.svg' : '/radio-unselected.svg'} />Other</label>
			  	</div>

			  	<div>
			    	<input className={styles.valid} type="text" placeholder="Where will you be coming from?" value={formState.comingFrom || ''} onChange={e => onChangeInput(e, 'comingFrom')} />
			  	</div>

			  	<div>
			  		<label className={styles.countMsg}>How many people will be joining?</label>
			  		<div className={styles.counts}>
						<i className={styles.minus} onClick={e => decreaseCount(e)}>
							<Minus />
						</i>
						<span>{formState.count < 10 ? '0' + formState.count : formState.count}</span>
						<i className={styles.plus} onClick={e => increaseCount(e)}>
							<Plus />
						</i>
					</div>
				</div>
			</div>
		  
		  	<button className={styles.joinBtn} onClick={(e) => { e.stopPropagation(); trackAnalytics('Click_Join_Btn', formState.mobile); handleSubmit(e);}}>
		  		<img src="/wa.png" />
		  		Click here to join
		  	</button>
		</form>
  	);  
}

export default BookExperienceForm;

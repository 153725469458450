import styles from "./BookExperience.module.css";
import { Back, Helper, LoginHelper, } from "../components";
import { BackArrow, Plus, Minus, } from "../icons";
import { Oval } from  'react-loader-spinner';
import { useParams, useNavigate, } from "react-router-dom";
import React, { useEffect, useState, useRef, } from "react";


const BookExperience = () => {
	const navigate = useNavigate();
	const { slug } = useParams();
	const { height, width } = Helper.getWindowDimensions();
	const [experience, setExperience] = useState({});
	const [adultsCount, setAdultsCount] = useState(1);
	const [minAdultsCount, setMinAdultsCount] = useState(1);
	const [maxAdultsCount, setMaxAdultsCount] = useState(1);
	const [selectedRecurrence, setSelectedRecurrence] = useState({});
	const [childrenCount, setChildrenCount] = useState(0);
	const [invalidExperienceId, setInvalidExperienceId] = useState(false);
	const [currentStep, setCurrentStep] = useState(1);
	const [step1Disabled, setStep1Disabled] = useState(true);
	const [step2Disabled, setStep2Disabled] = useState(true);
	const [resData, setResData] = useState({});
	const packageInfoRef = useRef(null);
	const depositAmountRef = useRef(null);
	const [depositAmountWidth, setDepositAmountWidth] = useState('auto');
	const [packageInfoHeight, setPackageInfoHeight] = useState('auto');
	const user = LoginHelper.getUser() || {};
	const [formState, setFormState] = useState({userPhone: user.mobile ? user.mobile.toString() : '', 0: {
		name: user.name,
		age: user.age,
		gender: user.gender,
	}});
	const [formErrorState, setFormErrorState] = useState({userPhone: false,});
	const [submittingForm, setSubmittingForm] = useState(false);
	const [selectedPackages, setSelectedPackages] = useState({});
	const tokenAmountPercentage = 0.10;


	useEffect(() => {
      if (packageInfoRef && packageInfoRef.current && packageInfoHeight === 'auto') {
        let height = 0;
        for (let c of packageInfoRef.current.children) {
          if (height < c.clientHeight) height = c.clientHeight;
        }  
        setPackageInfoHeight(height);
      }

      if (depositAmountRef && depositAmountRef.current && depositAmountWidth === 'auto') {
        setDepositAmountWidth(depositAmountRef.current.clientWidth);
      }
    });

	const getAnalyticsCategory = () => {
		try {
	      return `BookExperience_${experience.title.split(' ').join('_')}`;
	    } catch(err) {
	      return 'BookExperience';
	    }
	};

	const trackAnalytics = (action, label) => {
		Helper.trackAnalytics(getAnalyticsCategory(), action, label);
	};

	const routeChange = (e, url) => {
		trackAnalytics(`Click_${Helper.titleCase(url.split('/')[url.split('/').length - 1].replaceAll('-', '_'))}`, 'Navigate');
    	navigate(url);
  	};

	const togglePackage = (e, packageId) => {
		e.stopPropagation();
		if (packageId in selectedPackages) {
			delete selectedPackages[packageId];
			trackAnalytics('Click_Deselect_package', packageId);
		} else {
			selectedPackages[packageId] = (selectedPackages[packageId] || 0) + 1;
			trackAnalytics('Click_Select_package', packageId + '_' + selectedPackages[packageId]);
		}
		setSelectedPackages({...selectedPackages});
		checkStep1Disabled(selectedRecurrence, selectedPackages);
		calculateAdultsCount(selectedPackages);
	};

	const checkStep1Disabled = (sr, sp) => {
		if (Object.keys(sr).length !== 0 && Object.keys(sp).length !== 0) setStep1Disabled(false);
  		else setStep1Disabled(true);
	};

	const selectPackage = (e, packageId) => {
		e.stopPropagation();
		if (packageId in selectedPackages) {
			selectedPackages[packageId] += 1;
		} else {
			selectedPackages[packageId] = 1;
		}
		setSelectedPackages({...selectedPackages});
		checkStep1Disabled(selectedRecurrence, selectedPackages);
		calculateAdultsCount(selectedPackages);
		trackAnalytics('Click_Select_package', packageId + '_' + selectedPackages[packageId]);
	};

	const deselectPackage = (e, packageId) => {
		e.stopPropagation();
		if (packageId in selectedPackages) {
			selectedPackages[packageId] -= 1;
			
			if (selectedPackages[packageId] === 0) 
				delete selectedPackages[packageId]

			setSelectedPackages({...selectedPackages});
			checkStep1Disabled(selectedRecurrence, selectedPackages);
			calculateAdultsCount(selectedPackages);
			trackAnalytics('Click_Deselect_package', packageId);
		}
	};

	const calculateAdultsCount = (sp) => {
		const minGuests = Object.values(sp).reduce((a, b) => a + b, 0);
		setMinAdultsCount(minGuests);
		setAdultsCount(minGuests);
		let maxGuests = 0;

		const packs = experience.packages.filter(x => x['_id'] in sp);
		for (let i = 0; i < packs.length; i++) {
  			maxGuests += packs[i].maxGuests * sp[packs[i]['_id']];
		}
		setMaxAdultsCount(maxGuests);
	};

  	const decreaseCount = (e, type) => {
  		if (type === 'adults') {
  			if (adultsCount - 1 >= minAdultsCount) setAdultsCount(adultsCount - 1);
  			trackAnalytics('Click_Decrease_adults_count', adultsCount - 1);
  		} else {
  			if (childrenCount !== 0) setChildrenCount(childrenCount - 1);
  			trackAnalytics('Click_Decrease_children_count', childrenCount - 1);
  		}
  	};

  	const increaseCount = (e, type) => {
  		if (type === 'adults') {
  			if (adultsCount + 1 <= maxAdultsCount) setAdultsCount(adultsCount + 1);
  			trackAnalytics('Click_Increase_adults_count', adultsCount + 1);
  		} else {
  			setChildrenCount(childrenCount + 1);
  			trackAnalytics('Click_Increase_children_count', childrenCount + 1);
  		}
  	};

  	const selectRecurrence = (e, recurrence, index) => {
  		setSelectedRecurrence({...recurrence, index});
  		trackAnalytics('Click_Select_Date', Helper.formatDate(new Date(recurrence.dates.start)));
  		checkStep1Disabled(recurrence, selectedPackages);
  	};

  	const moveToNextStep = (event) => {
  		event.preventDefault();
  		setCurrentStep(currentStep + 1);
  		document.body.scrollTo(0, 0);
  	};

	const moveToPrevStep = (event) => {
  		event.preventDefault();
  		setCurrentStep(currentStep - 1);
		document.body.scrollTo(0, 0);
  	};

  	const validateMemberDetails = () => {
  		let isValid = true;

  		for (let i = 0; i < adultsCount; i++) {
  			if (!formState[i]) isValid = false;
  			else {
  				if (!formState[i].name) isValid = false;
  				if (!formState[i].age || formState[i].age < 5) isValid = false;
  			}
  		}

  		if (isValid && formState.userPhone.length !== 10) isValid = false;
  		setStep2Disabled(!isValid);
  	};

  	const onChangeInput = (e, type, i, val) => {
  		e.preventDefault();

  		if (type === 'phone') {
  			formErrorState['userPhone'] = false;
  			setFormErrorState({...formErrorState});
		    if (e.target.value.length < 11) {
		    	formState['userPhone'] = e.target.value;
		    	setFormState({...formState});
		    }
  		}

  		if (type === 'name') {
  			formErrorState[i] = formErrorState[i] || {};
  			formErrorState[i].name = false;
  			setFormErrorState({...formErrorState});
  			const result = e.target.value.replace(/[^a-z ]/gi, '');
    		formState[i] = formState[i] || {};
  			formState[i].name = result;
  			setFormState({...formState});
  		}

  		if (type === 'age') {
  			formErrorState[i] = formErrorState[i] || {};
  			formErrorState[i].age = false;
  			setFormErrorState({...formErrorState});
    		formState[i] = formState[i] || {};
    		if (e.target.value.length < 3)
	  			formState[i].age = Number(e.target.value);
	  			setFormState({...formState});
  		}

  		if (type === 'gender') {
    		formState[i] = formState[i] || {};
	  		formState[i].gender = val;
	  		setFormState({...formState});
  		}

  		validateMemberDetails();
  	};

  	const reserveParticipation = async (e) => {
  		e.preventDefault();

  		const guests = [];
  		for (let i = 0; i < adultsCount; i++) {
  			guests.push({
  				name: formState[i].name,
  				age: formState[i].age,
  				mobile: formState.userPhone,
  				gender: formState[i].gender,
  			});
  		}

	    if (!submittingForm) {
	      	setSubmittingForm(true);
	      	await fetch(`${global.config.SERVER_URL}/api/v1/bookings`, {
	        	method: 'POST',
	        	body: JSON.stringify({
		          	experience: experience['_id'],
		          	recurrence: selectedRecurrence['_id'],
		          	packages: selectedPackages,
		          	price: getTotalAmount(),
		          	tokenAmount: getDepositAmount(),
		          	children: childrenCount,
		          	guests,
	        	}),
	        	headers: {
	           		'Content-type': 'application/json; charset=UTF-8',
	           		...Helper.getCommonHeaders(),
	        	},
	        })
	        .then((response) => response.json())
	        .then((data) => {
	           	if (data.status == 'success') {
	           		setResData(data);
	           		const razorPayOptions = getRazorPayOptions(data);
	           		const rzp = new Razorpay(razorPayOptions);
    				rzp.open();
	           	}
	           	setTimeout(
				  () => {
				  	setSubmittingForm(false);
				  	setCurrentStep(currentStep + 1);
  					document.body.scrollTo(0, 0);
				  }, 
				  2000
				);
	        })
	        .catch((err) => {
	          	setSubmittingForm(false)
	          	console.log(err.message);
	        });
	    }
  	};

  	const payTokenAmount = (e) => {
  		e.preventDefault();
  		const razorPayOptions = getRazorPayOptions(resData);
   		const rzp = new Razorpay(razorPayOptions);
		rzp.open();
  	};

  	const getRazorPayOptions = (data) => {
  		return {
		    "key": global.config.RAZOR_PAY_KEY,
		    "amount": data.bookingRes.tokenAmount * 100,
		    "currency": "INR",
		    "name": "XpHive",
		    "description": experience.title,
		    "image": "https://xphive-prod.s3.ap-south-1.amazonaws.com/1d018d5451db3929e64cad2253b3b805.jpeg",
		    "order_id": data.pgRes.id,
		    "callback_url": `${global.config.SERVER_URL}/api/v1/payments/success`,
		    "prefill": {
		        "name": formState[0].name,
		        "email": "",
		        "contact": formState.userPhone
		    },
		    "notes": {
		        "bookingId": data.bookingRes._id
		    },
		    "theme": {
		        "color": "#3399cc"
		    }
		};
  	};

  	const getMembersCard = () => {
  		const cards = [];
  		for (let i = 0; i < adultsCount; i++) {
			cards.push(<li key={i} className={styles.memberDetail}>
				<form className={styles.memberForm}>
					<h4>Member {i + 1} {i === 0 ? '(You)' : ''}</h4>

					<div>
	                  <input className={formErrorState[i] && formErrorState[i].name ? styles.invalid : styles.valid} type="text" placeholder="Name *" value={formState[i] && formState[i].name || ''} onChange={e => onChangeInput(e, 'name', i)} />
	                </div>

	                <div>
		                <input className={formErrorState[i] && formErrorState[i].age ? styles.invalid : styles.valid} type="number" placeholder="Age (Above 4 Years) *" value={formState[i] && formState[i].age || ''} onChange={e => onChangeInput(e, 'age', i)} />
		            </div>

			        <div className={styles.genderInput}>
			        	<span className={styles.genderHeading}>Gender</span>
			        	<label className={(formState[i] || {}).gender === 'male' ? styles.selected : ''} onClick={e => onChangeInput(e, 'gender', i, 'male')}><img src={(formState[i] || {}).gender === 'male' ? '/radio-selected.svg' : '/radio-unselected.svg'} />Male</label>
			        	<label className={(formState[i] || {}).gender === 'female' ? styles.selected : ''} onClick={e => onChangeInput(e, 'gender', i, 'female')}><img src={(formState[i] || {}).gender === 'female' ? '/radio-selected.svg' : '/radio-unselected.svg'} />Female</label>
			        	<label className={(formState[i] || {}).gender === 'other' ? styles.selected : ''} onClick={e => onChangeInput(e, 'gender', i, 'other')}><img src={(formState[i] || {}).gender === 'other' ? '/radio-selected.svg' : '/radio-unselected.svg'} />Other</label>
			        </div>

			        {i === 0 && <div>
			          <input className={formErrorState.userPhone ? styles.invalid : styles.valid} type="number" placeholder="Phone No. (+91) *" value={formState.userPhone} onChange={e => onChangeInput(e, 'phone', i)} />
			        </div>}
		        </form>
			</li>)
		}

		return cards.slice();
  	}
 
	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/experiences?slug=${slug}`)
	      	.then((response) => response.json())
	      	.then((res) => {
	        	if (res.data) setExperience(res.data);
	        	else setInvalidExperienceId(true);
	     	})
	     	.catch((err) => {
	     		setInvalidExperienceId(true);
	        	console.log(err.message);
	     	});
	}, {});

	const getTotalAmount = () => {
		if (Object.keys(selectedPackages).length !== 0) {
			let amount = 0;
			for (const packageId in selectedPackages) {
			    const count = selectedPackages[packageId];
			    amount = amount + (count * experience.packages.filter(x => x['_id'] === packageId)[0].price);
			}

			return amount;
		} else {
			return Math.min(...experience.packages.map(item => item.price));
		}
	};

	const getDepositAmount = () => {
		return getTotalAmount() * tokenAmountPercentage;
	};

	const getPackagePrice = (packageId) => {
		return experience.packages.filter(x => x['_id'] === packageId)[0].price;
	};

  	return(
   		<div className={styles.bookExperienceContainer}>
   			{invalidExperienceId && 
		        <h1 style={{ textAlign: 'center', margin: '80px 50px'}}>Apologies! XPHive does not have this experience listed.</h1>
		    }

		    {(!invalidExperienceId && Object.keys(experience).length == 0) || submittingForm ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		    /> : '' }

		    { Object.keys(experience).length != 0 ?
		        <div>
		        	{currentStep === 1 && <div>
		        		<i className={styles.backIcon}><Back style={{position: 'absolute', left: '23px', top: '25px'}} analyticsCategory={getAnalyticsCategory()} /></i>
          				<h1 className={styles.pageHeading}>Slots & Pricing</h1>

		        		<div className={styles.topSection}>
		        			<img className={styles.experienceImg} src={experience.images[0]} />
		        			<h2>{experience.title}</h2>
		        			<p>{experience.subtitle}</p>
		        		</div>

			            {experience.characteristics && <div className={styles.characteristics}>
			              	<h3 style={{marginBottom: '10px'}} className={styles.sectionHeading}>What do you get</h3>
			              	<div>
			                  	<h4>Pricing Includes</h4>
			                  	<ul className={styles.characteristiclist}>
			                    	{experience.characteristics.inclusion.data.map((item, i) => {
			                        	return (
			                          		<li key={i}>{item}</li>
			                        	);
			                      	})}
			                  	</ul>
			                </div>

			                <div>
			                  	<h4>Pricing Excludes</h4>
			                  	<ul className={styles.characteristiclist}>
			                    	{experience.characteristics.exclusion.data.map((item, i) => {
			                        	return (
			                          		<li key={i}>{item}</li>
			                        	);
			                      	})}
			                  	</ul>
			                </div>
			            </div>}

			            {experience.packages && experience.packages.length > 0 && <div className={styles.packages}>
			              	<h3 style={{marginBottom: '10px'}} className={styles.sectionHeading}>Pricing Options</h3>
			              	{experience.packages.map((pack, i) => {
			              		return(
			              			<div onClick={e => togglePackage(e, pack['_id'])} className={styles.packageWrap} ref={packageInfoRef} className={`${styles.packageWrap} ${(selectedPackages[pack['_id']] || 0) > 0 ? styles.selectedpackageWrap : ''}`}>
			              				<div className={styles.packageInfo} style={{height: packageInfoHeight + 30}}>
				              				<h5>{pack.title}</h5>
				              				<label>₹{pack.price.toLocaleString()}/-</label>
				              				<p>{pack.description}</p>

				              				<div className={styles.counts} style={{bottom: (selectedPackages[pack['_id']] || 0) > 0 ? '11px' : '10px'}}>
												<i className={(selectedPackages[pack['_id']] || 0) === 0 ? styles.disabledMinus : styles.minus} onClick={e => deselectPackage(e, pack['_id'])}>
													<Minus />
												</i>
												<span>{(selectedPackages[pack['_id']] || 0) < 10 ? '0' + (selectedPackages[pack['_id']] || 0) : selectedPackages[pack['_id']]}</span>
												<i className={styles.plus} onClick={e => selectPackage(e, pack['_id'])}>
													<Plus />
												</i>
											</div>
										</div>
			              				<div className={styles.packageImgWrap} style={{backgroundImage: `url(${pack.image})`, height: packageInfoHeight + 52}}></div>
			              			</div>
			              		)
			              	})}
			            </div>}

			            {experience.recurrences && experience.recurrences.length > 0 && <div className={styles.recurrences}>
			        		<h3 style={{marginBottom: '10px', marginTop: '30px'}} className={styles.sectionHeading}>Available Slots</h3>
			        		<ul className={styles.recurrencesList}>
				        		{experience.recurrences.map((recurrence, i) => {
				        			return(<li key={i} className={recurrence['_id'] === selectedRecurrence['_id'] ? styles.selectedRecurrence : ''} onClick={e => selectRecurrence(e, recurrence, i)}>
				        				<span>{`${new Date(recurrence.dates.start).getDate()} ${new Date(recurrence.dates.start).toLocaleString('default', { month: 'short' })}  - ${new Date(recurrence.dates.end).getDate()} ${new Date(recurrence.dates.end).toLocaleString('default', { month: 'short' })}`}</span>
				        				<label>Slot {i + 1}</label>
				        			</li>)
				        		})}
			        		</ul>
			        	</div>}

			        	{experience.packages && experience.packages.length > 0 && experience.recurrences && experience.recurrences.length > 0 ? <div className={styles.depositMsg}>
			        		<label style={{width: depositAmountWidth - 20}} ref={depositAmountRef}>₹{getDepositAmount().toLocaleString()}/-</label>
			        		<p style={{width: width - 35 - depositAmountWidth}}>Reserve your spot with a small deposit<span>Pay the rest before the experience</span></p>
			        	</div>: <div style={{paddingBottom: '110px'}}></div>}

			        	<button disabled={step1Disabled} className={styles.nextBtn} onClick={(e) => { e.stopPropagation(); trackAnalytics('Click_Book_Now', `${Helper.formatDate(new Date(selectedRecurrence.dates.start))}`); moveToNextStep(e);}}>Book Now</button>
						<button className={styles.callBack} onClick={e => routeChange(e, `/get-in-touch?experienceId=${experience['_id']}`)}>Call Back</button>
			        </div>}

			        {currentStep === 2 && <div className={styles.bookingPage}>
			        	<i onClick={(e) => { trackAnalytics('Click_Back_from_booking_page', 'Booking_Page'); moveToPrevStep(e); }} className={styles.backIcon}><span style={{position: 'absolute', left: '11px', top: '11px'}}><BackArrow /></span></i>
			        	
          				<h1 className={styles.pageHeading}>Booking Page</h1>

          				<div>
          					<h3 style={{marginTop: '10px'}} className={styles.sectionHeading}>Your Selections</h3>

          					{experience.packages.filter(x => x['_id'] in selectedPackages).map((pack, i) => {
			              		return(
			              			<div className={styles.packageWrap} ref={packageInfoRef} className={`${styles.packageWrap} ${styles.selectedpackageWrap}`}>
			              				<div className={styles.packageInfo} style={{height: packageInfoHeight + 30}}>
				              				<h5>{pack.title}</h5>
				              				<label>₹{pack.price.toLocaleString()}/-</label>
				              				<p>{pack.description}</p>

				              				<div className={styles.counts} style={{bottom: (selectedPackages[pack['_id']] || 0) > 0 ? '11px' : '10px'}}>
												<span>{'x ' + selectedPackages[pack['_id']]}</span>
											</div>
										</div>
			              				<div className={styles.packageImgWrap} style={{backgroundImage: `url(${pack.image})`, height: packageInfoHeight + 52}}></div>
			              			</div>
			              		)
			              	})}

          					<ul className={styles.recurrencesList}>
	          					<li className={styles.selectedRecurrence}>
			        				<span>{`${new Date(selectedRecurrence.dates.start).getDate()} ${new Date(selectedRecurrence.dates.start).toLocaleString('default', { month: 'short' })}  - ${new Date(selectedRecurrence.dates.end).getDate()} ${new Date(selectedRecurrence.dates.end).toLocaleString('default', { month: 'short' })}`}</span>
			        				<label>Slot {selectedRecurrence.index + 1}</label>
			        			</li>
		        			</ul>
          				</div>

          				<h2>Confirm your participation by providing below information</h2>
	        			<div>
	        				<h3 style={{marginBottom: '10px'}} className={styles.sectionHeading}>How many people?</h3>

	        				<div className={styles.guestsInput}>
								<div className={styles.adults}>
									<label>Adults</label>
									<div className={styles.adultsCounts}>
										<i className={`${styles.minusBtn} ${adultsCount <= minAdultsCount ? styles.disabledMinusBtn : ''}`} onClick={e => decreaseCount(e, 'adults')}>
											<Minus />
										</i>
										<span>{adultsCount < 10 ? '0' + adultsCount : adultsCount}</span>
										<i className={`${styles.plusBtn} ${adultsCount >= maxAdultsCount ? styles.disabledPlusBtn : ''}`} onClick={e => increaseCount(e, 'adults')}>
											<Plus />
										</i>
									</div>
								</div>
								<div className={styles.children}>
									<label>Children</label>
									<div className={styles.childrenCounts}>
										<i className={`${styles.minusBtn} ${childrenCount === 0 ? styles.disabledMinusBtn : ''}`} onClick={e => decreaseCount(e, 'children')}>
											<Minus />
										</i>
										<span>{childrenCount < 10 ? '0' + childrenCount : childrenCount}</span>
										<i className={styles.plusBtn} onClick={e => increaseCount(e, 'children')}>
											<Plus />
										</i>
									</div>
								</div>
				        	</div>
	        			</div>
			        	
			        	<ul className={styles.membersList}>
			        		<h3 style={{marginTop: '24px'}} className={styles.sectionHeading}>Please fill the details</h3>
			        		{getMembersCard()}
			        	</ul>

			        	<div className={styles.membersList}>
			        		<h3 style={{marginTop: '24px'}} className={styles.sectionHeading}>Price Summary</h3>
			        		
			        		<div className={styles.priceDetails}>
				        		<ul>
				        			<li>
				        				Token Amount to be paid
				        				{Object.keys(selectedPackages).map((packageId) => {
				        					return(
				        						<p>{`₹${(getPackagePrice(packageId) * tokenAmountPercentage).toLocaleString()}`} x {selectedPackages[packageId]}<span>{`₹${(getPackagePrice(packageId) * tokenAmountPercentage * selectedPackages[packageId]).toLocaleString()}`}</span></p>
				        					);
				        				})}
				        				<span className={styles.finalAmount}>{`₹${(getDepositAmount()).toLocaleString()}`}</span>
				        			</li>

				        			<li>
				        				Total Amount
				        				{Object.keys(selectedPackages).map((packageId) => {
				        					return(
				        						<p>{`₹${(getPackagePrice(packageId)).toLocaleString()}`} x {selectedPackages[packageId]}<span>{`₹${(getPackagePrice(packageId) * selectedPackages[packageId]).toLocaleString()}`}</span></p>
				        					);
				        				})}
				        				<span className={styles.finalAmount}>{`₹${(getTotalAmount()).toLocaleString()}`}</span>
				        			</li>

				        			<li  className={styles.priceNote}>
				        				Note- Token amount blocks a slot for your but remaining payment needs to be made before the experience start date
				        			</li>
				        		</ul>
				        	</div>

				        	<p className={styles.agreePolicies}>
				        		By continuing from the current page you agree to the <a href="/terms-policy" target="_blank">Terms & Conditions</a>, <a href="/cancellation-policy" target="_blank">Cancellation Policy</a><br />, <a href="/refund-policy" target="_blank">Refund Policy</a> and XPhive’s Guest Guidlines.
				        	</p>
			        	</div>

			        	<button disabled={step2Disabled} className={styles.paymentBtn} onClick={(e) => { e.stopPropagation(); trackAnalytics('Click_Payment', formState.userPhone); reserveParticipation(e);}}>Continue to Payment</button>
			        </div>}

			    	 {currentStep === 3 && <div>
			    	 	<div className={styles.bookingSuccess}>
				    	 	<h3><span>Thanks</span>We’ve Received Your Booking</h3>
				    	 	<p>Please proceed further with the payment of ₹{getDepositAmount().toLocaleString()}/- as token amount</p>
			    	 		<button className={styles.paymentBtn2} onClick={(e) => { e.stopPropagation(); trackAnalytics('Click_Payment2', formState.userPhone); payTokenAmount(e);}}>Pay ₹{getDepositAmount().toLocaleString()}/-</button>
			    	 	</div>
			    	 </div>}
		        </div>
		    : ''}
   		</div>
  	);
};

export default BookExperience;

const LocationSmallIcon = () => {
  return (
		<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1506_5945)">
				<path d="M5.00004 2.5C4.67041 2.5 4.34817 2.59775 4.07409 2.78088C3.80001 2.96402 3.58639 3.22432 3.46024 3.52886C3.3341 3.8334 3.30109 4.16852 3.3654 4.49182C3.42971 4.81512 3.58844 5.11209 3.82153 5.34518C4.05462 5.57827 4.35159 5.737 4.67489 5.80131C4.99819 5.86562 5.3333 5.83261 5.63785 5.70647C5.94239 5.58032 6.20269 5.3667 6.38582 5.09262C6.56896 4.81853 6.66671 4.4963 6.66671 4.16667C6.66671 3.72464 6.49111 3.30072 6.17855 2.98816C5.86599 2.67559 5.44207 2.5 5.00004 2.5ZM5.00004 5C4.83522 5 4.67411 4.95113 4.53707 4.85956C4.40003 4.76799 4.29321 4.63784 4.23014 4.48557C4.16707 4.3333 4.15057 4.16574 4.18272 4.00409C4.21487 3.84244 4.29424 3.69395 4.41079 3.57741C4.52733 3.46087 4.67582 3.3815 4.83747 3.34935C4.99912 3.31719 5.16667 3.33369 5.31894 3.39677C5.47122 3.45984 5.60137 3.56665 5.69293 3.70369C5.7845 3.84073 5.83338 4.00185 5.83338 4.16667C5.83338 4.38768 5.74558 4.59964 5.5893 4.75592C5.43302 4.9122 5.22106 5 5.00004 5Z" fill="#1C1C1E"/>
				<path d="M4.99995 9.99986C4.64909 10.0017 4.30291 9.91936 3.99039 9.75988C3.67787 9.60039 3.40811 9.36835 3.2037 9.08319C1.61578 6.89277 0.810364 5.24611 0.810364 4.18861C0.810364 3.07746 1.25176 2.01182 2.03746 1.22612C2.82316 0.440425 3.8888 -0.000976562 4.99995 -0.000976562C6.11109 -0.000976562 7.17673 0.440425 7.96243 1.22612C8.74813 2.01182 9.18953 3.07746 9.18953 4.18861C9.18953 5.24611 8.38411 6.89277 6.7962 9.08319C6.59178 9.36835 6.32202 9.60039 6.0095 9.75988C5.69698 9.91936 5.3508 10.0017 4.99995 9.99986ZM4.99995 0.908607C4.13012 0.909599 3.2962 1.25558 2.68114 1.87064C2.06608 2.4857 1.72011 3.31961 1.71911 4.18944C1.71911 5.02694 2.50786 6.57569 3.93953 8.55027C4.06107 8.71768 4.22052 8.85393 4.40483 8.94788C4.58914 9.04184 4.79307 9.09082 4.99995 9.09082C5.20682 9.09082 5.41076 9.04184 5.59507 8.94788C5.77938 8.85393 5.93882 8.71768 6.06036 8.55027C7.49203 6.57569 8.28078 5.02694 8.28078 4.18944C8.27979 3.31961 7.93381 2.4857 7.31875 1.87064C6.70369 1.25558 5.86977 0.909599 4.99995 0.908607Z" fill="#1C1C1E"/>
			</g>
			<defs>
				<clipPath id="clip0_1506_5945">
					<rect width="10" height="10" fill="white"/>
				</clipPath>
			</defs>
		</svg>
  	);
};

export default LocationSmallIcon;

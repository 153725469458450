import styles from "./DestinationDetails.module.css";
import parse from 'html-react-parser';
import { Back, Favourite, Helper, Share, ContactUs, Blogs, BottomBar } from "../components";
import { } from "../icons";
import { Oval } from  'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import React, { useEffect, useState, } from "react";


const DestinationDetails = () => {
	const navigate = useNavigate();
	const { city } = useParams();
	const [destination, setDestination] = useState({});
	const [carousels, setCarousels] = useState({});
	const [invalidDestination, setInvalidDestination] = useState(false);

	const getAnalyticsCategory = () => {
		try {
	      return `DestinationDetails_${destination.location.city}`;
	    } catch(err) {
	      return 'DestinationDetails';
	    }
	};

	const routeChange = (e, url) => {
		Helper.trackAnalytics(getAnalyticsCategory(), `Click_${Helper.titleCase(url.split('/')[url.split('/').length - 1].replaceAll('-', '_'))}`, `Navigate`);
    	navigate(url);
  	}

	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/destinations/${city}`)
	      	.then((response) => response.json())
	      	.then((res) => {
	        	if (res.data) {
	        		setDestination(res.data);
	        	} else setInvalidDestination(true);
	     	})
	     	.catch((err) => {
	        	console.log(err.message);
	     	});

	    fetch(`${global.config.SERVER_URL}/api/v1/carousels?page=destination`)
	      	.then((response) => response.json())
	      	.then((res) => {
	        	if (res.data) setCarousels(res.data);
	     	})
	     	.catch((err) => {
	        	console.log(err.message);
	     	});
	}, {});

	const getTags = () => {
		const tags = [];
		destination.vibes.map((vibe) => {
			vibe.tags.map(tag => tags.push(tag));
		});

		return tags.map((tag, i) => {
			return(<span key={i}>{tag}</span>);
		});
	};

  	return(
   		<div className={styles.destination}>
   			<Back whiteIcon={true} analyticsCategory={getAnalyticsCategory()} />

   			{invalidDestination && 
		        <h1 style={{ textAlign: 'center', margin: '80px 50px'}}>Apologies! XPHive does not have this destination listed.</h1>
		    }

		    {!invalidDestination && Object.keys(destination).length == 0 ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		    /> : '' }

		    { Object.keys(destination).length != 0 ?
		        <div>
		        	<div className={styles.topContainer}>
		        		<img src={destination.bannerImages[0]} />
		        		<div className={styles.locationWrap}>
                        	<p className={styles.location}>{destination.location.city},<br/>{destination.location.state}</p>
                      	</div>
                      	<i className={styles.shareIcon}>
                          <Share analyticsCategory={getAnalyticsCategory()} blackIcon={true} hideCircle={true} style={{position: 'absolute', right: '22px', top: '22px', zIndex: 1}}/>
                        </i>
                        <i className={styles.favouriteIcon}>
                          <Favourite analyticsCategory={getAnalyticsCategory()} id={destination._id} circleWidth={'40'} circleHeight={'40'} storage={'destination'} style={{ position: 'absolute', top: '16px', right: '17px', bottom: 'unset', left: 'unset', zIndex: 1 }} />
                        </i>
		        	</div>

		        	<div className={styles.details}>
		        		<h4>Vibe:</h4>
		        		<div className={styles.tags}>
			        		{getTags()}
		        		</div>

		        		<h3 className={styles.title}>{destination.title}</h3>
		        		<p className={styles.description}>{destination.description}</p>
		        	</div>

		        	<div className={styles.promotionCarousel} style={{ backgroundImage: `url("/destination-ellipse-1.svg")` }}>
			            <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={20} autoPlay={false} showArrows={false} infiniteLoop={true} stopOnHover={false} showThumbs={false} showStatus={false} renderIndicator={false}>
			              {carousels.map((carousel, i) => {
			                return (
			                  <div key={i} className={styles.promoCarousel} onClick={e => routeChange(e, carousel.href)}>
			                    <div className={styles.headingAndTitle}>
			                      <h3>{parse(carousel.heading)}</h3>
			                      {carousel.cta && <button>{carousel.cta}</button>}
			                    </div>
			                    <img src={carousel.image} />
			                  </div>
			                );
			              })}
			            </Carousel>
			        </div>

		        	<div className={styles.gallery}>
		        		<img className={styles.image0} src={destination.images[0]} />
		        		<div className={styles.rightImgs}>
		        			<img className={styles.image1} src={destination.images[1]} />
		        			<img className={styles.image2} src={destination.images[2]} />
		        		</div>
		        		<img className={styles.image3} src={destination.images[3]} />
		        	</div>

			        <div className={styles.googleMap} style={{backgroundImage: `url("/destination-ellipse-2.svg")` }} onClick={()=> window.open(destination.location.googleMapLink, "_blank")}>
			   			<h3>Find {destination.location.city} on maps</h3>
			   			<div className={styles.mapImgWrap}><img src={destination.location.googleMapImage} /></div>
		   			</div>

		        	<div className={styles.blogs}><Blogs color={'#000'} analyticsCategory={getAnalyticsCategory()} /></div>
		        	<ContactUs />
		        	<BottomBar analyticsCategory={`BottomBar_DestinationDetails_${destination.location.city}`} />
		        </div>
		    : ''}
   		</div>
  	);
};

export default DestinationDetails;

import Cookies from 'js-cookie';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import { v4 as uuidv4 } from 'uuid';
const Helper = {};


Helper.formatDate = (date, m='short', y=true) => {
	const day = date.getDate();
	const month = date.toLocaleString("default", { month: m });
	const year = date.getFullYear();

	const nthNumber = (number) => {
	  if (number > 3 && number < 21) return "th";
	  switch (number % 10) {
	    case 1:
	      return "st";
	    case 2:
	      return "nd";
	    case 3:
	      return "rd";
	    default:
	      return "th";
	  }
	};

	return `${day}${nthNumber(day)} ${month}${y ? ' ' + year : ''}`;
};

Helper.diffDays = (date1, date2) => {
    const diffTime = date2.getTime() - date1.getTime();
    return (diffTime / (1000 * 3600 * 24) ) + 1;
};

Helper.getReviewDayText = (date) => {
    const diff =  Math.floor(Helper.diffDays(new Date(date), new Date()));

    if (diff == 1) {
        return '1 day ago';
    } else if (diff > 1 && diff < 7) {
        return diff + ' days ago';
    } else if (diff >= 7 && diff < 30) {
        const weekDiff = Math.floor(diff / 7);
        return weekDiff + (weekDiff == 1 ? ' week' : ' weeks') + ' ago';
    } else {
        const monthDiff = Math.floor(diff / 30);
        return monthDiff + (monthDiff == 1 ? ' month' : ' months') + ' ago';
    }

    return diff;
};

Helper.titleCase = (str) => {
    return str.split(' ')
        .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ');
};


const getStorage = () => {
    let storageImpl;
     try { 
        localStorage.setItem("storage", ""); 
        localStorage.removeItem("storage");
        storageImpl = localStorage;
     } catch (err) { 
         storageImpl = new LocalStorageAlternative();
     }
    return storageImpl;
}

function LocalStorageAlternative() {
    var structureLocalStorage = {};

    this.setItem = function (key, value) {
        structureLocalStorage[key] = value;
    }

    this.getItem = function (key) {
        if (typeof structureLocalStorage[key] != 'undefined' ) {
            return structureLocalStorage[key];
        } else {
            return null;
        }
    }

    this.removeItem = function (key) {
        structureLocalStorage[key] = undefined;
    }
}

Helper.storage = getStorage();

Helper.trackAnalytics = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });

    ReactPixel.trackCustom(action, { category, label,});
};

Helper.getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width: width > 912 ? 480 : width,
    height
  };
};

Helper.generateURL = (str) => {
    return str.split(' ').map(item => item.trim()).join('-').toLowerCase();
};

Helper.toggleFavourite = (analyticsCategory='Favourite', storage, fId) => {
    let data = JSON.parse(Helper.storage.getItem(storage)) || [];
    let state = true;
    if (data.includes(fId)) {
      data = data.filter(id => id !== fId);
      state = false;
      Helper.trackAnalytics(analyticsCategory, 'Click_Remove_favourite', fId);
    } else {
      data.push(fId);
      Helper.trackAnalytics(analyticsCategory, 'Click_Add_favourite', fId);
    }

    fetch(`${global.config.SERVER_URL}/api/v1/favourites`, {
        method: 'POST',
        body: JSON.stringify({
            type: storage,
            id: fId,
            state,
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            ...Helper.getCommonHeaders(),
        },
    })
    .then((response) => response.json())
    .then((data) => {})
    .catch((err) => {console.log(err.message);});
    
    Helper.storage.setItem(storage, JSON.stringify(data));
    return data;
};

Helper.getCommonHeaders = () => {
    return {Authorization: `Bearer ${Cookies.get('jwt')}`}
};

Helper.setXPVibes = (xpvibes) => {
    Helper.storage.setItem('xpvibes', JSON.stringify(xpvibes));
};

Helper.getXPVibes = () => {
    return JSON.parse(Helper.storage.getItem('xpvibes'));
};

Helper.removeXPVibes = () => {
    Helper.storage.removeItem('xpvibes');
};

Helper.getDeviceId = () => {
    return Helper.storage.getItem('deviceId');
};

Helper.setDeviceId = () => {
    if (!Helper.getDeviceId()) {
        Helper.storage.setItem('deviceId', uuidv4());    
    }
};

Helper.ToastOptions = {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
};

export default Helper;

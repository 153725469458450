const Check = (props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_4_994)">
			<path d="M5.81189 15.4969C5.30095 15.4971 4.81093 15.294 4.44994 14.9325L0.332296 10.8163C-0.110765 10.3731 -0.110765 9.65471 0.332296 9.2115C0.775498 8.76844 1.49393 8.76844 1.93713 9.2115L5.81189 13.0863L16.0629 2.83529C16.5061 2.39223 17.2245 2.39223 17.6677 2.83529C18.1108 3.27849 18.1108 3.99692 17.6677 4.44012L7.17385 14.9325C6.81286 15.294 6.32284 15.4971 5.81189 15.4969Z" fill={props.color || "#1C1C1E"} />
		</g>
		<defs>
			<clipPath id="clip0_4_994">
				<rect width="18" height="18" fill="white"/>
			</clipPath>
		</defs>
	</svg>
  );
};

export default Check;

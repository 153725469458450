import styles from "./FindYourHive.module.css";
import React, { useState, useEffect, useRef, } from 'react';  
import Helper from './Helper';
import parse from 'html-react-parser';
import { DownArrow, RightBigArrow, CloseBlack, } from '../icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const FindYourHive = (props) => {
  const myContainer = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedVibes, setSelectedVibes] = useState([]);
  const { height, width } = Helper.getWindowDimensions();
  const [currentStep, setCurrentStep] = useState(1);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [formState, setFormState] = useState({
    phone: '',
    name: '',
    email: '',
  });
  const [formErrorState, setFormErrorState] = useState({
    phone: false,
    name: false,
    email: false,
  });
  const [submittingForm, setSubmittingForm] = useState(false);

  setTimeout(
    () => {
      if (isTourOpen) setIsTourOpen(false)
    },
    10000
  );

  const onClickChoice = (e, que, type, val, selectionCap=0) => {
    formState[que] = formState[que] || [];

    if (type === 'choice-single') {
      if (formState[que][0] === val) {
        formState[que][0] = '';
        Helper.trackAnalytics(`FindYourHive`, 'Click_Remove_choice', val);
      } else {
        formState[que][0] = val;
        Helper.trackAnalytics(`FindYourHive`, 'Click_Add_choice', val);
      }
    } else {
      if (formState[que].includes(val)) {
        formState[que] = formState[que].filter(av => av !== val);
        Helper.trackAnalytics(`FindYourHive`, 'Click_Remove_choice', val);
      } else {
        if (selectionCap > 0) {
          if (formState[que].length < selectionCap) {
            formState[que].push(val);
            Helper.trackAnalytics(`FindYourHive`, 'Click_Add_choice', val);
          }
        } else {
          formState[que].push(val);
          Helper.trackAnalytics(`FindYourHive`, 'Click_Add_choice', val);
        }
      }
    }

    setFormState({...formState});
  };

  const questionnaires = [
    {
      "question": "What’s your preferred trip duration?",
      "type": "choice-single",
      "choices": [
        "1-2 days",
        "3-5 days",
        "6+ days",
        "I am flexible"
      ]
    },
    {
      "question": "Where are you currently located?",
      "type": "text",
      "placeholder": "Location",
      "choices": []
    },
    {
      "question": "Select destinations which excite you the most",
      "type": "choice-multi",
      "choices": [
        "Northern Himalayan Adventures - Majestic peaks, serene valleys, and adventure-filled trails.<br/>(Himachal Pradesh, Uttarakhand, Kashmir, Leh-Ladakh)",
        "Eastern Himalayan Treasures - Hidden gems, vibrant cultures, and untouched beauty.<br/>(Sikkim, Meghalaya, Arunachal Pradesh)",
        "Southern Escapes - Lush greenery, charming hill stations, and timeless heritage.<br/>(Coorg, Kodaikanal, Hampi, Ooty)",
        "Coastal Paradises - Sun, sand, sea, and tropical vibes await you.<br/>(Goa, Kerala, Gokarna, Pondicherry)",
        "Desert & Royal Heartlands - Golden dunes, regal palaces, and cultural richness.<br/>(Jaipur, Pushkar, Udaipur, Jaisalmer)",
        "Sacred Plains - Soulful journeys to India’s iconic spiritual havens.<br/>(Varanasi, Rishikesh, Haridwar)"
      ]
    },
    {
      "question": "Do you have any specific dates or a timeframe in mind for this trip? ",
      "type": "choice-multi",
      "placeholder": "Other details",
      "choices": [
        "This Weekend: I’m ready for a spontaneous getaway!",
        "Next 15 Days: Show me some exciting ideas—I’m in!",
        "Mid-December to New Year: Let’s make the end of the year unforgettable.",
        "January 2025: Kickstarting the year with epic adventures!",
        "Next 3-4 Months: Not now, but let’s plan something soon.",
        "I’m Flexible: If it’s exciting, I’m game—no set dates needed."
      ]
    },
    {
      "question": "Describe your travel style in one sentence",
      "type": "textarea",
      "placeholder": "I love exploring offbeat paths and meeting new people.",
      "choices": []
    },
    {
      "question": "Anything else we should know to make this perfect for you?",
      "type": "textarea",
      "placeholder": "I’d love to join a group that hikes to remote temples.",
      "choices": []
    },
  ];

  const onChangeInput = (e, type) => {
    e.preventDefault();

    if (type === 'phone') {
      formErrorState['phone'] = false;
      setFormErrorState({...formErrorState});
      if (e.target.value.length < 11) {
        formState['phone'] = e.target.value;
        setFormState({...formState});
      }
    } else if (type === 'name') {
      formErrorState.name = false;
      setFormErrorState({...formErrorState});
      const result = e.target.value.replace(/[^a-z ]/gi, '');
      formState.name = result;
      setFormState({...formState});
    } else if (type === 'email') {
      formErrorState.email = false;
      setFormErrorState({...formErrorState});
      formState.email = e.target.value;
      setFormState({...formState});
    } else {
      formErrorState[type] = false;
      setFormErrorState({...formErrorState});
      formState[type] = e.target.value;
      setFormState({...formState});
    }
  };

  const getQuestionnaires = () => {
    return questionnaires.map((inputQue, i) => {
      return(
        (inputQue.type === 'choice-single' || inputQue.type === 'choice-multi') ? 
          <div key={i}>
            <label className={styles.label}>{inputQue.question}</label>
            {inputQue.choices.map((choice, j) => {
                return(<span key={j} onClick={e => onClickChoice(e, inputQue.question, inputQue.type, choice)} className={(formState[inputQue.question] || []).includes(choice) ? styles.choiceSelected : styles.choice}>{parse(choice)}</span>)
            })}
          </div>
        : (inputQue.type === 'textarea' ?
          <div key={i}>
            <label className={styles.label}>{inputQue.question}</label>
            <textarea className={formErrorState[inputQue.question] ? styles.invalid : styles.valid} placeholder={inputQue.placeholder} value={formState[inputQue.question]} rows="2" onChange={e => onChangeInput(e, inputQue.question)} />
          </div>
          : <div key={i}>
            <label className={styles.label}>{inputQue.question}</label>
            <input className={formErrorState[inputQue.question] ? styles.invalid : styles.valid} type={inputQue.type} placeholder={inputQue.placeholder} value={formState[inputQue.question]} onChange={e => onChangeInput(e, inputQue.question)} />
          </div>
        )
      )
    });
  };

  const closeTour = (e) => {
    e.stopPropagation();
    setIsTourOpen(false);
  };

  const toggleFindYourHive = () => {
    Helper.trackAnalytics('FindYourHive', `Click_${open ? 'Close' : 'Open'}_FindYourHive`, 'Toggle FindYourHive');
    setOpen(!open);
  };

  const removeEmptyArrays = (obj) => {
    Object.keys(obj).forEach(key => {
        if (Array.isArray(obj[key]) && (obj[key].length === 0 || (obj[key].length === 1 && obj[key][0] === ''))) {
            delete obj[key];
        }
    });
    return obj;
  };

  const moveToNextStep = (event) => {
    event.preventDefault();
    if (myContainer.current) {
      myContainer.current.scrollTo(0, 0);
    }
    setCurrentStep(currentStep + 1);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    if (!formState.name) {
      formErrorState['name'] = true;
      setFormErrorState({...formErrorState});
    }

    if (formState.phone.length != 10) {
      formErrorState['phone'] = true;
      setFormErrorState({...formErrorState});
    }
    
    if (!formState.email) {
      formErrorState['email'] = true;
      setFormErrorState({...formErrorState});
    }

    if (!formState.name || formState.phone.length != 10 || !formState.email) {
      toast.error('Please fill the details', Helper.ToastOptions);
      if (myContainer.current) {
        myContainer.current.scrollTo(0, 0);
      }
    }


    if (!submittingForm && formState.name && formState.phone && formState.email && !formErrorState.name 
      && !formErrorState.phone && !formErrorState.email) {
      Helper.trackAnalytics(`FindYourHive`, 'Click_Submit_details', formState.phone);
      setSubmittingForm(true);
      const details = {...formState, 'Selected Vibes': selectedVibes};
      delete details.name;
      delete details.email;
      delete details.phone;
      removeEmptyArrays(details);

      await fetch(`${global.config.SERVER_URL}/api/v1/leads`, {
        method: 'POST',
        body: JSON.stringify({
          name: formState.name,
          email: formState.email,
          phone: formState.phone,
          details,
          type: 'findYourHive',
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => response.json())
        .then((data) => {
          setSubmittingForm(false);
          if (data.status == 'success') {
            setCurrentStep(currentStep + 1); 
          }
        })
        .catch((err) => {
          setSubmittingForm(false);
          console.log(err.message);
        });
    }
  };

  const images = [
    {
      id: "Hiking is Therapy",
      src: '/fmh/1.png',
    },
    {
      id: "Soaking it all Slowly",
      src: '/fmh/2.png',
    },
    {
      id: "Express Through Art",
      src: '/fmh/3.png',
    },
    {
      id: "Story-telling Nights",
      src: '/fmh/4.png',
    },
    {
      id: "Bikes & Bros",
      src: '/fmh/5.png',
    },
    {
      id: "Connect with Inner Self",
      src: '/fmh/6.png',
    },
    {
      id: "Chasing the Thrill",
      src: '/fmh/7.png',
    },
    {
      id: "Explore Like a Local",
      src: '/fmh/8.png',
    },
    {
      id: "Where Map Dont Reach",
      src: '/fmh/9.png',
    },
    {
      id: "Flavors Lead the Way",
      src: '/fmh/10.png',
    },
    {
      id: "Travel, Wrapped in Comfort",
      src: '/fmh/11.png',
    },
    {
      id: "Sunsets & Sundowners",
      src: '/fmh/12.png',
    },
  ];

  const toggleDayToDayVive = (vibeId) => {
    let currentSelectedVibes = [];
    setSelectedVibes((prevSelected) => {
      if (prevSelected.includes(vibeId)) {
        currentSelectedVibes = prevSelected.filter((id) => id !== vibeId);
      } else {
        currentSelectedVibes = [...prevSelected, vibeId];
      }

      return currentSelectedVibes;
    });
  };

	return (
    <div style={{overflow: 'hidden', textAlign: 'center'}}>
      <div className={styles.searchInput} onClick={toggleFindYourHive}>
          <img src="/ai-search.svg" />
          <span>Find your hive today!</span>
      </div>

      <div ref={myContainer} className={styles.container} style={{bottom: (open ? '0' : '-6000px'), transition: (open ? 'all 0.3s ease' : 'all 1.5s ease')}}>
        <i className={styles.downArrow} onClick={toggleFindYourHive}><DownArrow fill={'#fff'} width={'40'} height={'40'} /></i>
        
        {currentStep === 1 && <div>
          <h3>Pick your travel vibe…</h3>
          <p>Your vibe reflects how you love to travel—whether it’s chasing sunsets, exploring hidden trails, or bonding over bonfires. Tell us yours, and we’ll craft the perfect journey for you.</p>

          <div className={styles.imageGrid}>
            {images.map((image, i) => (
              <div key={image.id} style={{width: ((width - 36) / 2) - 5, height: ((width - 36) / 2) - 5}} className={`${selectedVibes.includes(image.id) ? styles.imageTileSelected : styles.imageTile}`} onClick={() => toggleDayToDayVive(image.id)}>
                
                {i === 1 && isTourOpen && <div className={styles.tourWrap}>
                  <i onClick={(e) => closeTour(e)} className={styles.closeIcon}><CloseBlack /></i>
                  <label>Click on the image to select</label>
                  <div className={styles.leftArrow}></div>
                </div>}

                <img src={image.src} alt={`Image ${image.id}`} />
                {selectedVibes.includes(image.id) && <img className={styles.checkIcon} src="/check-icon-yellow2.png" />}
              </div>
            ))}
          </div>
          {open && <button type="button" className={styles.nextButton} onClick={(e) => { Helper.trackAnalytics('FindYourHive', 'Click_Next', 'Next'); moveToNextStep(e); }}>Next <RightBigArrow /></button>}
        </div>}

        {currentStep === 2 && <div className={styles.formContainer}>
          <h3>Let’s Personalize Your Journey</h3>
          <p>Your personal information is safe with us. We’ll only use it to connect you with travel groups and experiences that perfectly match your vibe.</p>

          <div>
            <form style={{marginTop: '30px', paddingBottom: '60px'}}>
              <div>
                <label className={styles.label}>Your name... *</label>
                <input className={formErrorState.name ? styles.invalid : styles.valid} type="text" placeholder="Name" value={formState.name} onChange={e => onChangeInput(e, 'name')} />
              </div>

              <div>
                <label className={styles.label}>WhatsApp number... *</label>
                <input className={formErrorState.phone ? styles.invalid : styles.valid} type="number" placeholder="+91 -" value={formState.phone} onChange={e => onChangeInput(e, 'phone')} />
              </div>

              <div>
                <label className={styles.label}>Email ID... *</label>
                <input className={formErrorState.email ? styles.invalid : styles.valid} type="email" placeholder="Email" value={formState.email} onChange={e => onChangeInput(e, 'email')} />
              </div>

              {getQuestionnaires()}
            </form>

            <ToastContainer />
          </div>

          {open && <button type="button" className={styles.nextButton} onClick={(e) => { Helper.trackAnalytics('FindYourHive', 'Click_Submit', 'Submit'); submitForm(e); }}>Submit</button>}
        </div>}

        {currentStep === 3 && <div className={styles.formFilled}>
          <h3 style={{fontSize: '32px'}}>Congrats... you are all set!</h3>
          <p style={{fontSize: '20px'}}>We’ve got your vibe! Our team is matching you with the ideal travel groups and experiences. Sit tight—we’ll get back to you soon!⛰️🏖️</p>
        </div>}

      </div>
    </div>
  )
}

export default FindYourHive;  

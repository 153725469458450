import parse from 'html-react-parser';
import styles from "./BrandDetails.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { Helper, Back, Share, Favourite, Experiences, Faq, ContactCreator } from "../components";
import { RatingWhiteIcon, LocationBlackIcon, Rating, RightArrow } from "../icons";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Oval } from  'react-loader-spinner';
import { InstagramEmbed } from 'react-social-media-embed';


const BrandDetails = () => {
	const navigate = useNavigate();
  	const [brand, setBrand] = useState({});
  	const [experience, setExperience] = useState({});
  	const [contactCreatorStorage, setContactCreatorStorage] = useState('contactCreatorLeads');
  	const { brandName } = useParams();
  	const [blogs, setBlogs] = useState([]);
  	const { height, width } = Helper.getWindowDimensions();
  	const [blogScrollbarLeft, setBlogScrollbarLeft] = useState(0);
  	const [invalidBrandName, setInvalidBrandName] = useState(false);
  	const [openContactCreator, setOpenContactCreator] = useState(false);

  	const setData = (experience, storage, open) => {
  		setExperience(experience);
  		setContactCreatorStorage(storage);
  		setOpenContactCreator(open);
  	};

  	const getAnalyticsCategory = () => {
	    try {
	      return `BrandDetails_${Helper.titleCase(brand.name.split(' ').join('_'))}`;
	    } catch(err) {
	      return 'BrandDetails';
	    }
	};

	const routeChange = (e, url) => {
		Helper.trackAnalytics(getAnalyticsCategory(), `Click_${Helper.titleCase(url.split('/')[url.split('/').length - 1].replaceAll('-', '_'))}`, 'Navigate');
	    navigate(url);
	}

	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/brands?name=${brandName.split('-').join(' ')}`)
	      .then((response) => response.json())
	      .then((res) => {
	        if (res.data) setBrand(res.data);
	        else setInvalidBrandName(true);
	     })
	     .catch((err) => {
	        console.log(err.message);
	     });

	    fetch(`${global.config.SERVER_URL}/api/v1/blogs?active=true`)
	      .then((response) => response.json())
	      .then((res) => {
	       setBlogs(res.data);
	      })
	      .catch((err) => {
	        console.log(err.message);
	      });
	   }, {});

	const handleBlogsScroll = () => {
	    const totalScrollPossible = 235 * (blogs.length - 1);
	    let left = (document.getElementById("blogsContainer").scrollLeft / totalScrollPossible) * 100;
	    left = left >= 100 ? 100 : left;
	    const substract = left >= 80 ? ' - ' + ((left - 80) * 1.75) + 'px' : '';
	    setBlogScrollbarLeft('calc(' + left + '%' + substract  + ')');
	}

  	return (
   		<div className={styles.brandDetails} style={{backgroundColor: brand.bgColor}}>
   			{invalidBrandName && <h1 style={{ textAlign: 'center', padding: '80px 50px', margin: '0', color: '#fff'}}>Apologies! XPHive does not have this brand listed.</h1>}
   			{!invalidBrandName && Object.keys(brand).length == 0 ? <Oval
		        height={40}
		        width={40}
		        color="#fff"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		      /> : '' }

		     { Object.keys(brand).length != 0 ?
	     		<div className={styles.topContainer}>
		   			<Back analyticsCategory={getAnalyticsCategory()} whiteIcon={true} />
		   			<div className={styles.shareAndFavourite}>
		   				<Share analyticsCategory={getAnalyticsCategory()} style={{position: 'absolute', right: '50px', top: '40px'}}/>
		   				<Favourite analyticsCategory={getAnalyticsCategory()} id={brand._id} storage={'brand'} style={{ position: 'absolute', top: '15px', left: 'unset', bottom: 'unset', right: '82px'}} />
		   			</div>
		   			<div className={styles.brandTopInfo}>
		   				<i className={styles.brandIcon} style={{background: brand.icon.bg, marginTop: brand.icon.marginTop || 0}}><img style={{width: brand.icon.width, borderRadius: brand.icon.borderRadius || 0}} src={brand.icon.img} /></i>
		   				
		   				<div className={styles.brandInfo}>
		   					<label className={styles.brandName}>{brand.name}</label>
		   					<label className={styles.establishedYear}>Founded in {brand.establishedYear}</label>
		   					<label className={styles.creatorsName}>Created By - {brand.creators.length > 1 ? <br/> : ''}
				                {brand.creators.length > 3 && <p><span>{brand.creators[0].name}</span>, <span>{brand.creators[1].name}</span> and {brand.creators.length - 2} More</p>}
				                {brand.creators.length == 3 && <p><span>{brand.creators[0].name}</span>, <span>{brand.creators[1].name}</span> and <span>{brand.creators[2].name}</span></p>}
				                {brand.creators.length == 2 && <p><span>{brand.creators[0].name}</span> and <span>{brand.creators[1].name}</span></p>}
				                {brand.creators.length == 1 && <p><span>{brand.creators[0].name}</span></p>}
			   				</label>
		   				</div>
		   				<label className={styles.sloganText}>{brand.sloganText}</label>
		   			</div>

		   			<div className={styles.vibesContainer} style={{backgroundColor: brand.bgColor, backgroundImage: `url("/brand-white-bg.png")` }}>
		   				<div className="vibesCarouselParent">
			   				<Carousel preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={5} autoPlay={window.innerWidth > 912} showArrows={false} infiniteLoop={true} stopOnHover={false} showThumbs={false} showStatus={false}>
		                      	{brand.bannerImages.map((image, i) => {
		                        	return (
			                          	<div key={i} style={{width: width - 60, height: width - 60}}>
			                            	<img className={styles.vibeImage} src={image} />
			                          	</div>
		                        	);
		                      	})}
		                    </Carousel>
	                    </div>
		   			</div>

		   			<div className={styles.descContainer} style={{ backgroundImage: `url("/brand-white-bg.png")` }}>
		   				{brand.descriptions.map((desc, i) => {
		   					return (desc.text ? <div key={i} className={styles.description}>
	   							<img className={styles.descImage} src={desc.image} />
	   							<div className={styles.textSection}>
	   								{desc.heading && <h4 className={styles.descHeading}>{parse(desc.heading)}</h4>}
	   								<p className={styles.descText}>{parse(desc.text)}</p>
	   							</div>
	   						</div> : '');
		   				})}
		   				<div className={styles.brandImagesWrap}>
			   				{brand.descriptions.map((desc, i) => {
			   					return (!desc.text ? <img className={styles.descImageOnly} src={desc.image} /> : '');
			   				})}
		   				</div>
		   			</div>
		   			
		   			{brand.instaPost && <div style={{ display: 'flex', justifyContent: 'center' }}>
					  <InstagramEmbed url={brand.instaPost} width={width - 60 >= 328 ? width - 60 : 328} />
					</div>}

		   			{brand.essentials.length > 0 && <div className={brand.locations.length > 0 ? styles.essentials : styles.essentialsWithoutLocations} style={{ backgroundColor: brand.bgColor, backgroundImage: `url("/brand-white-bg.png")` }}>
		   				<div className={styles.hideBorder}></div>
		   				{brand.essentials.map((essential, i) => {
		   					return(
		   						<div className={styles.essentialWrap}>
				   					<h4>{essential.title}</h4>
				   					<ul>
				   						{essential.contents.map((content) => {
				   							return(
				   								<li>
				   									{content.img ? <img src={content.img} /> : ''}
				   									<label>{parse(content.text)}</label>
				   								</li>
				   							);
				   						})}
				   					</ul>
		   						</div>
		   					);
		   				})}
		   				{brand.locations.length > 0 && <div className={styles.hideBorder2}></div>}
		   			</div>}

		   			{brand.locations.length > 0 && <div className={styles.brandLocationContainer} style={{ backgroundColor: brand.bgColor, backgroundImage: `url("/brand-white-bg.png")` }} onClick={()=> window.open(brand.locations[0].googleMapLink, "_blank")}>
			   			<div className={styles.brandLocation} >
			   				<div className={styles.locationInfo}>
			   					<h4>{brand.locations[0].city}</h4>
			   					<h5>{brand.locations[0].state}</h5>
			   					<label><i><LocationBlackIcon /></i>Location</label>
			   				</div>
			   				<div className={styles.locationImgWrap}>
			   					<span className={styles.gradient}></span>
			   					<img src={brand.locations[0].googleMapImage} />
			   				</div>
			   			</div>
		   			</div>}

		   			{brand.experiences.length ? <div className={styles.experiencesContainer} style={brand.locations.length == 0 ? {backgroundColor: brand.bgColor, paddingTop: 0} : {backgroundColor: brand.bgColor} }><Experiences analyticsCategory={getAnalyticsCategory()} experiences={brand.experiences} brand={brand} setData={setData} /></div>: ''}
		   			
		   			{brand.reviews.data.length > 0 && <div className={styles.reviewsContainers} style={{backgroundColor: brand.bgColor}}>
		   				<h3><RatingWhiteIcon /> {brand.reviews.data.length} Reviews</h3>
		   				<div className={styles.reviewWrap}>
			   				{brand.reviews.data.map((review, i) => {
			   					return(
			   						<div className={styles.review}>
					   					<img src={review.traveller.image} />
					   					<div className={styles.traveller}>
					   						<label className={styles.travellerName}>{review.traveller.name}</label>
					   						<label className={styles.travellerRating}><Rating/> {review.rating}</label>
					   						<label className={styles.reviewDay}>Google Reviews</label>
					   					</div>
					   					<p className={styles.reviewText}>{parse(review.text)}</p>
			   						</div>
			   					);
			   				})}
		   				</div>
		   			</div>}

		   			{brand.faqs && <div className={styles.faqs} style={{ backgroundColor: brand.bgColor, backgroundImage: `url("/white-bg-reverse.png")` }}>
		   				<Faq rows={brand.faqs.data} />
		   			</div>}

		   			{true && blogs.length > 0 && <div className={styles.blogContainersParent}>
			            <h3 className={styles.blogsHeading}>Related Reads <RightArrow /></h3>
			            <div className={styles.blogContainers} id="blogsContainer" onScroll={handleBlogsScroll}>
			              {blogs.map((blog, i) => {
			                return(
			                  <div className={styles.blog} onClick={event => routeChange(event, `/blogs/${blog.slug}`)} key={i}>
			                    <div style={{position: 'relative'}}>
			                      <img src={blog.image} />
			                      <Favourite analyticsCategory={getAnalyticsCategory()} id={blog._id} storage={'blog'} style={{ position: 'absolute', top: '10px', right: '10px' }} />
			                    </div>
			                    <h4 className={styles.blogTitle}>{blog.title}</h4>
			                    <p className={styles.blogSubTitle}>{blog.subtitle}</p>
			                  </div>
			                );
			              })}
			            </div>
			        </div>}

		   			<ContactCreator analyticsCategory={getAnalyticsCategory()} open={openContactCreator} brand={brand} experience={experience} storage={contactCreatorStorage} setData={setData} />
		   		</div>
		     : ''}
   		</div>
  	);
};

export default BrandDetails;

const ThumbsDown = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="fi-rr-thumbs-down" clipPath="url(#clip0_482_706)">
			<path id="Vector" d="M23.951 12.3L23.246 7.29999C23.0727 6.10993 22.4783 5.0215 21.5707 4.23246C20.6631 3.44342 19.5026 3.00611 18.3 2.99999H5C3.67441 3.00158 2.40356 3.52887 1.46622 4.46621C0.528882 5.40355 0.00158786 6.6744 0 7.99999L0 13C0.00158786 14.3256 0.528882 15.5964 1.46622 16.5338C2.40356 17.4711 3.67441 17.9984 5 18H7.712L9.626 21.878C9.94777 22.5316 10.4938 23.0477 11.1644 23.3323C11.835 23.6169 12.5856 23.6509 13.2792 23.4282C13.9728 23.2055 14.5633 22.7408 14.9429 22.1191C15.3225 21.4973 15.4659 20.7597 15.347 20.041L15.011 18H19C19.7158 18 20.4232 17.8464 21.0745 17.5494C21.7258 17.2525 22.3057 16.8192 22.7751 16.2788C23.2445 15.7384 23.5923 15.1035 23.7952 14.4171C23.9981 13.7307 24.0512 13.0087 23.951 12.3ZM5 4.99999H7V16H5C4.20435 16 3.44129 15.6839 2.87868 15.1213C2.31607 14.5587 2 13.7956 2 13V7.99999C2 7.20434 2.31607 6.44128 2.87868 5.87867C3.44129 5.31606 4.20435 4.99999 5 4.99999ZM21.264 14.968C20.9824 15.292 20.6346 15.5517 20.244 15.7298C19.8535 15.9078 19.4292 16 19 16H13.833C13.688 16 13.5446 16.0315 13.413 16.0924C13.2814 16.1533 13.1645 16.2421 13.0706 16.3526C12.9767 16.4632 12.908 16.5928 12.8692 16.7326C12.8304 16.8723 12.8225 17.0189 12.846 17.162L13.374 20.362C13.3994 20.5105 13.3916 20.6628 13.3513 20.808C13.3111 20.9532 13.2392 21.0877 13.141 21.202C13.0258 21.3262 12.8828 21.4212 12.7237 21.4794C12.5645 21.5375 12.3939 21.557 12.2258 21.5363C12.0577 21.5156 11.8969 21.4553 11.7566 21.3603C11.6163 21.2653 11.5006 21.1384 11.419 20.99L9.23 16.558C9.17226 16.4471 9.09424 16.3481 9 16.266V4.99999H18.3C19.0223 5.00263 19.7195 5.26472 20.2647 5.73849C20.8099 6.21226 21.1667 6.86616 21.27 7.58099L21.976 12.581C22.0352 13.0066 22.0023 13.4401 21.8795 13.8519C21.7566 14.2637 21.5467 14.6443 21.264 14.968Z" fill={props.fill}/>
		</g>
		<defs>
			<clipPath id="clip0_482_706">
				<rect width="24" height="24" fill="white"/>
			</clipPath>
		</defs>
	</svg>
  );
};

export default ThumbsDown;

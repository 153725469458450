const Rating = (props) => {
  return (
	<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
	  <g clipPath="url(#clip0_2140_4742)">
	    <path d="M0.719018 6.71727L2.64735 8.12561L1.91502 10.3936C1.79667 10.7453 1.79517 11.1259 1.91074 11.4786C2.02632 11.8312 2.25277 12.1371 2.55635 12.3506C2.85473 12.5709 3.21631 12.689 3.58723 12.6871C3.95814 12.6853 4.31853 12.5636 4.61469 12.3403L6.50023 10.9526L8.38631 12.3387C8.68415 12.5578 9.04378 12.6768 9.41351 12.6786C9.78324 12.6804 10.144 12.5649 10.444 12.3487C10.7439 12.1326 10.9676 11.8268 11.0829 11.4755C11.1981 11.1242 11.199 10.7454 11.0854 10.3936L10.3531 8.12561L12.2814 6.71727C12.5789 6.4998 12.8 6.19389 12.9132 5.84323C13.0264 5.49258 13.0259 5.11512 12.9118 4.76477C12.7976 4.41443 12.5757 4.10911 12.2777 3.89243C11.9796 3.67575 11.6207 3.5588 11.2523 3.55827H8.88356L8.16477 1.31794C8.05173 0.965292 7.82962 0.657655 7.53046 0.439391C7.2313 0.221127 6.87055 0.103516 6.50023 0.103516C6.12991 0.103516 5.76916 0.221127 5.46999 0.439391C5.17083 0.657655 4.94872 0.965292 4.83569 1.31794L4.11689 3.55827H1.75035C1.38188 3.5588 1.023 3.67575 0.724966 3.89243C0.426934 4.10911 0.204999 4.41443 0.090859 4.76477C-0.0232807 5.11512 -0.0237859 5.49258 0.0894156 5.84323C0.202617 6.19389 0.423735 6.4998 0.721185 6.71727H0.719018Z" fill="#1C1C1E"/>
	  </g>
	  <defs>
	    <clipPath id="clip0_2140_4742">
	      <rect width="13" height="13" fill="white"/>
	    </clipPath>
	  </defs>
	</svg>
  );
};

export default Rating;

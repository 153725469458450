import styles from "./CommunityDetails.module.css";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { Oval } from  'react-loader-spinner';
import { Back, Share, Helper, CommunityInvite, } from "../components";



const CommunityDetails = () => {
  const navigate = useNavigate();
  const [community, setCommunity] = useState({});
  const { height, width } = Helper.getWindowDimensions();
  const { slug } = useParams();
  const [invalidCommunitySlug, setInvalidCommunitySlug] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const getAnalyticsCategory = () => {
    try {
      return `CommunityDetails_${community.title.split(' ').join('_')}`;
    } catch(err) {
      return 'CommunityDetails';
    }
  };

  const trackAnalytics = (action, label) => {
    Helper.trackAnalytics(getAnalyticsCategory(), action, label);
  };

  useEffect(() => {
    fetch(`${global.config.SERVER_URL}/api/v1/communities?slug=${slug}`)
      .then((response) => response.json())
      .then((res) => {
        if (res.data) {
          setCommunity(res.data);
        } else setInvalidCommunitySlug(true);
        setShowLoader(false);
     })
     .catch((err) => {
        setInvalidCommunitySlug(true);
        console.log(err.message);
     });
  }, {});


  return (
    <div className={styles.communityDetails}>
      {invalidCommunitySlug && <Back analyticsCategory={getAnalyticsCategory()} /> }
      {invalidCommunitySlug && 
        <h1 style={{ textAlign: 'center', margin: 0, padding: '80px 50px'}}>Apologies! XPHive does not have this community listed.</h1>
      }

      {(!invalidCommunitySlug && Object.keys(community).length == 0) || showLoader ? <Oval
        height={40}
        width={40}
        color="#1C1C1E"
        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#dbe8f6"
        strokeWidth={4}
        strokeWidthSecondary={4}
      /> : '' }

      { !showLoader && Object.keys(community).length != 0 ?
        <div>
          <i className={styles.backIcon}><Back analyticsCategory={getAnalyticsCategory()} /></i>
          <i className={styles.shareIcon}><Share hideCircle={true} style={{position: 'absolute', right: '25px', top: '27px'}} blackIcon={true} analyticsCategory={getAnalyticsCategory()} /></i>
          <img style={{width, height: width}} className={styles.communityImg} src={community.image} />

          <div className={styles.communityInfo}>
            <h1 className={styles.communityTitle}>{community.title}</h1>
            <p className={styles.groupInfo}>Invite only group<span>{community.size}</span><span>{community.engagement}</span></p>
            <p className={styles.description}><span>About</span>{community.longDescription}</p>
            <CommunityInvite community={community} setShowLoader={setShowLoader} />
          </div>
        </div>
       : ''}
    </div>
  );
};

export default CommunityDetails;

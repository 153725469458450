const User = () => {
  return (
	   <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
		  <path d="M12.9987 13C15.9902 13 18.4154 10.5749 18.4154 7.58332C18.4154 4.59178 15.9902 2.16666 12.9987 2.16666C10.0072 2.16666 7.58203 4.59178 7.58203 7.58332C7.58203 10.5749 10.0072 13 12.9987 13Z" stroke="#1C1C1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		  <path d="M22.307 23.8333C22.307 19.6408 18.1361 16.25 13.0011 16.25C7.86614 16.25 3.69531 19.6408 3.69531 23.8333" stroke="#1C1C1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
  	);
};

export default User;

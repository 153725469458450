const DoubleArrowRight = () => {
  return(
		<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
			<circle cx="20" cy="20" r="20" fill="#FFEA00"/>
			<path d="M21.6 14L28 20.4L21.6 26.8M12 14L18.4 20.4L12 26.8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
  	);
};

export default DoubleArrowRight;

import parse from 'html-react-parser';
import styles from "./Search.module.css";
import { useNavigate, useSearchParams, } from "react-router-dom";
import React, { useState, useEffect,  } from 'react';  
import useToggle from './UseToggle';
import Helper from './Helper';
import { DownArrow, } from '../icons';


const Search = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useToggle(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userSearchParams, setUserSearchParams] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  let [searchTerms, setSearchTerms] = useState({
    locations: props.locations || [],
    vibes: props.vibes || [],
    categories: props.categories || []
  });

 React.useEffect(() => {
    searchTerms['locations'] = props.locations || searchTerms['locations'];
    searchTerms['categories'] = props.categories || searchTerms['categories'];
    searchTerms['vibes'] = props.vibes || searchTerms['vibes'];
    setSearchTerms({...searchTerms});
  }, [props])

  const toggleSearchModal = () => {
    Helper.trackAnalytics(props.analyticsCategory || 'Search', `Click_${open ? 'Close' : 'Open'}_Search`, 'Toggle Search');
    setOpen();
    setSearchType('');
  };

	useEffect(() => {
    fetch(`${global.config.SERVER_URL}/api/v1/search`)
      .then((response) => response.json())
      .then((res) => {
        setUserSearchParams(res.data);
     })
     .catch((err) => {
        console.log(err.message);
     });
   }, []);
  
  const selectSearchType = (e, type) => {
    setSearchType(type);
  };

  const getSearchTypeHeading = () => {
    if (searchType === 'locations') {
      return 'DESTINATION';
    } else if (searchType === 'categories') {
      return 'EXPERIENCE<br/>CATEGORY';
    } else if (searchType === 'vibes') {
      return 'VIBE';
    }
  };

  const getSearchTypeDesc = () => {
    return 'Explore teh exact location you’re planning your trip for simply with our help';
  };

  const onChangeSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const selectSearchTerms = (e, item) => {
    if (searchTerms[searchType].includes(item)) {
      searchTerms[searchType] = searchTerms[searchType].filter(it => it !== item);
      setSearchTerms({...searchTerms});
    } else {
      searchTerms[searchType].push(item);
      setSearchTerms({...searchTerms});
    }
  };

  const getSearchSuggestionItem = (e) => {
    let items = [];
    if (searchType === 'locations') {
      userSearchParams[searchType].map(location => items.push(location.city));
    } else if (searchType === 'categories') {
      userSearchParams[searchType].map(category => items.push(category.name));
    } else if (searchType === 'vibes') {
      userSearchParams[searchType].map(vibe => {
        vibe.tags.map(tag => items.push(tag));
      });
    }

    items = [...new Set(items)];
    const filteredSuggestions = items.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
    );
    return filteredSuggestions.slice(0, 12).map((item, i) => {
      return(<li className={searchTerms[searchType].includes(item) ? styles.selectedTerm : ''} onClick={e => selectSearchTerms(e, item)} key={i}>{item}</li>);
    });
  };

  const onSeach = (e) => {
    const tab = searchParams.get('tab') ? `tab=${searchParams.get('tab')}&` : '';
    navigate(`/listings?${tab}locations=${searchTerms['locations']}&categories=${searchTerms['categories']}&vibes=${searchTerms['vibes']}&experiencebyte=${searchParams.get('experiencebyte')}&experience=${searchParams.get('experience')}`);
    toggleSearchModal();
    if (props.postSearch) props.postSearch();
  };

	return (
    <div style={{overflow: 'hidden'}}>
      {!props.input 
        ? <button className={styles.searchHere} onClick={toggleSearchModal}>SEARCH HERE</button> 
        : <input className={styles.searchInput} type="text" placeholder="Search here..." readOnly={true} onClick={toggleSearchModal} />
      }
      <div className={styles.searchContainer} style={{bottom: (open ? '0' : '-6000px'), transition: (open ? 'all 0.3s ease' : 'all 1.5s ease')}}>
        <i className={styles.downArrow} onClick={toggleSearchModal}><DownArrow fill={'#fff'} width={'40'} height={'40'} /></i>
        <h3>Search here..</h3>
        <p>You can customize your search based on multiple of options from below</p>

        {!searchType && <ul className={styles.availableSearch}>
          <li onClick={e => selectSearchType(e, 'locations')}>
            <h4>DESTINATION</h4>
            <label>Explore teh exact location you’re planning your trip for simply with our help</label>
          </li>
          <li onClick={e => selectSearchType(e, 'categories')}>
            <h4>CATEGORY</h4>
            <label>Explore teh exact location you’re planning your trip for simply with our help</label>
          </li>
          <li onClick={e => selectSearchType(e, 'vibes')}>
            <h4>VIBE</h4>
            <label>Explore teh exact location you’re planning your trip for simply with our help</label>
          </li>
        </ul>}

        {searchType && <div className={styles.searchBox}>
          <h3>{parse(getSearchTypeHeading())}</h3>
          <label>{getSearchTypeDesc()}</label>

          <div className={styles.inputAndSearch}>
            <input type="text" placeholder="Type here..." value={searchTerm} onChange={e => onChangeSearchTerm(e)} />
            <button onClick={e => onSeach(e)}>Search</button>
          </div>
          <ul className={styles.suggestionList}>{getSearchSuggestionItem()}</ul>
        </div>}

      </div>
    </div>
  )
}

export default Search;  

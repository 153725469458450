import React, { useEffect, useState, } from "react";
import styles from "./Home.module.css";
import { useNavigate } from "react-router-dom";
import { Sidebar, BottomBar, LoginHelper, Helper, Blogs, ContactUs, Footer, Communities, FindYourHive, } from "../components";
import { Logo, } from '../icons';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { Oval } from  'react-loader-spinner';


const Home = () => {
  const navigate = useNavigate();
  const isUserLoggedIn = LoginHelper.isUserLoggedIn();
  const { height, width } = Helper.getWindowDimensions();
  const [hives, setHives] = useState([]);
  const [dayExperiences, setDayExperiences] = useState([]);
  const [categories, setCategories] = useState([]);
  let [selectedCategories, setSelectedCategories] = useState([]);

  const routeChange = (e, url) => {
      navigate(url);
  };

  const getAnalyticsCategory = () => {
    return 'Home';
  };

  const getIntersection = (arr1, arr2) => {
    // Create a map to store the unique objects based on _id from the first array
    const map = new Map();

    // Add each object from the first array to the map using _id as the key
    arr1.forEach(item => {
      map.set(item._id, item);
    });

    // Now filter the second array by checking if the _id exists in the map
    const intersection = arr2.filter(item => map.has(item._id));

    // Remove duplicates by using the _id as the key and only keeping the unique ones
    const uniqueIntersection = [...new Map(intersection.map(item => [item._id, item])).values()];

    return uniqueIntersection;
  };

  useEffect(() => {
      fetch(`${global.config.SERVER_URL}/api/v1/groups?active=true`, {headers: {...Helper.getCommonHeaders(),}})
        .then((response) => response.json())
        .then((res) => {
          const allHives = res.data.filter(hive => !hive.dayHive);
          const allExperiences = res.data.filter(hive => hive.dayHive);
          setDayExperiences(allExperiences);
          setHives(allHives);

          const allHiveCategories = [];
          const allExperiecesCategories = [];

          allHives.forEach((hive) => {
              allHiveCategories.push(hive.categories)
          });

          allExperiences.forEach((hive) => {
              allExperiecesCategories.push(hive.categories)
          });
          setCategories(getIntersection(allHiveCategories.flat(1), allExperiecesCategories.flat(1)));
        })
        .catch((err) => {
          console.log(err.message);
        });
  }, {});

  const onGroupClick = (e, slug, url) => {
    Helper.trackAnalytics(getAnalyticsCategory(), `Click_${Helper.titleCase(slug.split('/')[slug.split('/').length - 1].replaceAll('-', '_'))}`, `Navigate_Group`);
    routeChange(e, `/groups/${slug}`);
  };

  const onCategoryClick = (e, category) => {
    if (selectedCategories.includes(category['_id'])) {
      Helper.trackAnalytics(getAnalyticsCategory(), `Remove_category_filter`, category.label);
      selectedCategories = selectedCategories.filter(sc => sc !== category['_id']);
    } else {
      Helper.trackAnalytics(getAnalyticsCategory(), `Add_category_filter`, category.label);
      selectedCategories.push(category['_id']);
    }

    setSelectedCategories([...selectedCategories]);
  };

  const getCreatorsName = (creators) => {
    let result = '';

    // Get the names of all creators
    const creatorNames = creators.map(creator => creator.name);

    // If there's more than 1 creator, join the names with commas, and put "and" before the last one
    if (creatorNames.length > 1) {
      result = creatorNames.slice(0, -1).join(', ') + ' and ' + creatorNames[creatorNames.length - 1];
    } else {
      result = creatorNames[0]; // If only one creator
    }

    return result;
  };

  return (
    <div className={styles.homePage}>
      {hives.length == 0 ? <Oval
        height={40}
        width={40}
        color="#FFEA00"
        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#ccc"
        strokeWidth={4}
        strokeWidthSecondary={4}
      /> : <div style={{paddingBottom: isUserLoggedIn ? '80px' : '10px'}}>

        <div>
          <i className={styles.logo}><Logo /></i>
          <h1 className={styles.heading}><span>Explore</span>
            <div className={styles.textContainer}>
              <div className={styles.text} style={{textAlign: width < 360 ? 'center' : 'left' }}>Communities</div>
              <div className={styles.text} style={{textAlign: width < 360 ? 'center' : 'left' }}>Experiences</div>
              <div className={styles.text} style={{textAlign: width < 360 ? 'center' : 'left' }}>Hives</div>
              <div className={styles.text} style={{textAlign: width < 360 ? 'center' : 'left' }}>Together</div>
            </div>
          </h1>
        </div>
        <Sidebar analyticsCategory={getAnalyticsCategory()} />

        <FindYourHive />
        
        {categories.length > 0 && <div className={styles.categories}>
          {categories.map((category, i) => {
            return(
              <div className={(selectedCategories.includes(category['_id']) ? styles.selectedCategory : styles.category)} key={i} onClick={e => onCategoryClick(e, category)}>
                <img src={category.icon} />
                <label>{category.label}</label>
              </div>
            );
          })}
        </div>}

        <div className={styles.hives}>
          <h3 className={styles.groupsHeading}>Curated Hives for You</h3>
          <label className={styles.groupsSubHeading}>Invite-only groups tailored to your travel style</label>

          <ul className={styles.groupsCarousel}>
            {hives.filter(hive => hive.categories.map(c => c['_id']).filter(value => selectedCategories.includes(value)).length > 0 || selectedCategories.length === 0).map((hive, i) => {
              return(<li onClick={e => onGroupClick(e, hive.slug, hive.link)} className={styles.group} key={i}>
                <div style={{position: 'relative'}}>
                  <img src={hive.image} alt={i} />

                  {hive.idealFor && <label className={styles.idealFor}>{hive.idealFor}</label>}
                  {hive.duration && <label className={styles.duration}>{hive.duration}</label>}
                </div>
                <h3>{hive.title}</h3>
                {hive.creators.length > 0 && <label className={styles.curator}>An experience by {getCreatorsName(hive.creators)}</label>}
                {hive.location && hive.location.city && <p className={styles.location}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 1.66699C13.3823 1.66699 16.25 4.52368 16.25 7.98941C16.25 11.5103 13.3357 13.9812 10.6437 15.6613C10.4476 15.7742 10.2257 15.8337 10 15.8337C9.77425 15.8337 9.55242 15.7742 9.35625 15.6613C6.66937 13.9647 3.75 11.5225 3.75 7.98941C3.75 4.52368 6.61767 1.66699 10 1.66699Z" fill="black" stroke="white" strokeWidth="1.5"/>
                    <path d="M15.0007 15C16.0379 15.3537 16.6673 15.8183 16.6673 16.3271C16.6673 17.4351 13.6826 18.3333 10.0007 18.3333C6.31875 18.3333 3.33398 17.4351 3.33398 16.3271C3.33398 15.8183 3.96338 15.3537 5.00065 15" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M12.5 7.91699C12.5 9.29774 11.3807 10.417 10 10.417C8.61925 10.417 7.5 9.29774 7.5 7.91699C7.5 6.53628 8.61925 5.41699 10 5.41699C11.3807 5.41699 12.5 6.53628 12.5 7.91699Z" stroke="white" strokeWidth="1.5"/>
                  </svg>
                  <span className={styles.city}>{hive.location.city}</span>
                </p>}
                <p className={styles.description}>{hive.description}</p>
              </li>)
            })}
          </ul>
        </div>

        {dayExperiences.length > 0 && <div className={styles.experiences}>
          <h3 className={styles.groupsHeading}>One-Day Wonders</h3>
          <label className={styles.groupsSubHeading}>Exciting short adventures, perfect for a day’s escape</label>

          <ul className={styles.groupsCarousel}>
            {dayExperiences.filter(hive => hive.categories.map(c => c['_id']).filter(value => selectedCategories.includes(value)).length > 0 || selectedCategories.length === 0).map((hive, i) => {
              return(<li onClick={e => onGroupClick(e, hive.slug, hive.link)} className={styles.group} key={i}>
                <div style={{position: 'relative'}}>
                  <img src={hive.image} alt={i} />
                  {hive.idealFor && <label className={styles.idealFor}>{hive.idealFor}</label>}
                </div>
                <h3>{hive.title}</h3>
                {hive.creators.length > 0 && <label className={styles.curator}>Curated by - {getCreatorsName(hive.creators)}</label>}
                {hive.location && hive.location.city && <p className={styles.location}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 1.66699C13.3823 1.66699 16.25 4.52368 16.25 7.98941C16.25 11.5103 13.3357 13.9812 10.6437 15.6613C10.4476 15.7742 10.2257 15.8337 10 15.8337C9.77425 15.8337 9.55242 15.7742 9.35625 15.6613C6.66937 13.9647 3.75 11.5225 3.75 7.98941C3.75 4.52368 6.61767 1.66699 10 1.66699Z" fill="black" stroke="white" strokeWidth="1.5"/>
                    <path d="M15.0007 15C16.0379 15.3537 16.6673 15.8183 16.6673 16.3271C16.6673 17.4351 13.6826 18.3333 10.0007 18.3333C6.31875 18.3333 3.33398 17.4351 3.33398 16.3271C3.33398 15.8183 3.96338 15.3537 5.00065 15" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
                    <path d="M12.5 7.91699C12.5 9.29774 11.3807 10.417 10 10.417C8.61925 10.417 7.5 9.29774 7.5 7.91699C7.5 6.53628 8.61925 5.41699 10 5.41699C11.3807 5.41699 12.5 6.53628 12.5 7.91699Z" stroke="white" strokeWidth="1.5"/>
                  </svg>
                  <span className={styles.city}>{hive.location.city}</span>
                </p>}
                <p className={styles.description}>{hive.description}</p>
              </li>)
            })}
          </ul>
        </div>}

        <Communities heading={'Communities for you'} subHeading={'Get inspired with updates on your favorite areas and interests'} analyticsCategory={getAnalyticsCategory()} headingStyle={{margin: '16px 0 5px 12px', fontFamily: 'var(--font-poppins)', fontWeight: 500}} subHeadingStyle={{margin: '0 12px 8px 12px', fontSize: '12px', fontWeight: 400, }} communitiesCarouselStyle={{paddingLeft: '12px'}} />
        <div className={styles.blogs}><Blogs analyticsCategory={getAnalyticsCategory()} heading={'Bits of Wanderlust'} subHeading={'Quick reads to fuel your travel inspiration'} containerStyle={{marginTop: '12px', paddingLeft: '12px'}} /></div>
        <ContactUs bgColor={'#fff'} style={{padding: '0 12px 20px'}} />

        <Footer />
        {isUserLoggedIn && <BottomBar analyticsCategory={'BottomBar_Home'} />}
      </div>}
    </div>
  );
};

export default Home;

import styles from "./BookNowPopup.module.css";
import React, { useState, useEffect } from 'react';
import Helper from './Helper';
import Modal from './Modal';


const BookNowPopup = (props) => {
  const [open, setOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyName, setEmptyName] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const { height, width } = Helper.getWindowDimensions();
  let leadSubmitted = Helper.storage.getItem('SummerBreeze') || null;

  const onChangeUserName = (e) => {
    setEmptyName(false);
    const result = event.target.value.replace(/[^a-z ]/gi, '');
    setUserName(result);
  }

  const onChangeEmail = (e) => {
    setEmptyEmail(false);
    setUserEmail(e.target.value);
  }

  const setLeads = () => {
    leadSubmitted = true;
    Helper.storage.setItem('SummerBreeze', true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!userName) setEmptyName(true);
    if (!userEmail) setEmptyEmail(true);
    if (userPhone.length != 10) setInvalidPhone(true);

    if (!submittingForm && userName && userPhone && userEmail && !emptyName && !emptyEmail && !invalidPhone && userPhone.length == 10) {
      Helper.trackAnalytics(props.analyticsCategory || 'BookNow', 'Click_Submit_BookNow', userPhone);
      setSubmittingForm(true);
      await fetch(`${global.config.SERVER_URL}/api/v1/leads`, {
        method: 'POST',
        body: JSON.stringify({
           name: userName,
           phone: userPhone,
           email: userEmail,
           type: 'BookNow',
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => response.json())
        .then((data) => {
          setSubmittingForm(false)
           if (data.status == 'success') setLeads();
        })
        .catch((err) => {
          setSubmittingForm(false)
          console.log(err.message);
        });
    }
  };

  const onChangeUserPhone = (e) => {
    setInvalidPhone(false)
    if (e.target.value.length < 11)
      setUserPhone(e.target.value);
  };

  const closeBookNow = () => {
    setOpen(false);
  }
  
	return (
    	<div className={styles.bookNowContainer}>
        <button className={styles.bookNowButton} onClick={(e) => { e.stopPropagation(); setOpen(true);  Helper.trackAnalytics(props.analyticsCategory || 'BookNow', 'Click_Open_BookNow', 'button');}}>RESERVE YOUR SPOT<label>Day passes starting at 300/-</label></button>

        <div id="book-now-modal-root"></div>
        {open && (
          <Modal open={open} toggle={closeBookNow} root='book-now-modal-root'>
            {!leadSubmitted &&
              <div>
                <h1 className={styles.bookNowModalHeading}>Book Summer Breeze</h1>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label className={styles.label}>Your Name</label>
                    <input className={emptyName ? styles.invalid : styles.valid} type="text" placeholder="Name" value={userName} onChange={e => onChangeUserName(e)} />
                  </div>

                  <div>
                    <label className={styles.label}>Email</label>
                    <input className={emptyEmail ? styles.invalid : styles.valid} type="email" placeholder="loremipsu@company.com" value={userEmail} onChange={e => onChangeEmail(e)} />
                  </div>

                  <div>
                    <label className={styles.label}>Phone Number</label>
                    <input className={invalidPhone ? styles.invalid : styles.valid} type="number" placeholder="+91 -" value={userPhone} onChange={e => onChangeUserPhone(e)} />
                  </div>

                  <p className={styles.message}>Please carry any government id (Passport / Driving License / Voter Id / PAN Card / Aadhaar Card) with you.</p>

                  <button type="submit">Reserve</button>
                  <button className={styles.closeBtn} onClick={(e) => { e.stopPropagation(); setOpen(false);}}>Close</button>
                </form>
              </div>
            }

            {leadSubmitted && 
              <div>
                <h3 className={styles.bookNowModalHeadingSuccess}>The team will reach out to you with payment details shortly.</h3>
              </div>
            }
          </Modal>
        )}
    </div>
  )
}

export default BookNowPopup;

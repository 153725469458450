import JWTHelper from "./JWTHelper";
import Cookies from 'js-cookie';

const LoginHelper = {};

LoginHelper.isUserLoggedIn = () => {
	const token = Cookies.get('jwt');
	if (!token) return false;
    return !JWTHelper.isExpired(token);
};

LoginHelper.getUser = () => {
	const token = Cookies.get('jwt');
    return JWTHelper.decodeToken(token);
};

LoginHelper.getUserFromToken = (token) => {
    return JWTHelper.decodeToken(token);
};

LoginHelper.setUser = (jwt) => {
	Cookies.set('jwt', jwt, { expires: 14 });
};

export default LoginHelper;

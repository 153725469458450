import styles from "./ExperienceDetails.module.css";
import { useNavigate, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import React, { useEffect, useState, } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { Oval } from  'react-loader-spinner';
import { Back, Share, Helper, BookExperienceForm, } from "../components";
import { Location, Duration, } from '../icons';
import ReactPlayer from 'react-player';


const ExperienceDetails = () => {
  const navigate = useNavigate();
  const [experience, setExperience] = useState({});
  const [experienceSlug, setExperienceSlug] = useState(null);
  const { slug } = useParams();
  const { height, width } = Helper.getWindowDimensions();
  const [invalidExperienceId, setInvalidExperienceId] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [scrollbarLeft, setScrollbarLeft] = useState(0);


  const handleBytesScroll = () => {
    const totalScrollPossible = 180 * (experience.immersions.length - 1);
    let left = (document.getElementById('immersionsWrap').scrollLeft / totalScrollPossible) * 100;
    left = left >= 100 ? 100 : left;
    const substract = left >= 80 ? ' - ' + ((left - 80) * 1.75) + 'px' : '';
    setScrollbarLeft('calc(' + left + '%' + substract  + ')');
  };

  const getAnalyticsCategory = () => {
    try {
      return `ExperienceDetails_${experience.title.split(' ').join('_')}`;
    } catch(err) {
      return 'ExperienceDetails';
    }
  };

  const trackAnalytics = (action, label) => {
    Helper.trackAnalytics(getAnalyticsCategory(), action, label);
  };

  const routeChange = (e, url) => {
    trackAnalytics(`Click_${Helper.titleCase(url.split('/')[url.split('/').length - 1].replaceAll('-', '_'))}`, `Navigate`);
    navigate(url);
  };

  const fetchData = () => {
    fetch(`${global.config.SERVER_URL}/api/v1/experiences?slug=${slug}`)
      .then((response) => response.json())
      .then((res) => {
        if (res.data) {
          setExperience(res.data);
        } else setInvalidExperienceId(true);

        setShowLoader(false);
     })
     .catch((err) => {
        setInvalidExperienceId(true);
        console.log(err.message);
     });
  };

  useEffect(() => {
    fetchData();
    if (slug !== experienceSlug) setExperienceSlug(slug);
  }, {});

  if (experienceSlug && slug !== experienceSlug) {
    setShowLoader(true);
    setExperienceSlug(slug);
    fetchData();
  };

  return (
    <div className={styles.experienceDetails} style={{background: invalidExperienceId ? '#F2F2F7' : ''}}>
      {invalidExperienceId && <Back analyticsCategory={getAnalyticsCategory()} /> }
      {invalidExperienceId && 
        <h1 style={{ textAlign: 'center', margin: '80px 50px'}}>Apologies! XPHive does not have this experience listed.</h1>
      }

      {(!invalidExperienceId && Object.keys(experience).length == 0) || showLoader ? <Oval
        height={40}
        width={40}
        color="#1C1C1E"
        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#dbe8f6"
        strokeWidth={4}
        strokeWidthSecondary={4}
      /> : '' }

      { !showLoader && Object.keys(experience).length != 0 && experience.shortExperience !== true ?
        <div className={styles.topContainerParent}>
          <i className={styles.backIcon}><Back style={{position: 'absolute', left: '23px', top: '25px'}} analyticsCategory={getAnalyticsCategory()} /></i>
          <i className={styles.shareIcon}><Share hideCircle={true} style={{position: 'absolute', right: '25px', top: '27px'}} blackIcon={true} analyticsCategory={getAnalyticsCategory()} /></i>
          <h1 className={styles.pageHeading}>XPhive Experiences</h1>

          <div className={styles.topContainer}>
            <div className="experienceImagesCarouselParent">
              <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={20} autoPlay={window.innerWidth > 912} showArrows={false} infiniteLoop={true} stopOnHover={false} showThumbs={false} showStatus={false}>      
                  {experience.bannerImages.map((image, i) => {
                    return (
                        <div key={i} style={{width: width, height: (width) / 0.75}}>
                          {image.includes(".mp4") ? <ReactPlayer className={styles.videoContainer} url={image} playsinline muted={true} loop={true} playing={true} width={width} height={(width) * 1.33} config={{file: {attributes: {crossOrigin: 'true',},},}} /> : <img className={styles.bannerImage} src={image} />}
                        </div>
                    );
                  })}
                  
              </Carousel>
              <div className={styles.titleAndSubtitle}>
                <h4 className={styles.title}>{experience.title}</h4>
                <h3 className={styles.subtitle}>{experience.subtitle}</h3>
              </div>
            </div>

            <div className={styles.locationAndDuration}>
              <label className={styles.location}>
                <Location />
                {`${experience.locations[0].city}, ${experience.locations[0].state}`}
              </label>
              <label className={styles.duration}><Duration />{experience.duration}</label>
            </div>

            <p className={styles.description}>{experience.description}</p>
          </div>

          <div className={styles.middleContainer}>
            {experience.immersions && <div>
              <h3 className={styles.sectionHeading}>Highlights</h3>

              <div onScroll={handleBytesScroll} className="immersionsWrap" id="immersionsWrap">
                {experience.immersions.map((immersion, i) => {
                 return(<div className={styles.immersion} key={i}>
                      <div className={styles.immersionImgWrap} style={{background: `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 68.25%, rgba(0, 0, 0, 0.80) 88.6%), url(${immersion.image}) lightgray 50% / cover no-repeat`}}>
                        <label className={styles.immersionLabel}>{parse(immersion.title)}</label>
                      </div>
                      <p>{parse(immersion.description)}</p>
                    </div>)
                })}
              </div>

              {experience.immersions.length > 1 && <div className={styles.scrollWrap}>
                <span className={styles.scrollLine}></span>
                <span className={styles.scrollBar} style={{'left': scrollbarLeft}}></span>
              </div>}
            </div>}

            <div className={styles.snapshot}>
              <h3 className={styles.sectionHeading}>Experience Snapshot</h3>
              <p>{parse(experience.longDescription)}</p>
            </div>

            {experience.howToReach && <div className={styles.howToReach}>
              <h3 className={styles.sectionHeading}>How to reach</h3>
              <div className={styles.howToReachImg} style={{width: width - 40, height: (width - 40) / 1.7, background: `linear-gradient(203deg, rgba(0, 0, 0, 0.00) 37.06%, rgba(255, 234, 0, 0.66) 73.4%), url(${experience.howToReach.img}) lightgray 50% / cover no-repeat`}}>
                <label>{`${experience.locations[0].city}, ${experience.locations[0].state}`}</label>
              </div>
              {experience.howToReach.data.map((htr, i) => {
                return(<div>
                  <h4>{htr.heading}</h4>
                  <ul className={styles.htrlist}>
                    {htr.list.map((item, j) => {
                        return (
                          <li key={j}>{item}</li>
                        );
                      })}
                  </ul>
                </div>)
              })}
            </div>}

            {
              experience.itinerary && experience.itinerary.brief && experience.itinerary.brief.data && 
              <div className={styles.itineraryBrief}>
                <h3 className={styles.sectionHeading}>Day wise plan</h3>
                {experience.itinerary.brief.data.map((ib, i) => {
                  return(
                    <p><b>{ib.title}:</b>{ib.description}</p>
                  )
                })}
              </div>
            }

            <div className={styles.organizer}>
              <h3 className={styles.sectionHeading}>Organizers</h3>
              <div className={styles.experienceCreator} onClick={e => routeChange(e, `/brands/${Helper.generateURL(experience.brand.name)}`)}>
                <i style={{background: experience.brand.bgColor}} className={styles.brandIcon}><img style={{width: '100%', borderRadius: experience.brand.icon.borderRadius || 0}} src={experience.brand.icon.img} /></i>
                <label className={styles.brandName}>{experience.brand.name}</label>
              </div>
            </div>
          </div>

          <button className={styles.bookExperienceBtn} onClick={e => routeChange(e, location.pathname + '/book')}>View Slots & Pricing</button>

        </div>
       : ''}

      { !showLoader && Object.keys(experience).length != 0 && experience.shortExperience === true ?
        <div className={styles.topContainerParent}>
          <i className={styles.backIcon}><Back style={{position: 'absolute', left: '23px', top: '25px'}} analyticsCategory={getAnalyticsCategory()} /></i>
          <i className={styles.shareIcon}><Share hideCircle={true} style={{position: 'absolute', right: '25px', top: '27px'}} blackIcon={true} analyticsCategory={getAnalyticsCategory()} /></i>
          <h1 className={styles.pageHeading}>XPhive Experiences</h1>

          <div className={styles.shortExperienceContainer}>
            <div>
              {experience.icon && <img className={styles.icon} src={experience.icon} />}
              <div className={styles.titleAndLocationWrap}>
                <h3>{experience.title}</h3>
                <p>{`${experience.locations[0].city}, ${experience.locations[0].state} (${experience.locations[0].tag})`}</p>
              </div>

              <div className={styles.detailsWrap}>
                <div className={styles.imgWrap} style={{width: ((width - 60) * 0.60) - 20}}>
                  <img src={experience.bannerImages[0]} style={{width: ((width - 60) * 0.60) - 20}} />
                  <label>₹ {experience.unitPrice} /- {experience.characteristics.inclusion.highlight ? '(' + experience.characteristics.inclusion.highlight + ')' : ''}</label>
                </div>
                <div className={styles.basicDetails}>
                  {experience.vibes && experience.vibes.length > 0 && <span className={styles.vibeName}><b>Vibe:</b> {experience.vibes[0].tags.join(', ')}</span>}
                  <p className={styles.experienceDesc}>{experience.description}</p>
                  {experience.recurrences.length > 0 ? <label className={styles.time}><b>Time:</b> {`${Helper.formatDate(new Date(experience.recurrences[0].dates.start), 'short', false)} ${new Date(experience.recurrences[0].dates.start).toLocaleTimeString('en-US')} - ${new Date(experience.recurrences[0].dates.end).toLocaleTimeString('en-US')}`}</label> : ''}
                  
                  <p className={styles.curatedBy}>Curated by<br/><label>{experience.brand.name}</label></p>
                </div>
              </div>
            </div>
          </div>
          <BookExperienceForm experience={experience} />
        </div>
      : ''}
    </div>
  );
};

export default ExperienceDetails;

import styles from "./ExperienceTerms.module.css";
import parse from 'html-react-parser';
import { Back, ContactCreator, Helper, List, } from "../components";
import { Oval } from  'react-loader-spinner';
import { useParams } from "react-router-dom";
import React, { useEffect, useState, } from "react";


const ExperienceTerms = () => {
	const { slug } = useParams();
	const [terms, setTerms] = useState({});
	const [brand, setBrand] = useState({});
	const [invalidExperienceId, setInvalidExperienceId] = useState(false);
	const [openContactCreator, setOpenContactCreator] = useState(false);
	const [experience, setExperience] = useState({});
	const [contactCreatorStorage, setContactCreatorStorage] = useState('contactCreatorLeads');


	const setData = (exp, storage, open) => {
		setContactCreatorStorage(storage);
		setOpenContactCreator(open);
	};

	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/experiences/terms?slug=${slug}`)
	      	.then((response) => response.json())
	      	.then((res) => {
	        	if (res.data) {
	        		setTerms(res.data.terms);
	        		setExperience({id: res.data._id, title: res.data.title})
	        		setBrand(res.data.brand);
	        	}
	        	else setInvalidExperienceId(true);
	     	})
	     	.catch((err) => {
	        	console.log(err.message);
	     	});
	}, {});

	const getAnalyticsCategory = () => {
		try {
	      return `ExperienceDetails_Terms_${experience.title.split(' ').join('_')}`;
	    } catch(err) {
	      return 'ExperienceDetails_Terms';
	    }
	};

  	return(
   		<div className={styles.termsContainer}>
   			<Back analyticsCategory={getAnalyticsCategory()} />

   			{invalidExperienceId && 
		        <h1 style={{ textAlign: 'center', margin: '80px 50px'}}>Apologies! XPHive does not have this experience listed.</h1>
		    }

		    {!invalidExperienceId && Object.keys(terms).length == 0 ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		    /> : '' }

		    { Object.keys(terms).length != 0 ?
		        <div>
		        	<h3 className={styles.heading}>{terms.heading}</h3>
		        	<p className={styles.termsHeading}>{parse(terms.description)}</p>

		        	<ul className={styles.termsList}>
			        	{terms.data.map((term, i) => {
			        		return(
			        			<li>
			        				<h4><i>{i + 1}.</i><span>{term.heading}</span></h4>
			        				<List list={term.content} class={styles.termListItem} />
			        			</li>
			        		)
			        	})}
		        	</ul>
					<p className={styles.note}>{terms.note}</p>

					<ContactCreator analyticsCategory={getAnalyticsCategory()} hideCommentsBtn={true} open={openContactCreator} brand={brand} experience={experience} storage={contactCreatorStorage} setData={setData} />
			        <button className={styles.queryBtn} onClick={(e) => { e.stopPropagation(); setData(experience, 'contactCreatorLeads', true); Helper.trackAnalytics(getAnalyticsCategory(), 'Click_Query', 'Query');}}>Ask your Queries</button>
		        </div>
		    : ''}
   		</div>
  	);
};

export default ExperienceTerms;
